import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card report" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = {
  key: 11,
  class: "text-center"
}

import { DrugEventsViewer } from '@sancare/ui-frontend-commons'
import { HealthConstantsViewer } from '@sancare/ui-frontend-commons'
import { LabResultsViewer } from '@sancare/ui-frontend-commons'
import { NamedEntitiesViewer } from '@sancare/ui-frontend-commons'
import {
  DocumentSourceIdentifier,
  HealthDataDocument,
  HealthDataType,
  TextualHealthEntryType,
} from '@sancare/ui-frontend-commons/src/types/health-data'
import _ from 'lodash'
import { computed, ref } from 'vue'

import ReportViewer from '@/stay-displayer/health-data/ReportDisplayer.vue'
import TextualHealthEntriesViewer from '@/stay-displayer/health-data/TextualHealthEntries.vue'
import { TextualHealthEntry } from '@/stay-displayer/health-data/types'
import {
  getHighlightedDrugEvent,
  getHighlightedHealthData
} from '@/stay-displayer/health-data/utils'
import { CodingSelection } from '@/stay-displayer/types'
import { useStore } from '@/store'
import { PredictedLabel } from '@/store/modules/diagnosis/types'
import { Stay } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentViewer',
  props: {
    currentStay: {},
    currentDocument: {},
    displayedRumIdx: {},
    selection: {},
    rumPredictedLabels: {},
    shouldScroll: { type: Boolean, default: false },
    enableFalseJustificationFeatures: { type: Boolean, default: true }
  },
  emits: ['disable-justification-scrolling'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const props = __props

const emit = __emit

const hasHealthData = computed(() => {
  return [
    props.currentStay.reports.filter((r) => r.content),
    props.currentStay.textualHealthEntries.filter((t) => t.content),
    props.currentStay.categoricalLabResults.filter((c) => c.value),
    props.currentStay.healthConstants,
    props.currentStay.labResults,
    props.currentStay.drugEvents,
    props.currentStay.namedEntities
  ].flat().length > 0
})

const isDocumentSource = ref(false)

function setCurrentDocument(documentSource: DocumentSourceIdentifier) {
  let type = documentSource.type

  if (documentSource.type === HealthDataType.TEXTUAL_HEALTH_ENTRY) {
    type = _.find(
      props.currentStay.textualHealthEntries,
      (textualHealthEntry: TextualHealthEntry) => textualHealthEntry.id === documentSource.id
    ).type
  }

  store.commit('setMainContent', {
    type: type,
    targetId: documentSource.id
  })
  isDocumentSource.value = true
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!hasHealthData.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Aucun document concernant ce séjour "))
      : (_ctx.currentDocument.type === _unref(HealthDataType).REPORT)
        ? (_openBlock(), _createBlock(ReportViewer, {
            key: 1,
            report: _ctx.currentStay.reports
        .find((item) => _ctx.currentDocument.targetId === item.id),
            "displayed-rum-idx": _ctx.displayedRumIdx,
            selection: _ctx.selection,
            "saved-searches": _ctx.currentStay.staySavedSearches,
            "rum-predicted-labels": _ctx.rumPredictedLabels,
            "should-scroll": _ctx.shouldScroll,
            "is-document-source": isDocumentSource.value,
            "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
            onDisableJustificationScrolling: _cache[0] || (_cache[0] = ($event: any) => (emit('disable-justification-scrolling')))
          }, null, 8, ["report", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "is-document-source", "enable-false-justification-features"]))
        : (_ctx.currentDocument.type === _unref(HealthDataType).DRUG_EVENT)
          ? (_openBlock(), _createBlock(_unref(DrugEventsViewer), {
              key: 2,
              "drug-events": _ctx.currentStay.drugEvents
        .filter((item) => item.prestationType === _ctx.currentDocument.drugEventPrestationType),
              "active-drug-event": _unref(getHighlightedDrugEvent)(_ctx.currentStay.drugEvents, _ctx.selection, _ctx.displayedRumIdx),
              "drug-event-prestation-type": _ctx.currentDocument.drugEventPrestationType
            }, null, 8, ["drug-events", "active-drug-event", "drug-event-prestation-type"]))
          : (_ctx.currentDocument.type === _unref(HealthDataType).CONSTANT)
            ? (_openBlock(), _createBlock(_unref(HealthConstantsViewer), {
                key: 3,
                "health-constants": _ctx.currentStay.healthConstants,
                "active-health-constant": _unref(getHighlightedHealthData)(_ctx.currentStay.healthConstants, _ctx.selection, _ctx.displayedRumIdx),
                onShowSource: _cache[1] || (_cache[1] = (documentSource) => setCurrentDocument(documentSource))
              }, null, 8, ["health-constants", "active-health-constant"]))
            : (_ctx.currentDocument.type === _unref(HealthDataType).LAB_RESULT)
              ? (_openBlock(), _createBlock(_unref(LabResultsViewer), {
                  key: 4,
                  "numerical-lab-results": _ctx.currentStay.labResults,
                  "categorical-lab-results": _ctx.currentStay.categoricalLabResults
        .filter((item) => item.value),
                  "active-lab-result": _unref(getHighlightedHealthData)(_ctx.currentStay.labResults, _ctx.selection, _ctx.displayedRumIdx),
                  "active-categorical-lab-result": _unref(getHighlightedHealthData)(_ctx.currentStay.categoricalLabResults, _ctx.selection, _ctx.displayedRumIdx),
                  onShowSource: _cache[2] || (_cache[2] = (documentSource) => setCurrentDocument(documentSource))
                }, null, 8, ["numerical-lab-results", "categorical-lab-results", "active-lab-result", "active-categorical-lab-result"]))
              : (_ctx.currentDocument.type === _unref(HealthDataType).NAMED_ENTITY)
                ? (_openBlock(), _createBlock(_unref(NamedEntitiesViewer), {
                    key: 5,
                    "named-entities": _ctx.currentStay.namedEntities,
                    "highlighted-named-entity-ids": [],
                    onShowSource: _cache[3] || (_cache[3] = (documentSource) => setCurrentDocument(documentSource))
                  }, null, 8, ["named-entities"]))
                : (_ctx.currentDocument.type === _unref(TextualHealthEntryType).NURSE_REPORT)
                  ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                      key: 6,
                      "textual-health-entries": _ctx.currentStay.textualHealthEntries
        .filter((item) => item.type === _unref(TextualHealthEntryType).NURSE_REPORT && item.content),
                      "displayed-rum-idx": _ctx.displayedRumIdx,
                      selection: _ctx.selection,
                      "saved-searches": _ctx.currentStay.staySavedSearches,
                      "rum-predicted-labels": _ctx.rumPredictedLabels,
                      "should-scroll": _ctx.shouldScroll,
                      type: _unref(TextualHealthEntryType).NURSE_REPORT,
                      "is-document-source": isDocumentSource.value,
                      "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
                      onDisableJustificationScrolling: _cache[4] || (_cache[4] = ($event: any) => (emit('disable-justification-scrolling')))
                    }, null, 8, ["textual-health-entries", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "type", "is-document-source", "enable-false-justification-features"]))
                  : (_ctx.currentDocument.type === _unref(TextualHealthEntryType).TARGET_NURSE_REPORT)
                    ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                        key: 7,
                        "textual-health-entries": _ctx.currentStay.textualHealthEntries
        .filter((item) => item.type === _unref(TextualHealthEntryType).TARGET_NURSE_REPORT && item.content),
                        "displayed-rum-idx": _ctx.displayedRumIdx,
                        selection: _ctx.selection,
                        "saved-searches": _ctx.currentStay.staySavedSearches,
                        "rum-predicted-labels": _ctx.rumPredictedLabels,
                        "should-scroll": _ctx.shouldScroll,
                        type: _unref(TextualHealthEntryType).TARGET_NURSE_REPORT,
                        "is-document-source": isDocumentSource.value,
                        "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
                        onDisableJustificationScrolling: _cache[5] || (_cache[5] = ($event: any) => (emit('disable-justification-scrolling')))
                      }, null, 8, ["textual-health-entries", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "type", "is-document-source", "enable-false-justification-features"]))
                    : (_ctx.currentDocument.type === _unref(TextualHealthEntryType).MEDICAL_HISTORY)
                      ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                          key: 8,
                          "textual-health-entries": _ctx.currentStay.textualHealthEntries
        .filter((item) => item.type === _unref(TextualHealthEntryType).MEDICAL_HISTORY && item.content),
                          "displayed-rum-idx": _ctx.displayedRumIdx,
                          selection: _ctx.selection,
                          "saved-searches": _ctx.currentStay.staySavedSearches,
                          "rum-predicted-labels": _ctx.rumPredictedLabels,
                          "should-scroll": _ctx.shouldScroll,
                          type: _unref(TextualHealthEntryType).MEDICAL_HISTORY,
                          "is-document-source": isDocumentSource.value,
                          "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
                          onDisableJustificationScrolling: _cache[6] || (_cache[6] = ($event: any) => (emit('disable-justification-scrolling')))
                        }, null, 8, ["textual-health-entries", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "type", "is-document-source", "enable-false-justification-features"]))
                      : (_ctx.currentDocument.type === _unref(TextualHealthEntryType).LAB_RESULT_COMMENT)
                        ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                            key: 9,
                            "textual-health-entries": _ctx.currentStay.textualHealthEntries
        .filter((item) => item.type === _unref(TextualHealthEntryType).LAB_RESULT_COMMENT && item.content),
                            "displayed-rum-idx": _ctx.displayedRumIdx,
                            selection: _ctx.selection,
                            "saved-searches": _ctx.currentStay.staySavedSearches,
                            "rum-predicted-labels": _ctx.rumPredictedLabels,
                            "should-scroll": _ctx.shouldScroll,
                            type: _unref(TextualHealthEntryType).LAB_RESULT_COMMENT,
                            "is-document-source": isDocumentSource.value,
                            "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
                            onDisableJustificationScrolling: _cache[7] || (_cache[7] = ($event: any) => (emit('disable-justification-scrolling')))
                          }, null, 8, ["textual-health-entries", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "type", "is-document-source", "enable-false-justification-features"]))
                        : (_ctx.currentDocument.type === _unref(TextualHealthEntryType).SURGERY_LABEL)
                          ? (_openBlock(), _createBlock(TextualHealthEntriesViewer, {
                              key: 10,
                              "textual-health-entries": _ctx.currentStay.textualHealthEntries
        .filter((item) => item.type === _unref(TextualHealthEntryType).SURGERY_LABEL && item.content),
                              "displayed-rum-idx": _ctx.displayedRumIdx,
                              selection: _ctx.selection,
                              "saved-searches": _ctx.currentStay.staySavedSearches,
                              "rum-predicted-labels": _ctx.rumPredictedLabels,
                              "should-scroll": _ctx.shouldScroll,
                              type: _unref(TextualHealthEntryType).SURGERY_LABEL,
                              "is-document-source": isDocumentSource.value,
                              "enable-false-justification-features": _ctx.enableFalseJustificationFeatures,
                              onDisableJustificationScrolling: _cache[8] || (_cache[8] = ($event: any) => (emit('disable-justification-scrolling')))
                            }, null, 8, ["textual-health-entries", "displayed-rum-idx", "selection", "saved-searches", "rum-predicted-labels", "should-scroll", "type", "is-document-source", "enable-false-justification-features"]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _cache[9] || (_cache[9] = _createTextVNode(" Type de document \"")),
                              _createElementVNode("strong", null, _toDisplayString(_ctx.currentDocument.type), 1),
                              _cache[10] || (_cache[10] = _createTextVNode("\" inconnu "))
                            ]))
  ]))
}
}

})