import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "data-table" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-8" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-8" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-8" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-8" }
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "col-8" }
const _hoisted_14 = {
  key: 1,
  class: "row"
}
const _hoisted_15 = { class: "col-8" }

import { DestinationCh, OriginCh } from '@sancare/ui-frontend-commons/src/types/origin-destination'
import { ref } from 'vue'

import { LocalizedOption } from '@/store/modules/filters/types'
import { RumCh } from '@/store/modules/stay/mco/types'

import FiltersData from '../filters/FiltersData'
import { CountryEnum } from '../store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RumCh',
  props: {
    rums: {}
  },
  setup(__props: any) {

const props = __props

const rum = ref(props.rums[0])

// OFS V1203
function explainAdmissionMode(mode: string): string {
  return (FiltersData.admissionMode.options as LocalizedOption[]).find(
    (option) => option.country === CountryEnum.CH && Number(option.value) === Number(mode ?? '09')
  ).label
}
// OFS V1202
function explainOrigin(origin: string): string {
  return OriginCh[Number(origin ?? '09')]
}

// OFS V1502
function explainReleaseMode(mode: string): string {
  return (FiltersData.releaseMode.options  as LocalizedOption[]).find(
    (option) => option.country === CountryEnum.CH && Number(option.value) === Number(mode ?? '09')
  ).label
}
// OFS V1503
function explainDestination(destination: string): string {
  return DestinationCh[Number(destination ?? '09')]
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "col-4" }, " MODE D'ENTRÉE ", -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(explainAdmissionMode(rum.value.admissionMode)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "col-4" }, " SEJOUR AVANT ADMISSION ", -1)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(explainOrigin(rum.value.origin)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "col-4" }, " DECISION DE SORTIE ", -1)),
      _createElementVNode("div", _hoisted_7, _toDisplayString(explainReleaseMode(rum.value.releaseMode)), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "col-4" }, " SEJOUR APRES SORTIE ", -1)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(explainDestination(rum.value.destination)), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "col-4" }, " DURÉE DE LA VENTIL. ART. ", -1)),
      _createElementVNode("div", _hoisted_11, _toDisplayString(rum.value.artificialVentilationDuration ?? '-'), 1)
    ]),
    (rum.value.newbornWeight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "col-4" }, " POIDS A L'ADMISSION ", -1)),
          _createElementVNode("div", _hoisted_13, _toDisplayString(rum.value.newbornWeight), 1)
        ]))
      : _createCommentVNode("", true),
    (rum.value.pregnancyDuration)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "col-4" }, " DURÉE DE LA GROSSESSE ", -1)),
          _createElementVNode("div", _hoisted_15, _toDisplayString(rum.value.pregnancyDuration), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})