import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "dashboard-title d-flex justify-content-between" }
const _hoisted_2 = {
  key: 0,
  class: "aligned-row font-italic"
}
const _hoisted_3 = { class: "dashboard-title d-flex justify-content-between" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "aligned-row font-italic" }
const _hoisted_6 = { class: "dashboard" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "aligned-row font-italic"
}

import { Dayjs } from 'dayjs'
import { computed, ComputedRef, Ref, ref } from 'vue'

import DiagnosisForm from '@/stay-displayer/ssr/rhs/diagnosis/DiagnosisForm.vue'
import RhsDiagnosisItem from '@/stay-displayer/ssr/rhs/RhsDiagnosisItem.vue'
import {
  Diagnosis,
  DiagnosisType,
  getSingleDiagnosisTypes,
  PredictedLabel,
  SuggestedDiagnosis
} from '@/store/modules/diagnosis/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { RhsDashboardClass, RhsDashboardType, SsrDiagnosisGroup } from '@/store/modules/stay/ssr/types'
import { StayHealthData } from '@/store/modules/stay/types'
import { TextProcessingStaySavedSearch } from '@/text-processing/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsDiagnoses',
  props: {
    rhsId: {},
    rhsEndDate: {},
    currentModeDiagnosisGroup: {},
    predictedDiagnoses: {},
    healthData: {},
    savedSearchIntersectionChunks: {},
    staySavedSearches: {}
  },
  setup(__props: any) {


const props = __props

const currentFormDisplayed: Ref<DiagnosisType | null> = ref(null)

const diagnosisTypesList = computed(() => getSingleDiagnosisTypes(PmsiTypeEnum.SSR, props.rhsEndDate))

const currentDiagnoses = computed(() => {
  const diagnoses = {}

  for (const diagnosisType of diagnosisTypesList.value) {
    diagnoses[diagnosisType] = null
    if(diagnosisType.toLowerCase() in props.currentModeDiagnosisGroup
      && props.currentModeDiagnosisGroup[diagnosisType.toLowerCase()] !== null
      && props.currentModeDiagnosisGroup[diagnosisType.toLowerCase()].associatedDiagnosis !== null
    ) {
      diagnoses[diagnosisType] = {
        ...props.currentModeDiagnosisGroup[diagnosisType.toLowerCase()].associatedDiagnosis,
        id: props.currentModeDiagnosisGroup[diagnosisType.toLowerCase()].id
      }
    }
  }

  diagnoses[DiagnosisType.DAS] = []

  for(const dasDiagnosis of props.currentModeDiagnosisGroup.dasList) {
    diagnoses[DiagnosisType.DAS].push({
      ...dasDiagnosis.associatedDiagnosis,
      id: dasDiagnosis.id,
    })
  }

  // Alphabetical order
  diagnoses[DiagnosisType.DAS] = (diagnoses[DiagnosisType.DAS] as Diagnosis[]).sort((diagnosis1, diagnosis2) =>
    diagnosis1.reference.localeCompare(diagnosis2.reference)
  )

  return diagnoses
})

const predictedDiagnoses = computed(() => {
  return props.predictedDiagnoses.filter((suggestion) => {
    const reference = suggestion.diagnosis.reference

    const flatCurrentDiagnosisRefs = [
      ...currentDiagnoses.value[DiagnosisType.DAS],
      ...diagnosisTypesList.value
        .reduce((acc, type) => ([...acc, ...(currentDiagnoses.value[type] ? [currentDiagnoses.value[type]] : [])]), [])
    ]
      .map((diagnosis) => diagnosis.reference)

    return !flatCurrentDiagnosisRefs.includes(reference)
  })
})

const suggestedDiagnoses: ComputedRef<SuggestedDiagnosis[]> = computed(() => {
  let suggestedDiagnoses: SuggestedDiagnosis[] = []

  props.staySavedSearches.forEach((staySavedSearch) => {
    const diagnoses = staySavedSearch.search.suggestedDiagnoses.map((suggestedDiag) => {
      return { ...suggestedDiag.diagnosis, search: staySavedSearch.search, suggestedType: suggestedDiag.type }
    })
    suggestedDiagnoses = suggestedDiagnoses.concat(diagnoses)
  })

  return suggestedDiagnoses
})

const selectableSuggestedDiagnoses: ComputedRef<SuggestedDiagnosis[]> = computed(() => {
  return suggestedDiagnoses.value.filter((diagnosis) => {
    const reference = diagnosis.reference

    const flatCurrentDiagnosisRefs = [
      ...currentDiagnoses.value[DiagnosisType.DAS],
      ...diagnosisTypesList.value
        .reduce((acc, type) => ([...acc, ...(currentDiagnoses.value[type] ? [currentDiagnoses.value[type]] : [])]), [])
    ]
      .map((diagnosis) => diagnosis.reference)

    return !flatCurrentDiagnosisRefs.includes(reference)
  })
})

const hasSelectableDiagnoses = predictedDiagnoses.value.length || selectableSuggestedDiagnoses.value.length

function getPredictionConfidence(reference: string) {
  return props.predictedDiagnoses.find((diag) => diag['diagnosis']['reference'] === reference)?.confidence ?? null
}

const setDisplayedForm = (diagnosisType: DiagnosisType, displayForm: boolean) => {
  currentFormDisplayed.value = displayForm ? diagnosisType : null
}

function getCodedDiagnosisSuggestingSearch(reference: string) {
  const matchedSuggestedDiagnosis = suggestedDiagnoses.value.find((diag: SuggestedDiagnosis) => diag.reference === reference)

  return matchedSuggestedDiagnosis?.search
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(diagnosisTypesList.value, (diagnosisType) => {
      return (_openBlock(), _createElementBlock("section", {
        key: diagnosisType,
        class: _normalizeClass(["dashboard mb-2", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_DIAGNOSES]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("small", null, _toDisplayString(diagnosisType), 1),
          _cache[1] || (_cache[1] = _createElementVNode("small", null, "codage en cours", -1))
        ]),
        (currentDiagnoses.value[diagnosisType])
          ? (_openBlock(), _createBlock(RhsDiagnosisItem, {
              key: 0,
              diagnosis: currentDiagnoses.value[diagnosisType],
              "diagnosis-type": diagnosisType,
              confidence: getPredictionConfidence(currentDiagnoses.value[diagnosisType]['reference']),
              "is-suggestion": false,
              "rhs-id": _ctx.rhsId,
              "rhs-end-date": _ctx.rhsEndDate,
              search: getCodedDiagnosisSuggestingSearch(currentDiagnoses.value[diagnosisType]['reference'])
            }, null, 8, ["diagnosis", "diagnosis-type", "confidence", "rhs-id", "rhs-end-date", "search"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (!currentDiagnoses.value[diagnosisType] && currentFormDisplayed.value !== diagnosisType)
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, " Aucun " + _toDisplayString(diagnosisType) + " codé ", 1))
            : _createCommentVNode("", true),
          _createVNode(DiagnosisForm, {
            diagnosis: currentDiagnoses.value[diagnosisType],
            "diagnosis-type": diagnosisType,
            "rhs-id": _ctx.rhsId,
            "current-diagnosis-type-form-displayed": currentFormDisplayed.value,
            onDisplayForm: displayForm => setDisplayedForm(diagnosisType, displayForm)
          }, null, 8, ["diagnosis", "diagnosis-type", "rhs-id", "current-diagnosis-type-form-displayed", "onDisplayForm"])
        ])
      ], 2))
    }), 128)),
    _createElementVNode("section", {
      class: _normalizeClass(["dashboard mb-2", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_DIAGNOSES]])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("small", null, _toDisplayString(_unref(DiagnosisType).DAS), 1),
        _cache[2] || (_cache[2] = _createElementVNode("small", null, "codage en cours", -1))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentDiagnoses.value[_unref(DiagnosisType).DAS], (diagnosis) => {
        return (_openBlock(), _createBlock(RhsDiagnosisItem, {
          key: `das-diagnosis-${diagnosis.id}`,
          diagnosis: diagnosis,
          "diagnosis-type": _unref(DiagnosisType).DAS,
          confidence: getPredictionConfidence(diagnosis['reference']),
          "is-suggestion": false,
          "rhs-id": _ctx.rhsId,
          "rhs-end-date": _ctx.rhsEndDate,
          search: getCodedDiagnosisSuggestingSearch(diagnosis['reference'])
        }, null, 8, ["diagnosis", "diagnosis-type", "confidence", "rhs-id", "rhs-end-date", "search"]))
      }), 128)),
      (!Object.keys(currentDiagnoses.value[_unref(DiagnosisType).DAS]).length
          && currentFormDisplayed.value !== _unref(DiagnosisType).DAS)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("small", _hoisted_5, "Aucun " + _toDisplayString(_unref(DiagnosisType).DAS) + " codé", 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(DiagnosisForm, {
        diagnosis: currentDiagnoses.value[_unref(DiagnosisType).DAS],
        "diagnosis-type": _unref(DiagnosisType).DAS,
        "rhs-id": _ctx.rhsId,
        "current-diagnosis-type-form-displayed": currentFormDisplayed.value,
        onDisplayForm: _cache[0] || (_cache[0] = displayForm => setDisplayedForm(_unref(DiagnosisType).DAS, displayForm))
      }, null, 8, ["diagnosis", "diagnosis-type", "rhs-id", "current-diagnosis-type-form-displayed"])
    ], 2),
    _createElementVNode("section", _hoisted_6, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "dashboard-title" }, [
        _createElementVNode("small", null, "Diagnostics suggérés")
      ], -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(predictedDiagnoses.value, (diagnosis) => {
        return (_openBlock(), _createBlock(RhsDiagnosisItem, {
          key: `predicted-diagnosis-${diagnosis.id}`,
          diagnosis: { ...diagnosis['diagnosis'], id: diagnosis.id },
          confidence: diagnosis['confidence'],
          "is-suggestion": true,
          "rhs-id": _ctx.rhsId,
          "rhs-end-date": _ctx.rhsEndDate
        }, null, 8, ["diagnosis", "confidence", "rhs-id", "rhs-end-date"]))
      }), 128)),
      (_ctx.staySavedSearches.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectableSuggestedDiagnoses.value, (suggestedDiagnosis) => {
              return (_openBlock(), _createBlock(RhsDiagnosisItem, {
                key: `suggested-diagnosis-${suggestedDiagnosis.reference}`,
                diagnosis: suggestedDiagnosis,
                "diagnosis-type": suggestedDiagnosis.suggestedType,
                confidence: null,
                "is-suggestion": true,
                "rhs-id": _ctx.rhsId,
                "rhs-end-date": _ctx.rhsEndDate,
                search: suggestedDiagnosis.search,
                "health-data": _ctx.healthData,
                "saved-search-intersection-chunks": _ctx.savedSearchIntersectionChunks,
                "stay-saved-searches": _ctx.staySavedSearches
              }, null, 8, ["diagnosis", "diagnosis-type", "rhs-id", "rhs-end-date", "search", "health-data", "saved-search-intersection-chunks", "stay-saved-searches"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_unref(hasSelectableDiagnoses))
        ? (_openBlock(), _createElementBlock("small", _hoisted_8, " Aucun diagnostic suggéré "))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})