import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "aligned-row mt-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "error-block mt-1 mb-2"
}
const _hoisted_5 = { class: "d-flex justify-content-end" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { key: 0 }

import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import LoadingMark from '@/misc/LoadingMark.vue'
import { Diagnosis, DiagnosisType } from '@/store/modules/diagnosis/types'



export default /*@__PURE__*/_defineComponent({
  __name: 'DiagnosisForm',
  props: {
    diagnosis: {},
    diagnosisType: {},
    rhsId: {},
    currentDiagnosisTypeFormDisplayed: {}
  },
  emits: ["display-form"],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const props = __props

const emit = __emit

const isFormDisplayed = ref(false)
const typedDiagnosisReference = ref('')
const isDiagnosisCompletionDisplayed = ref(false)
const selectedDiagnosisReference = ref('')
const addDiagnosisRequest = ref({ fetching: false, error: null, ok: true })
let abortController: AbortController

const diagnosisSearchResults = computed(
  () => store.state.stayDisplayer.diagnosisSearchResults[props.diagnosisType]
)
const diagnosisSearchRequest = computed(
  () => store.state.stayDisplayer.searchDiagnosesRequest[props.diagnosisType]
)

const isTypedDiagnosisEmpty = computed(() => typedDiagnosisReference.value === '')

const resetAddDiagnosisRequest = () => {
  addDiagnosisRequest.value = { fetching: false, error: null, ok: false }
}

const displayForm = (isDisplayed: boolean) => {
  emit('display-form', isDisplayed)
  isFormDisplayed.value = isDisplayed
  typedDiagnosisReference.value = ''
  resetAddDiagnosisRequest()
}

const displayCompletion = (isDisplayed: boolean) => {
  isDiagnosisCompletionDisplayed.value = isDisplayed
}

const selectItem = (diag: Diagnosis) => {
  selectedDiagnosisReference.value = diag.reference
  typedDiagnosisReference.value = diag.reference
}

const addDiagnosis = async () => {
  resetAddDiagnosisRequest()
  addDiagnosisRequest.value.fetching = true

  const response: { error: string } | null = await store.dispatch('ssrDiagnosis/addSsrDiagnosis', {
    body: {
      newDiagnosisType: props.diagnosisType,
      diagnosis: typedDiagnosisReference.value,
    },
    urlParams: {
      rhsId: props.rhsId,
    },
  })

  if (response?.error) {
    addDiagnosisRequest.value.error = response.error
  } else {
    addDiagnosisRequest.value.ok = true
    displayForm(false)
  }

  addDiagnosisRequest.value.fetching = false
}

watch(typedDiagnosisReference, _.debounce(() => {
  if (abortController) {
    abortController.abort()
  }
  if (selectedDiagnosisReference.value && selectedDiagnosisReference.value === typedDiagnosisReference.value) {
    return
  }

  if (typedDiagnosisReference.value) {
    abortController = new AbortController()
    const signal = abortController.signal

    store.dispatch('searchDiagnoses', {
      diagnosisType: props.diagnosisType,
      search: typedDiagnosisReference.value,
      signal,
      searchByType: true,
    })
  } else {
    store.commit('clearSearchDiagnoses', props.diagnosisType)
  }
}, 500))

watch(() => props.currentDiagnosisTypeFormDisplayed, (newFormDisplayed) => {
  if(newFormDisplayed !== props.diagnosisType) {
    isFormDisplayed.value = false
  }
})

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isFormDisplayed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "col-12 btn btn-sm",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (displayForm(true)))
          }, _toDisplayString(!_ctx.diagnosis || _ctx.diagnosisType === _unref(DiagnosisType).DAS
          ? `Ajouter un ${_ctx.diagnosisType}`
          : `Modifier le ${_ctx.diagnosisType}`), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((typedDiagnosisReference).value = $event)),
            type: "text",
            class: _normalizeClass(["form-control mt-3", {'mb-2': !addDiagnosisRequest.value.error}]),
            onKeydown: [
              _cache[2] || (_cache[2] = () => displayCompletion(true)),
              _cache[3] || (_cache[3] = _withKeys(() => displayCompletion(false), ["esc"]))
            ]
          }, null, 34), [
            [_vModelText, typedDiagnosisReference.value],
            [_directive_on_click_outside, () => displayCompletion(false)],
            [_directive_focus]
          ]),
          _withDirectives(_createVNode(_unref(InputAutoCompletion), {
            "completion-list": diagnosisSearchResults.value,
            "is-loading": diagnosisSearchRequest.value.fetching,
            "title-prop": 'reference',
            "description-prop": 'description',
            onSelectItem: selectItem
          }, null, 8, ["completion-list", "is-loading"]), [
            [_vShow, isDiagnosisCompletionDisplayed.value && !isTypedDiagnosisEmpty.value]
          ]),
          (addDiagnosisRequest.value.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(addDiagnosisRequest.value.error), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline mr-2 button-diagnosis",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (displayForm(false)))
            }, " Annuler "),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-sm button-diagnosis", {'disabled': isTypedDiagnosisEmpty.value}]),
              disabled: isTypedDiagnosisEmpty.value,
              onClick: addDiagnosis
            }, [
              (!addDiagnosisRequest.value.fetching)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Valider"))
                : (_openBlock(), _createBlock(LoadingMark, {
                    key: 1,
                    finished: !addDiagnosisRequest.value.fetching,
                    class: "spinner",
                    color: "blue"
                  }, null, 8, ["finished"]))
            ], 10, _hoisted_6)
          ])
        ]))
  ]))
}
}

})