import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import usePmsiStayListTable from '@/stay-list/stay-list-table/composables/pmsiStayListTable'
import { ListSsrStay } from '@/stay-list/stay-list-table/ssr/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SsrStayListTable',
  setup(__props) {

const PmsiStayListTable = usePmsiStayListTable<ListSsrStay>(PmsiTypeEnum.SSR)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(PmsiStayListTable))))
}
}

})