<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapState } from 'vuex'

import { PmsiTypeEnum } from '@/store/modules/settings/types'

import AdvancedSearchCriteriaGroup from './AdvancedSearchCriteriaGroup.vue'

export default {
  components: {
    'advanced-search-criteria-group': AdvancedSearchCriteriaGroup,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    disabled: { type: Boolean, required: true },
    mode: { type: String, required: true },
    pmsiType: {
      validator(value) {
        return Object.values(PmsiTypeEnum).includes(value)
      },
      default: PmsiTypeEnum.MCO
    },
  },
  emits: ['clear-message'],
  computed: {
    ...mapState({
      globalCriteriaGroup: (state) => _.isEmpty(state.stayList.currentSearch.globalCriteriaGroup) ? {} : state.stayList.currentSearch.globalCriteriaGroup,
      criteriaGroups: (state) => state.stayList.currentSearch.criteriaGroups,
      externalOperator: (state) => state.stayList.currentSearch.searchOperators === 'andOr' ? 'ET' : 'OU',
      internalOperator: (state) => state.stayList.currentSearch.searchOperators === 'andOr' ? 'OU' : 'ET',
    }),
  },
  mounted() {
    if (_.isEmpty(this.globalCriteriaGroup)) {
      this.$store.commit('initiateGlobalCriteriaGroup')
    }
  },
  methods: {
    clearMessage() {
      this.$emit('clear-message')
    },
    addCriteriaGroup() {
      this.clearMessage()
      if (!this.disabled) {
        this.$store.commit('addCriteriaGroup')
      }
    },
    deleteCriteriaGroup(id) {
      this.clearMessage()
      this.$store.commit('removeCriteriaGroup', id)
    }
  }
}
</script>

<template>
  <div class="advanced-search-criteria-groups">
    <div class="row justify-content-center mb-4">
      <div class="col-12 row align-items-stretch">
        <advanced-search-criteria-group
          :internal-operator="internalOperator"
          :group="globalCriteriaGroup"
          :group-is-global="true"
          :mode="mode"
          :pmsi-type="pmsiType"
          class="col-12 mb-4 mb-md-0"
        />
      </div>
    </div>

    <div class="px-5 px-md-3">
      <div class="criteria-groups-wrapper gap-3">
        <template
          v-for="(group, idx) in criteriaGroups"
          :key="group.id"
        >
          <advanced-search-criteria-group
            class="w-100"
            :internal-operator="internalOperator"
            :group="group"
            :group-is-global="false"
            :mode="mode"
            :pmsi-type="pmsiType"
            @delete-criteria-group="deleteCriteriaGroup"
            @clear-message="clearMessage"
          />
          <div
            class="operator d-flex align-items-center justify-content-center text-center"
          >
            {{ externalOperator }}
          </div>
        </template>


        <div class="d-flex flex-column flex-md-row align-items-center gap-2 align-items-stretch mb-2 pb-2">
          <div
            class="automation-criteria-group automation-criteria-group-add gap-2"
            :class="{ disabled }"
            @click="addCriteriaGroup"
          >
            <sancare-octicon
              name="diff-added"
              :width="30"
            />
            <div>Ajouter un groupe de critères</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.criteria-groups-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.operator {
  width: 100%;
}

@media (min-width: 768px) {
  .criteria-groups-wrapper {
    grid-template-columns: 1fr 2rem 1fr;
  }

  .operator {
    width: 2rem;

    &:nth-child(4n) {
      display: none !important;
    }
  }
}

.automation-criteria-group-add {
  background: #F0F0F0;
  border-radius: 0.3rem;
  min-height: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #A0A0A0;
  cursor: pointer;
  border: solid 1px #E0E0E0;

  &:hover {
    border-color: #A0A0A0;
    color: #505050;
  }

  transition: border-color 0.15s, color 0.15s;

  &.disabled {
    cursor: default;

    &:hover {
      border-color: #E0E0E0;
      color: #A0A0A0;
    }
  }
}
</style>
