import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'AutomationGroupStayListValidationButton',
  emits: ["refresh-staylist"],
  setup(__props, { emit: __emit }) {

const store = useStore()

const demoModeEnabled = computed(() => store.getters.flatSancareSettings['demo_mode'])
const stayList = computed(() => store.state.automation.automationStayList.items)
const automationGroupId = computed(() => Number(store.state.route.query.automationGroupId))
const automationMode = computed(() => store.state.route.query.automationMode)
const selectedStayIds = computed(() => store.state.stayList.selectedStayIds)
const selectedStaysCount = computed(() => selectedStayIds.value.length)
const label = computed(() => `Valider ${selectedStaysCount.value > 0 ? `(${selectedStaysCount.value})` : 'tout'}`)

const emit = __emit

const showValidationDropdown = ref(false)

function validateAutomatableStays() {
  if (!selectedStayIds.value.length) {
    store.dispatch('validateAutomatableList', { automationGroupId: automationGroupId.value, mode: automationMode.value }).then(() => {
      emit('refresh-staylist')
    })
  } else {
    store.dispatch('validateAutomatableSelection', { automationGroupId: automationGroupId.value, stayIds: selectedStayIds.value }).then(() => {
      emit('refresh-staylist')
    })
  }
  showValidationDropdown.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createBlock(_component_v_dropdown, {
    shown: showValidationDropdown.value,
    onHide: _cache[1] || (_cache[1] = ($event: any) => (showValidationDropdown.value = false))
  }, {
    popper: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-light text-center mb-2" }, [
        _createElementVNode("h4", null, " Êtes-vous sûr(e) ? "),
        _createElementVNode("span", null, "Cette action est irréversible")
      ], -1)),
      _createElementVNode("div", { class: "text-center" }, [
        _createElementVNode("button", {
          class: "btn btn-danger btn-padded",
          onClick: validateAutomatableStays
        }, " Confirmer ")
      ])
    ]),
    default: _withCtx(() => [
      (automationGroupId.value && !demoModeEnabled.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["btn sancare-btn rounded-right", { disabled: !stayList.value.length }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showValidationDropdown.value = true))
          }, _toDisplayString(label.value), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["shown"]))
}
}

})