import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'

import ExportStayListButton from '@/stay-list/stay-list-table/actions/ExportStayListButton.vue'
import ReadingListButton from '@/stay-list/stay-list-table/actions/ReadingListButton.vue'
import StayListActions from '@/stay-list/stay-list-table/actions/StayListActions.vue'
import { ListMcoStay } from '@/stay-list/stay-list-table/mco/types'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'McoStayListActions',
  emits: ["set-stay-list-parameter"],
  setup(__props, { emit: __emit }) {

const store = useStore()

const exportError = ref(null)

const mode = computed(() => store.state.stayList.stayListParams.mode)
const stayList = computed(() => store.state.stayList.stayList.items.map((stay: ListMcoStay) => stay.id))
const exportRequest = computed(() => store.getters.getStayListExportRequest)
const params = computed(() => ({
  showSeen: store.state.stayList.stayListParams.showSeen,
  pageSize: store.state.stayList.stayListParams.pageSize
}))

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(StayListActions, {
    params: params.value,
    "error-message": exportError.value,
    onSetStayListParameter: _cache[1] || (_cache[1] = (name, value) => _ctx.$emit('set-stay-list-parameter', name, value))
  }, {
    default: _withCtx(() => [
      _createVNode(ExportStayListButton, {
        mode: mode.value,
        request: exportRequest.value,
        onError: _cache[0] || (_cache[0] = message => exportError.value = message)
      }, null, 8, ["mode", "request"]),
      _createVNode(ReadingListButton, {
        mode: mode.value,
        "stay-list": stayList.value,
        "base-stay-displayer-path": "/id"
      }, null, 8, ["mode", "stay-list"])
    ]),
    _: 1
  }, 8, ["params", "error-message"]))
}
}

})