import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }

import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { Stay } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientData',
  props: {
    stay: {},
    pseudonymizationEnabled: { type: Boolean },
    pmsiType: { default: PmsiTypeEnum.MCO }
  },
  setup(__props: any) {



function displayGender(gender: string): string {
  switch (gender) {
    case '1':
      return 'M'
    case '2':
      return 'F'
    case '3':
      return 'I'
    default:
      return gender
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.pseudonymizationEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", {
            class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-4' : 'col-5')
          }, " DATE DE NAISSANCE ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-8' : 'col-7')
          }, _toDisplayString(_ctx.stay.patient.birthDate ? _ctx.stay.patient.birthDate.format('L') : 'Non renseigné'), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-4' : 'col-5')
      }, " ÂGE EN ENTRÉE ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-8' : 'col-7')
      }, _toDisplayString(_ctx.stay.patientAge ? _ctx.stay.patientAge + ' ans' : 'Non renseigné'), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h1", {
        class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-4' : 'col-5')
      }, " SEXE ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.pmsiType === _unref(PmsiTypeEnum).MCO ? 'col-8' : 'col-7')
      }, _toDisplayString(displayGender(_ctx.stay.patient.gender)), 3)
    ])
  ]))
}
}

})