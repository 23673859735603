import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row align-items-center no-gutters hoverable-item flex-nowrap" }
const _hoisted_2 = { class: "col-11 col-lg-10 mr-auto" }
const _hoisted_3 = { class: "rehabilitation-act-ref" }
const _hoisted_4 = {
  key: 0,
  class: "rehabilitation-act-description"
}
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "text-center" }

import { OcticonButton } from '@sancare/ui-frontend-commons'
import { ref } from 'vue'

import { CodedRehabilitationAct } from '@/store/modules/stay/ssr/types'



export default /*@__PURE__*/_defineComponent({
  __name: 'RehabilitationAct',
  props: {
    codedRehabilitationAct: {}
  },
  setup(__props: any) {



const isLoading = ref(false)
const removeConfirm = ref(false)

async function removeAct() {
  // TODO: https://app.clickup.com/t/2628858/WEB-2244
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.codedRehabilitationAct.associatedAct.reference), 1)
      ]),
      (_ctx.codedRehabilitationAct.associatedAct.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.codedRehabilitationAct.associatedAct.description), 1))
        : _createCommentVNode("", true)
    ])), [
      [_directive_tooltip, _ctx.codedRehabilitationAct.associatedAct.description || null]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_dropdown, { shown: removeConfirm.value }, {
        popper: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "title-light text-center" }, " Êtes-vous sûr(e) ? ", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn btn-danger btn-padded",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (removeAct()))
            }, " Confirmer ")
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(OcticonButton), {
            class: "rehabilitation-act-delete-btn",
            "is-loading": isLoading.value,
            "octicon-name": "trash",
            width: 15,
            title: "Supprimer l'acte",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (removeConfirm.value = true))
          }, null, 8, ["is-loading"])
        ]),
        _: 1
      }, 8, ["shown"])
    ])
  ]))
}
}

})