import useI18n, { Gender } from '@/i18n'

export function makeRangeLabel(lowValue: string, highValue: string, suffix: string = '', gender: Gender = Gender.Masculine) {
  const t = useI18n().global.t
  if(highValue[0] === '>' &&  lowValue[0] === '<') {
    return t('range.allValues')
  }

  if (!highValue || highValue[0] === '>') {
    return t(`range.greaterOrEqual.${gender}`, { value: lowValue.replace('>', ''), suffix })
  }

  if (!lowValue || lowValue[0] === '<') {
    return t(`range.lessOrEqual.${gender}`, { value: highValue.replace('<', ''), suffix })
  }

  if(lowValue === highValue) {
    return t(`range.equal.${gender}`, { value: lowValue, suffix })
  }

  return t('range.rangeBetween', { lowValue, highValue, suffix })
}
