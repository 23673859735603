import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import { computed, onMounted, Ref, ref } from 'vue'

import { FilterData } from '@/store/modules/filters/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectFilter',
  props: {
    filterData: {},
    isLegacy: { type: Boolean, default: false },
    pmsiCountry: {}
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const props = __props

const filterValue = ref('')
const subFiltersValues = ref({}) as Ref<{ [key: string]: string }>

const options = computed(() => {
  if (props.filterData.optionsLocalised) {
    return props.filterData.options.filter((option) => option.country === props.pmsiCountry)
  }

  return props.filterData.options.map((option) => {
    return {
      value: option,
      label: displayOptionLabel(props.filterData.labels, option),
    }
  })
})

function addFilter() {
  if(filterValue.value === '') {
    return
  }

  const name = props.filterData.name
  let type = props.filterData.format
  const value = filterValue.value

  type = type.replace('{filterType}', capitalize(props.filterData.name))
  if (props.filterData.subFilters) {
    props.filterData.subFilters.forEach((filter) => {
      type = type.replace(`{${filter.name}}`, capitalize(subFiltersValues.value[filter.name]))
    })
  }
  type = type.charAt(0).toLowerCase() + type.slice(1)
  emit('add-filter', { name, type, value })
}

function displayOptionLabel(labels, option) {
  return (labels && labels[option]) ? labels[option] : option
}

onMounted(() => {
  filterValue.value = props.filterData.default ?? ''
  const defaultValues = {}
  if (props.filterData.subFilters) {
    props.filterData.subFilters.forEach((filter) => {
      if (filter.default) {
        defaultValues[filter.name] = filter.default
        return
      }
      defaultValues[filter.name] = filter.options ? filter.options[0] : ''
    })
  }
  subFiltersValues.value = defaultValues
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'col-4': _ctx.isLegacy && (!_ctx.filterData.subFilters || _ctx.filterData.subFilters.length < 2),
        'col-10': _ctx.isLegacy && _ctx.filterData.subFilters && _ctx.filterData.subFilters.length >= 2,
        'col-12': !_ctx.isLegacy || _ctx.filterData.name.includes('documentCategory')
      }, "input-group"])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterData.subFilters, (filter) => {
        return _withDirectives((_openBlock(), _createElementBlock("select", {
          key: filter.name,
          "onUpdate:modelValue": ($event: any) => ((subFiltersValues.value[filter.name]) = $event),
          class: "custom-select"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (opt) => {
            return (_openBlock(), _createElementBlock("option", {
              key: opt,
              value: opt
            }, _toDisplayString(filter.labels[opt] ? filter.labels[opt] : opt), 9, _hoisted_3))
          }), 128))
        ], 8, _hoisted_2)), [
          [_vModelSelect, subFiltersValues.value[filter.name]]
        ])
      }), 128)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterValue).value = $event)),
        class: "custom-select",
        onChange: addFilter
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("option", {
          value: "",
          disabled: ""
        }, " --- ", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (opt) => {
          return (_openBlock(), _createElementBlock("option", {
            key: opt.value,
            value: opt.value
          }, _toDisplayString(opt.label), 9, _hoisted_4))
        }), 128))
      ], 544), [
        [_vModelSelect, filterValue.value]
      ]),
      _createElementVNode("div", { class: "input-group-append" }, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          type: "button",
          onClick: addFilter
        }, " Ok ")
      ])
    ], 2)
  ]))
}
}

})