import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "row no-gutters align-items-star card-container col-12" }
const _hoisted_2 = { class: "card acts-card col-7 mb-0" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-title d-flex align-items-center mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "list-unstyled medical-act p-0 m-0"
}
const _hoisted_6 = {
  key: 1,
  class: "text-center"
}
const _hoisted_7 = { class: "card dark acts-card col-5 m-0" }
const _hoisted_8 = { class: "py-1 px-3" }
const _hoisted_9 = { class: "card-title d-flex align-items-center mb-2" }
const _hoisted_10 = {
  key: 0,
  class: "list-unstyled predicted-medical-act p-0 m-0"
}
const _hoisted_11 = {
  key: 1,
  class: "text-center"
}
const _hoisted_12 = {
  key: 0,
  style: {"margin-top":"5px"}
}
const _hoisted_13 = { class: "col-auto icon-block px-2" }
const _hoisted_14 = { class: "col-auto" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, Ref, ref } from 'vue'

import MedicalActForm from '@/stay-displayer/medical-act/MedicalActForm.vue'
import { CodingSelection } from '@/stay-displayer/types'
import { PredictedLabel, PredictedMedicalAct as PredictedMedicalActType } from '@/store/modules/diagnosis/types'
import { CodedMedicalAct } from '@/store/modules/medical-act/types'
import { CountryEnum, PmsiTypeEnum } from '@/store/modules/settings/types'
import { Rum } from '@/store/modules/stay/mco/types'
import { StayHealthData } from '@/store/modules/stay/types'

import MedicalAct from './MedicalAct.vue'
import PredictedMedicalAct from './PredictedMedicalAct.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalActList',
  props: {
    codedMedicalActs: {},
    displayedRumIdx: {},
    healthData: {},
    predictedMedicalActs: {},
    rumPredictedLabels: {},
    pmsiCountry: {},
    rum: {},
    selection: {}
  },
  emits: ['make-selection'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props

const actFormVisible = ref(false)
const preSelectedMedicalAct = ref(null) as Ref<{ reference: string, description: string } | null>

const isReadOnly = computed(() => props.rum.id === 0)
const visibleActs = computed(() => props.codedMedicalActs.filter((act) => !act.disabled))
const visibleActRefs = computed(() => visibleActs.value.map((medicalAct) => medicalAct.associatedAct.reference))
const visiblePredictedActs = computed(() => props.predictedMedicalActs.filter((predictedAct) => !visibleActRefs.value.includes(predictedAct.medicalAct.reference)))

function promoteMedicalAct(predictedMedicalAct: PredictedMedicalActType) {
  preSelectedMedicalAct.value = {
    reference: predictedMedicalAct.medicalAct.reference,
    description: predictedMedicalAct.medicalAct.description,
  }
  actFormVisible.value = true
}
function makeSelection(selection: CodingSelection) {
  emit('make-selection', selection)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('medicalAct.title', 2).toUpperCase()), 1),
            _cache[2] || (_cache[2] = _createElementVNode("small", { class: "ml-1" }, "- Codage en cours", -1))
          ]),
          (visibleActs.value.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleActs.value, (codedMedicalAct) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: codedMedicalAct.id,
                    class: "px-0 mt-3"
                  }, [
                    _createVNode(MedicalAct, {
                      "pmsi-country": _ctx.pmsiCountry,
                      "coded-medical-act": codedMedicalAct,
                      readonly: isReadOnly.value,
                      "rum-id": _ctx.rum.id
                    }, null, 8, ["pmsi-country", "coded-medical-act", "readonly", "rum-id"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('medicalAct.codedNone')), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h1", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('medicalAct.title', 2).toUpperCase()), 1),
            _cache[3] || (_cache[3] = _createElementVNode("small", { class: "ml-1" }, "- Suggestion", -1))
          ])
        ]),
        (visiblePredictedActs.value.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visiblePredictedActs.value, (predictedMedicalAct) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: predictedMedicalAct.id,
                  class: "p-2 d-flex align-items-start"
                }, [
                  _createVNode(PredictedMedicalAct, {
                    "displayed-rum-idx": _ctx.displayedRumIdx,
                    "health-data": _ctx.healthData,
                    "predicted-medical-act": predictedMedicalAct,
                    "rum-predicted-labels": _ctx.rumPredictedLabels,
                    readonly: isReadOnly.value,
                    selection: _ctx.selection,
                    onMakeSelection: makeSelection,
                    onPromoteMedicalAct: promoteMedicalAct
                  }, null, 8, ["displayed-rum-idx", "health-data", "predicted-medical-act", "rum-predicted-labels", "readonly", "selection"])
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('medicalAct.predictedNone')), 1))
      ])
    ]),
    (!isReadOnly.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", {
            class: "row no-gutters col-5 btn btn-coding-element btn-medical-act px-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (actFormVisible.value = !actFormVisible.value))
          }, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(SancareOcticon), {
                name: "plus",
                width: 15,
                height: 15
              })
            ]),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('medicalAct.manual')), 1)
          ]),
          _createVNode(MedicalActForm, {
            "summary-unit": _ctx.rum,
            "pmsi-type": _unref(PmsiTypeEnum).MCO,
            "pmsi-country": _ctx.pmsiCountry,
            "pre-selected-medical-act": preSelectedMedicalAct.value,
            visible: actFormVisible.value,
            onCloseForm: _cache[1] || (_cache[1] = ($event: any) => (actFormVisible.value = false))
          }, null, 8, ["summary-unit", "pmsi-type", "pmsi-country", "pre-selected-medical-act", "visible"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})