import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import numeral from 'numeral'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupingMoneyGap',
  props: {
    moneyGap: { default: 0 },
    showed: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

const formattedMoneyGap = computed(() => `${numeral(props.moneyGap).format('+ 0,0').replace(/,/g, ' ')} €`)

return (_ctx: any,_cache: any) => {
  return (_ctx.moneyGap !== 0 && _ctx.showed)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.moneyGap > 0 ? 'grouping-positive-revaluation' : 'grouping-negative-revaluation')
      }, " (" + _toDisplayString(formattedMoneyGap.value) + ") ", 3))
    : _createCommentVNode("", true)
}
}

})