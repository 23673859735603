import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow } from "vue"

const _hoisted_1 = {
  name: "homepage",
  class: "container"
}
const _hoisted_2 = {
  key: 2,
  class: "alert alert-secondary"
}
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "home-list mb-2 pt-0" }

import { MedicalUnit } from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter/types'
import { Dayjs } from 'dayjs'
import _ from 'lodash'
import { computed, onMounted, Ref, ref, watch } from 'vue'

import AutomationPage from '@/automation/AutomationPage.vue'
import ChartGraph from '@/home/ChartGraph.vue'
import ChartSelector from '@/home/ChartSelector.vue'
import HomeList from '@/home/HomeList.vue'
import HomeListSelector from '@/home/HomeListSelector.vue'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { formatQuery } from '@/misc/string_utils'
import router from '@/router'
import { SavedSearchesPayload } from '@/saved-searches/types'
import StatsSettings from '@/stats/StatsSettings.vue'
import { useStore } from '@/store'
import { ModeEnum, PmsiTypeEnum, PrivacyEnum } from '@/store/modules/settings/types'

type ChartName = 'waitingStays' | 'shortStays' | 'revaluableStays' | 'automatableStays'
type ChartMode = 'global' | ModeEnum


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  props: {
    pmsiType: { default: PmsiTypeEnum.MCO }
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const chartName: Ref<ChartName> = ref('waitingStays')
const chartMode: Ref<ChartMode> = ref('global')
const chartInterval = ref(1)
const routeQuery = computed(() => store.state.route.query)
const chartUnit: Ref<string> = ref(routeQuery.value.cu ? routeQuery.value.cu : 'month')

const mode = computed(() => store.state.settings.pmsi.mode)
const upToDateModes: Ref<ModeEnum[]> = ref([])

const isAutomationEnabled = computed(() =>
  store.state.settings.sancareSettings.boolSettings.session_automation.value === true
    || store.state.settings.sancareSettings.boolSettings.non_session_automation.value === true
)

const statsInfo = computed(() => {
  let color: string
  switch (chartName.value) {
    case 'waitingStays':
      color = 'orange'
      break
    case 'shortStays':
      color = 'green'
      break
    case 'revaluableStays':
      color = 'yellow'
      break
  }
  return color
    ? {
      countValidatedStay: { label: 'Validés', color },
      countTodoStay: { label: 'A traiter', color: 'grey' },
      countTotalStay: { label: 'Total', hidden: true },
    }
    : null
})

const earlyLimit: Ref<Dayjs> = computed(() => store.state.settings.earlyLimit)
const lateLimit: Ref<Dayjs> = computed(() => store.state.settings.lateLimit)
const teamMode = computed(() => store.state.settings.currentUser.teamMode)
const selectedMedicalUnits: Ref<MedicalUnit[]> = computed(() => store.state.settings.selectedMedicalUnits)
const canManageAutomatisation = computed(() => hasPermission(RoleEnum.ROLE_AUTOMATION_MANAGER, store.state.login.userRole))
const globalStats = computed(() => store.state.home.homeStats)

const scopedSearches = computed(() => store.state.savedSearches.savedSearches.filter((s) => s.type === mode.value && s.pmsiType === props.pmsiType))
const privateSearches = computed(() => {
  const privateSearches = scopedSearches.value.filter((s) => {
    return s.user.username === store.state.login.username && s.privacy === PrivacyEnum.PRIVATE_SEARCH
  })

  return _.orderBy(privateSearches, ['countTodoStay', 'name'], ['desc', 'asc'])
})

const teamSearches = computed(() => {
  const teamSearches = []
  if (store.state.settings.currentUser.team === null || !teamMode.value) {
    return teamSearches
  }

  let modified = scopedSearches.value.filter((s) => {
    return s.privacy === PrivacyEnum.TEAM_SEARCH
      && s.validated === false
      && s.user.team.id === store.state.settings.currentUser.team.id
  })

  modified = _.orderBy(modified, ['countTodoStay', 'name'], ['desc', 'asc'])

  teamSearches.push({
    title: 'Recherches en attente de validation',
    searches: modified
  })

  let validated = scopedSearches.value.filter((s) => {
    return s.privacy === PrivacyEnum.TEAM_SEARCH
      && s.validated === true
      && s.user.team.id === store.state.settings.currentUser.team.id
  })
  validated = _.orderBy(validated, ['countTodoStay', 'name'], ['desc', 'asc'])

  teamSearches.push(
    {
      title: 'Recherches validées',
      searches: validated
    }
  )
  return teamSearches
})

const publicCategories = computed(() => {
  let categories = []
  for (const search of scopedSearches.value) {
    if (search.privacy !== PrivacyEnum.PUBLIC_SEARCH) {
      continue
    }
    const category = search.savedSearchCategory
    const listCategory = categories.find((listCategory) => listCategory.id === category.id)
    if (listCategory) {
      listCategory.searches.push(search)
      continue
    }
    categories.push({
      ...category,
      searches: [search]
    })
  }
  categories = categories.map((cat) => ({ ...cat, searches: _.orderBy(cat.searches, ['name'], ['asc']) }))
  return _.orderBy(categories, ['name'], ['asc'])
})

const sancareCategories = computed(() => {
  const sancareCategories = _.map(store.state.savedSearches.sancareSavedSearchesCategories, (cat) => {
    const catSearches = scopedSearches.value.filter((s) => {
      return s.savedSearchCategory.id === cat.id && s.privacy === PrivacyEnum.SANCARE_SEARCH
    })

    let countTodoStay = 0
    switch (mode.value) {
      case ModeEnum.NEW_CODING:
        countTodoStay = cat.countTodoNewCodingStay
        break
      // XXX: In SSR with quality stays mode, stats are not displayed either
      case ModeEnum.QUALITY_STAYS:
      case ModeEnum.OPTIMIZABLE:
        countTodoStay = cat.countTodoOptimizableStay
        break
    }

    return {
      id: cat.id,
      name: cat.name,
      countTodoStay: countTodoStay,
      searches: _.orderBy(catSearches, ['countTodoStay', 'name'], ['desc', 'asc'])
    }
  })
  return _.orderBy(
    _.filter(sancareCategories, (cat) => cat.searches && cat.searches.length),
    ['countTodoStay', 'name'],
    ['desc', 'asc']
  )
})

const finessLimitList = computed(() => {
  return _.concat(
    store.state.settings.currentUser.legalEntities || [],
    store.state.settings.currentUser.geographicEntities || []
  )
})

const refreshGlobalStats = () => {
  store.dispatch('getHomeStats', {
    earlyLimit: earlyLimit.value,
    lateLimit: lateLimit.value,
    medicalUnits: selectedMedicalUnits.value,
    pmsiType: props.pmsiType,
  })
}

const refreshChartStats = () => {
  store.dispatch('getHomeChartStats', {
    mode: chartMode.value,
    earlyLimit: earlyLimit.value,
    lateLimit: lateLimit.value,
    unit: chartUnit.value,
    medicalUnits: selectedMedicalUnits.value,
  })
}

function refreshAutomationGroups() {
  if (ModeEnum.AUTOMATION !== mode.value) {
    return
  }
  store.dispatch('getAllAutomationGroups')
  store.dispatch('getAllAutomationGroupsGhmCollisions')
}

const refreshSavedSearches = (searchIds?: number[]) => {
  if (mode.value === ModeEnum.AUTOMATION) {
    return
  }
  const payload: SavedSearchesPayload = {
    earlyLimit: earlyLimit.value,
    lateLimit: lateLimit.value,
    medicalUnits: selectedMedicalUnits.value,
    pmsiType: props.pmsiType,
    searchMode: mode.value,
  }
  if (searchIds) {
    payload.searchIds = searchIds
  }
  store.dispatch('fetchSavedSearches', payload)
}

const shouldSancareCategoriesBeFetched = computed(() =>
  (props.pmsiType === PmsiTypeEnum.MCO && [ModeEnum.OPTIMIZABLE, ModeEnum.NEW_CODING].includes(mode.value))
  || (props.pmsiType === PmsiTypeEnum.SSR && [ModeEnum.OPTIMIZABLE, ModeEnum.QUALITY_STAYS].includes(mode.value))
)
const refreshSancareSearchesCategories = (searchIds?: number[]) => {
  if (!shouldSancareCategoriesBeFetched.value) {
    return
  }
  const payload: SavedSearchesPayload = {
    earlyLimit: earlyLimit.value,
    lateLimit: lateLimit.value,
    medicalUnits: selectedMedicalUnits.value,
    pmsiType: props.pmsiType,
    searchMode: mode.value,
  }
  if (searchIds) {
    payload.searchIds = searchIds
  }
  store.dispatch('fetchSancareSavedSearchesCategories', payload)
}

const refreshSavedSearchesGroups = (searchIds?: number[]) => {
  if (ModeEnum.OPTIMIZABLE !== mode.value) {
    return
  }
  const payload: SavedSearchesPayload = {
    earlyLimit: earlyLimit.value,
    lateLimit: lateLimit.value,
    medicalUnits: selectedMedicalUnits.value,
    pmsiType: props.pmsiType,
  }
  if (searchIds) {
    payload.searchIds = searchIds
  }
  store.dispatch('fetchSavedSearchesGroups', payload)
}

const refresh = (refreshStats: boolean, searchIds?: number[]) => {
  upToDateModes.value.push(mode.value)
  if (refreshStats && props.pmsiType === PmsiTypeEnum.MCO) {
    refreshChartStats()
  }
  refreshGlobalStats()
  refreshAutomationGroups()
  refreshSavedSearches(searchIds)
  refreshSancareSearchesCategories(searchIds)
  refreshSavedSearchesGroups(searchIds)
}

const setEarlyLimit = (limit: Dayjs) => {
  store.commit('setEarlyLimit', limit)
  router.replace({
    path: store.state.route.path,
    query: {
      ...store.state.route.query,
      el: limit.format('DD-MM-YYYY'),
    }
  })
  upToDateModes.value = []
  refresh(true)
}

const setLateLimit = (limit: Dayjs) => {
  store.commit('setLateLimit', limit)
  router.replace({
    path: store.state.route.path,
    query: {
      ...store.state.route.query,
      ll: limit.format('DD-MM-YYYY'),
    }
  })
  upToDateModes.value = []
  refresh(true)
}

const setSelectedMedicalUnits = (medicalUnitList: MedicalUnit[]) => {
  store.commit('setSelectedMedicalUnits', medicalUnitList)
  router.replace({
    path: store.state.route.path,
    query: formatQuery({
      ...store.state.route.query,
      mu: medicalUnitList,
    })
  })
  upToDateModes.value = []
  refresh(true)
}

const setChartUnit = (unit: string) => {
  chartUnit.value = unit
  refreshChartStats()
  router.replace({
    path: store.state.route.path,
    query: { ...store.state.route.query, cu: unit } })
}

const setChart = (newChartName: ChartName) => {
  chartName.value = newChartName
  switch (chartName.value) {
    case 'waitingStays':
      chartMode.value = 'global'
      break
    case 'automatableStays':
      chartMode.value = ModeEnum.AUTOMATION
      break
    case 'shortStays':
      chartMode.value = ModeEnum.NEW_CODING
      break
    case 'revaluableStays':
      chartMode.value = ModeEnum.OPTIMIZABLE
      break
  }
  refreshChartStats()
}

const getFinessTooltip = (finessLimit) => {
  return `${finessLimit.longDescription && finessLimit.longDescription.length > 0
    ? finessLimit.longDescription
    : finessLimit.shortDescription} (${finessLimit.finess})`
}

async function setList(mode: ModeEnum) {
  await router.replace({
    path: store.state.route.path,
    query: { ...store.state.route.query, mode }
  })
  if (!upToDateModes.value.includes(mode)) {
    refresh(false)
  }
}

watch(teamMode, (_, PreviousTeamMode) => {
  // FIXME: little hack that prevents refresh() to be called twice on homepage initialization
  if (PreviousTeamMode !== null) {
    refresh(true)
  }
})

watch(() => props.pmsiType, () => {
  upToDateModes.value = []
  refresh(true)
})

onMounted(() => {
  refresh(true)
  store.commit('resetStayList')
})

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(StatsSettings, {
      class: _normalizeClass({ 'mb-2': _ctx.pmsiType === _unref(PmsiTypeEnum).SSR }),
      "early-limit": earlyLimit.value,
      "late-limit": lateLimit.value,
      "chart-unit": chartUnit.value,
      "are-intervals-displayed": _ctx.pmsiType === _unref(PmsiTypeEnum).MCO,
      onSetEarlyLimit: setEarlyLimit,
      onSetLateLimit: setLateLimit,
      onSetChartUnit: setChartUnit,
      onSetSelectedMedicalUnits: setSelectedMedicalUnits
    }, null, 8, ["class", "early-limit", "late-limit", "chart-unit", "are-intervals-displayed"]),
    (_ctx.pmsiType === _unref(PmsiTypeEnum).MCO)
      ? (_openBlock(), _createBlock(ChartSelector, {
          key: 0,
          "selected-chart": chartName.value,
          "chart-interval": chartInterval.value,
          stats: globalStats.value,
          class: "row col-12 my-3",
          onSetChart: setChart
        }, null, 8, ["selected-chart", "chart-interval", "stats"]))
      : _createCommentVNode("", true),
    (_ctx.pmsiType === _unref(PmsiTypeEnum).MCO)
      ? (_openBlock(), _createBlock(ChartGraph, {
          key: 1,
          "chart-name": chartName.value,
          "chart-unit": chartUnit.value,
          "chart-interval": chartInterval.value,
          "early-limit": earlyLimit.value,
          "late-limit": lateLimit.value,
          "stats-info": statsInfo.value,
          class: "row col-12 mb-2 chart-graph"
        }, null, 8, ["chart-name", "chart-unit", "chart-interval", "early-limit", "late-limit", "stats-info"]))
      : _createCommentVNode("", true),
    (finessLimitList.value && finessLimitList.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" Votre compte est restreint aux établissements suivants: ")),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(finessLimitList.value, (finessLimit) => {
              return (_openBlock(), _createElementBlock("li", {
                key: finessLimit.finess
              }, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(finessLimit.shortDescription), 1)
                ])), [
                  [_directive_tooltip, getFinessTooltip(finessLimit)]
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(HomeListSelector, {
      class: _normalizeClass({ 'mt-5': _ctx.pmsiType === _unref(PmsiTypeEnum).SSR }),
      "selected-list": mode.value,
      "is-automation-allowed": canManageAutomatisation.value && _ctx.pmsiType === _unref(PmsiTypeEnum).MCO,
      "is-automation-enabled": isAutomationEnabled.value,
      "is-new-coding-allowed": _ctx.pmsiType === _unref(PmsiTypeEnum).MCO,
      onSetList: _cache[0] || (_cache[0] = (mode) => setList(mode))
    }, null, 8, ["class", "selected-list", "is-automation-allowed", "is-automation-enabled", "is-new-coding-allowed"]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.pmsiType === _unref(PmsiTypeEnum).MCO && mode.value === _unref(ModeEnum).AUTOMATION)
        ? (_openBlock(), _createBlock(AutomationPage, {
            key: 0,
            "is-automation-allowed": canManageAutomatisation.value,
            "is-automation-enabled": isAutomationEnabled.value,
            "early-limit": earlyLimit.value,
            "late-limit": lateLimit.value
          }, null, 8, ["is-automation-allowed", "is-automation-enabled", "early-limit", "late-limit"]))
        : _createCommentVNode("", true),
      _withDirectives((_openBlock(), _createBlock(HomeList, {
        key: mode.value,
        mode: mode.value,
        "pmsi-type": _ctx.pmsiType,
        "early-limit": earlyLimit.value,
        "late-limit": lateLimit.value,
        "private-searches": privateSearches.value,
        "team-searches": teamSearches.value,
        "public-categories": publicCategories.value,
        "user-search-groups": mode.value === _unref(ModeEnum).OPTIMIZABLE
          ? _unref(store).state.savedSearches.savedSearchesGroups
          : []
        ,
        "sancare-categories": sancareCategories.value,
        onRefreshStats: _cache[1] || (_cache[1] = (searchIds) => refresh(true, searchIds))
      }, null, 8, ["mode", "pmsi-type", "early-limit", "late-limit", "private-searches", "team-searches", "public-categories", "user-search-groups", "sancare-categories"])), [
        [_vShow, mode.value !== _unref(ModeEnum).AUTOMATION]
      ])
    ])
  ]))
}
}

})