import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "settings-table" }
const _hoisted_2 = { class: "settings-table__data-row row" }
const _hoisted_3 = { class: "col-12 settings-table__data-cell align-items-start" }
const _hoisted_4 = { class: "col-12" }

import { computed, onMounted } from 'vue'

import { useStore } from '@/store'

import ConnectorForm from './ConnectorForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectorsManager',
  setup(__props) {

const store = useStore()

const connectorsConfigList = computed(() => store.state.connectorConfig.connectorsConfigList)

onMounted(() => {
  store.dispatch('connectorConfig/getConnectorsConfigList')
})

function addEmptyConnector() {
  store.commit('connectorConfig/addEmptyConnector')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"settings-table\"><div class=\"settings-table__header-row row\"><div class=\"col-6 settings-table__header-cell\"><h2>Flux entrant</h2></div></div><div class=\"settings-table__data-row row\"><div class=\"col-12 settings-table__data-cell\"><p class=\"alert alert-secondary\"> Ce flux n&#39;est pas paramétrable pour le moment, les routes sont toujours ouvertes et la version du format est indiquée dans le message. </p></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "settings-table__header-row row" }, [
        _createElementVNode("div", { class: "col-6 settings-table__header-cell" }, [
          _createElementVNode("h2", null, "Flux sortant")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connectorsConfigList.value, (connectorConfig, connectorIndex) => {
            return (_openBlock(), _createBlock(ConnectorForm, {
              key: connectorConfig.id,
              index: connectorIndex,
              connector: connectorConfig
            }, null, 8, ["index", "connector"]))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-block my-4",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (addEmptyConnector()))
            }, " Ajouter un nouveau connecteur ")
          ])
        ])
      ])
    ])
  ]))
}
}

})