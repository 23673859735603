import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-100",
  style: {"background":"#F8F9FA","padding":"15px","border-radius":"10px"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerXML',
  props: {
    data: {}
  },
  setup(__props: any) {


const props = __props

function pretify(data) {
  try {
    let formatted = ''
    let indent = ''
    const tab = '\t'
    data.split(/>\s*</).forEach(function(node) {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length) // decrease indent by one 'tab'
      }
      formatted += `${indent}<${node}>\r\n`
      if (node.match(/^<?\w[^>]*[^/]$/)) {
        indent += tab // increase indent
      }
    })

    return formatted.substring(1, formatted.length - 3)
  } catch (error) {
    return data
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("pre", null, _toDisplayString(pretify(props.data)), 1)
  ]))
}
}

})