import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeStyle as _normalizeStyle, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-100 p-5" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "d-flex flex-row flex-wrap justify-content-start align-items-baseline align-content-stretch" }
const _hoisted_6 = {
  class: "d-flex",
  style: {"width":"9vw"}
}
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  class: "d-flex flex-wrap justify-content-around",
  style: {"gap":"1vw","background":"#F7F7F7","padding":"20px 90px","border-radius":"10px 10px 0 0px","margin-bottom":"1px"}
}
const _hoisted_9 = ["for"]
const _hoisted_10 = ["onUpdate:modelValue", "name", "placeholder"]
const _hoisted_11 = {
  class: "d-flex justify-content-end",
  style: {"background":"#EEE","padding":"15px","border-radius":"0 0 10px 10px"}
}
const _hoisted_12 = {
  key: 0,
  class: "d-flex flex-wrap flex-row align-content-stretch justify-content-center w-100 mt-3"
}
const _hoisted_13 = { class: "table table-hover" }
const _hoisted_14 = ["id"]
const _hoisted_15 = ["onClick"]

import { SancarePagination } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { event, SourcesInfo } from '@/maintenance/types'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'EventObserver',
  setup(__props) {

const store = useStore()
const router = useRouter()
const sourcesInfo: SourcesInfo = {
  log_io: {
    id: { placeholder: '', input: null, type: 'integer' },
    flow: { placeholder: 'external_to_ui|ui_to_external', input: null, type: 'string' },
    trigger: { placeholder: 'dataImport|externalPush|manualValidate...', input: null, type: 'string' },
    pmsiType: { placeholder: 'mco|ssr', input: null, type: 'string' },
    response: { placeholder: 'not compliant|success|accepted', input: null, type: 'string' },
    minCreatedAt: { placeholder: '', input: null, type: 'datetime' },
    maxCreatedAt: { placeholder: '', input: (new Date()).toISOString().substring(0, 19), type: 'datetime' },
    message: { placeholder: '.*exempleregexsearch.*', input: null, type: 'string' },
  }
}
const filters = ref(_.cloneDeep(store.state.maintenance.logs.filters))
const sources: Array<string> = [
  'log_io'
]
const pageCount = computed<number>(() => store.state.maintenance.logs.pageCount)
const currentPage = computed<number>(() => store.state.maintenance.logs.currentPage)
const pageSize = ref(50)
const source = ref(store.state.maintenance.logs.currentSource)
const logEvents = computed<event[]>(() => store.state.maintenance.logs.logEvents)
const logEventsHeaders = computed<string[]>(
  () => store.state.maintenance.logs.logEvents.length > 0 ? Object.keys(store.state.maintenance.logs.logEvents.find(Boolean)) : []
)

function setPage(page, request) {
  store.commit('setLogCurrentPage', page)
  if (!(request ?? true)) {
    return
  }
  doRequest()
}

function initSource() {
  filters.value = _.clone(sourcesInfo[source.value]) ?? {}
  setPage(1, false)
}

function saveRequestData() {
  store.commit('setLogCurrentSource', source.value)
  store.commit('setLogFilters', filters.value)
}

function doRequest() {
  saveRequestData()
  const filtersData = {}
  for (const [filterName, filterInfo] of Object.entries(filters.value)) {
    let input = filterInfo.input
    switch (filterInfo.type) {
      case 'boolean':
        input = input === null ? null : Boolean(input)
        break
      case 'float':
        input = input?.length > 0 ? parseFloat(input) ?? null : null
        break
      case 'integer':
        input = input?.length > 0 ? parseInt(input) ?? null : null
        break
      case 'datetime':
      case 'string':
      default:
        input = input?.length > 0 ? input : null
        break
    }

    filtersData[filterName] = input
  }
  store.dispatch(
    'getLogEvents',
    { source: source.value, filters: filtersData, page: currentPage.value, pageSize: pageSize.value }
  )
}

function showLogEvent(LogEvent: event) {
  store.commit('setLogEventShown', LogEvent)
  router.push({ name: 'maintenance-event-visualizer' })
}

watch(source, (newValue) => {
  if (newValue === '') {
    return
  }

  initSource()
})

onMounted(() => {
  source.value = store.state.maintenance.logs.currentSource ? store.state.maintenance.logs.currentSource : sources[0] // set first source of the list by default
  nextTick(() => {
    const hash = router.currentRoute.value.hash
    if (!router.currentRoute.value.hash) {
      return
    }
    const anchor = hash.replace('#', '')
    const elem = document.getElementById(anchor)
    if (!elem) {
      return
    }
    elem.scrollIntoView()
  })
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('maintenance.event-observer.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"width":"4vw"} }, [
            _createElementVNode("label", {
              class: "font-weight-bold mr-2",
              for: "eventsSource"
            }, " Source : ")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((source).value = $event)),
              name: "eventsSource",
              class: "custom-select"
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sources, (sourceOpt) => {
                return _createElementVNode("option", {
                  key: sourceOpt,
                  value: sourceOpt
                }, _toDisplayString(sourceOpt), 9, _hoisted_7)
              }), 64))
            ], 512), [
              [_vModelSelect, source.value]
            ])
          ]),
          _createElementVNode("div", {
            class: "mt-3",
            style: _normalizeStyle([{"margin":"0 60px"}, { display: Object.values(filters.value).length ? 'block' : 'none' }])
          }, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filters.value, (filter, filterName) => {
                return (_openBlock(), _createElementBlock("div", { key: filterName }, [
                  _createElementVNode("label", {
                    class: "font-weight-bold mr-2",
                    for: filterName
                  }, _toDisplayString(filterName), 9, _hoisted_9),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": ($event: any) => ((filter.input) = $event),
                    name: filterName,
                    placeholder: filter.placeholder
                  }, null, 8, _hoisted_10), [
                    [_vModelText, filter.input]
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: doRequest
              }, _toDisplayString(_ctx.$t('maintenance.event-observer.filter-button')), 1)
            ])
          ], 4),
          (logEvents.value.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (pageCount.value > 1)
                  ? (_openBlock(), _createBlock(_unref(SancarePagination), {
                      key: 0,
                      "page-count": pageCount.value,
                      "current-page": currentPage.value,
                      onSetPage: setPage
                    }, null, 8, ["page-count", "current-page"]))
                  : _createCommentVNode("", true),
                _createElementVNode("table", _hoisted_13, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logEventsHeaders.value, (logEventsHeader) => {
                        return (_openBlock(), _createElementBlock("th", { key: logEventsHeader }, _toDisplayString(logEventsHeader), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logEvents.value, (logEvent) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        id: 'event-'+logEvent.id,
                        key: logEvent.id
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(logEvent), (value, index) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: value.toString().substring(0, 15)
                          }, [
                            _createElementVNode("div", {
                              style: _normalizeStyle((index + 1) === Object.values(logEvent).length ? { wordBreak:'break-all', maxHeight:'100px', overflow:'scroll' } : {})
                            }, _toDisplayString(value), 5)
                          ]))
                        }), 128)),
                        _createElementVNode("td", null, [
                          _createElementVNode("button", {
                            class: "btn btn-link",
                            onClick: ($event: any) => (showLogEvent(logEvent))
                          }, " Voir → ", 8, _hoisted_15)
                        ])
                      ], 8, _hoisted_14))
                    }), 128))
                  ]),
                  _createElementVNode("tfoot", null, [
                    _createElementVNode("tr", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logEventsHeaders.value, (logEventsHeader) => {
                        return (_openBlock(), _createElementBlock("th", { key: logEventsHeader }, _toDisplayString(logEventsHeader), 1))
                      }), 128))
                    ])
                  ])
                ]),
                (pageCount.value > 1)
                  ? (_openBlock(), _createBlock(_unref(SancarePagination), {
                      key: 1,
                      "page-count": pageCount.value,
                      "current-page": currentPage.value,
                      onSetPage: setPage
                    }, null, 8, ["page-count", "current-page"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})