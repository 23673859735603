import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { computed } from 'vue'

import { Rhs } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'WeekDaysPresence',
  props: {
    rhs: {}
  },
  setup(__props: any) {

const props = __props

const presenceDays = computed(() => [...props.rhs.weekDays.split(''), ...props.rhs.weekEndDays.split('')].map((day) => Boolean(Number(day))))
const weekDayLabels = dayjs.weekdays(true)


return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(presenceDays.value, (day, idx) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      key: idx,
      class: _normalizeClass(["week-day", { presence: day }])
    }, null, 2)), [
      [_directive_tooltip, _unref(weekDayLabels)[idx]]
    ])
  }), 128))
}
}

})