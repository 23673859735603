import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "row align-items-center mt-5"
}
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = { class: "row mb-5" }
const _hoisted_5 = { class: "col-lg-7 col-md-12" }
const _hoisted_6 = { class: "col-lg-5 col-md-12" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col p-0" }

import { computed, onMounted, Ref, ref } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

import AsideStayGme from '@/stay-displayer/grouping/ssr/AsideStayGme.vue'
import { refreshStay } from '@/stay-displayer/ssr/dataRefreshers'
import GeneralStayData from '@/stay-displayer/ssr/GeneralStayData.vue'
import RhsSummaries from '@/stay-displayer/ssr/rhs/RhsSummaries.vue'
import RhsTable from '@/stay-displayer/ssr/RhsTable.vue'
import SsrLoadingError from '@/stay-displayer/ssr/SsrLoadingError.vue'
import { useStore } from '@/store'
import { SsrLoadingErrorReason } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SsrPage',
  setup(__props) {

const route = useRoute()

const store = useStore()

const refreshErrorReasons = { ...SsrLoadingErrorReason }
const refreshErrorReason: Ref<SsrLoadingErrorReason> = ref(null)
const isLoading: Ref<boolean> = ref(false)
const stayListPage: Ref<RouteLocationRaw> = ref(null)

const ssrId = computed(() => Number(route.params.ssrId))
const currentStay = computed(() => store.state.ssrStay.currentStay)
const isRefreshed = computed(() => store.state.ssrStay.requests.fetchStay.ok)
const isStandardGmeSet = computed(() => currentStay.value.standardModeGroupingResult.gme !== null)
// TODO: To be updated when modes are implemented
const displayedGroupingResult = computed(() => isStandardGmeSet.value
  ? currentStay.value.standardModeGroupingResult
  : currentStay.value.firstStandardModeGroupingResult)
const stayMoneyGap = computed(() => isStandardGmeSet.value
  ? currentStay.value.standardModeGroupingResult.value - currentStay.value.firstStandardModeGroupingResult.value
  : 0)

async function refresh() {
  await refreshStay(ssrId.value, store)

  if (window.sessionStorage['stayListRoute']) {
    stayListPage.value = {
      path: window.sessionStorage.getItem('stayListRoute'),
      query: {
        ...store.getters.settingsUrlQuery,
        ...store.getters.shortHandStayListParams,
      },
    }
  }
}

onMounted(async () => {
  isLoading.value = true
  try {
    await refresh()
  } catch (e) {
    refreshErrorReason.value = Object.values(refreshErrorReasons).includes(e.message) ? e.message : null
  }
  isLoading.value = false
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "col-sm-12" }, [
            _createElementVNode("div", { class: "loader loader-md loader-center mb-2" }),
            _createElementVNode("div", { class: "text-center font-italic" }, " Chargement des informations du SMR... ")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (isRefreshed.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (stayListPage.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_router_link, { to: stayListPage.value }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("span", { class: "back-to-ssr-staylist" }, " ←  Retour à la liste des séjours ", -1)
                  ])),
                  _: 1
                }, 8, ["to"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(GeneralStayData, { stay: currentStay.value }, null, 8, ["stay"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(AsideStayGme, {
                    "grouping-result": displayedGroupingResult.value,
                    "money-gap": stayMoneyGap.value,
                    "hospitalization-type": currentStay.value.hospitalizationType
                  }, null, 8, ["grouping-result", "money-gap", "hospitalization-type"])
                ])
              ])
            ])
          ]),
          _createVNode(RhsTable, {
            "rhs-list": currentStay.value.rhsList
          }, null, 8, ["rhs-list"]),
          _createVNode(RhsSummaries, {
            "current-stay-id": currentStay.value.id,
            "hospitalization-type": currentStay.value.hospitalizationType,
            "rhs-list": currentStay.value.rhsList
          }, null, 8, ["current-stay-id", "hospitalization-type", "rhs-list"])
        ], 64))
      : (_openBlock(), _createBlock(SsrLoadingError, {
          key: 2,
          reason: refreshErrorReason.value,
          "ssr-id": ssrId.value
        }, null, 8, ["reason", "ssr-id"]))
  ]))
}
}

})