import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "general-stay-data card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "data-table" }
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "row"
}
const _hoisted_8 = { class: "col-7" }
const _hoisted_9 = {
  key: 2,
  class: "row"
}
const _hoisted_10 = { class: "col-7" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-7" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-7" }
const _hoisted_15 = {
  key: 3,
  class: "row"
}
const _hoisted_16 = { class: "col-7" }
const _hoisted_17 = {
  key: 4,
  class: "row"
}
const _hoisted_18 = { class: "col-7" }
const _hoisted_19 = {
  key: 5,
  class: "row"
}
const _hoisted_20 = { class: "col-7" }
const _hoisted_21 = {
  key: 6,
  class: "row"
}
const _hoisted_22 = { class: "col-7" }

import { SancareSticky } from '@sancare/ui-frontend-commons'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { Dayjs } from 'dayjs'
import { computed, ComputedRef } from 'vue'

import PatientData from '@/stay-displayer/PatientData.vue'
import WeekDaysPresence from '@/stay-displayer/ssr/rhs/WeekDaysPresence.vue'
import { useStore } from '@/store'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { Rhs, SsrStay } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralStayData',
  props: {
    stay: {},
    displayedRhs: {}
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const pseudonymizationEnabled = computed(() => store.getters.flatSancareSettings['enable_pseudonymization'])
const fullName = computed(() => store.getters['ssrStay/fullName'])
const lastSurgeryDate: ComputedRef<Dayjs | null> = computed(() => props.stay.rhsList.at(-1)?.surgeryDate ?? null)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SancareSticky), { class: "patient-data-container" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!pseudonymizationEnabled.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "col-5" }, " PATIENT ", -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(fullName.value), 1),
                    (_ctx.stay.patient.ipp)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (" + _toDisplayString(_ctx.stay.patient.ipp) + ")", 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row mb-3" }, null, -1)),
            (!_ctx.displayedRhs)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "col-5" }, " DURÉE DU SÉJOUR ", -1)),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.stay.stayDuration) + "j ", 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "col-5" }, " JOURS DE PRESENCE ", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(WeekDaysPresence, { rhs: _ctx.displayedRhs }, null, 8, ["rhs"])
                  ])
                ])),
            _createElementVNode("div", _hoisted_11, [
              _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "col-5" }, " DATE D'ENTRÉE ", -1)),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.displayedRhs ? _unref(dayjs)(_ctx.displayedRhs.startDate).format('L') : _unref(dayjs)(_ctx.stay.stayStart).format('L')), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "col-5" }, " DATE DE SORTIE ", -1)),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.displayedRhs ? _unref(dayjs)(_ctx.displayedRhs.endDate).format('L') : _unref(dayjs)(_ctx.stay.stayEnd).format('L')), 1)
            ]),
            (!pseudonymizationEnabled.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "col-5" }, " N° ADMINISTRATIF ", -1)),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.stay.administrativeLocalStayId || '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.displayedRhs)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "col-5" }, " DERNIÈRE INTER. CHIR. ", -1)),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(lastSurgeryDate.value ? _unref(dayjs)(lastSurgeryDate.value).format('L') : '-'), 1)
                ]))
              : _createCommentVNode("", true),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row mb-3" }, null, -1)),
            _createVNode(PatientData, {
              stay: _ctx.stay,
              "pseudonymization-enabled": pseudonymizationEnabled.value,
              "pmsi-type": _unref(PmsiTypeEnum).SSR
            }, null, 8, ["stay", "pseudonymization-enabled", "pmsi-type"]),
            (_ctx.displayedRhs)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "col-5" }, " N° DE SEMAINE ", -1)),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.displayedRhs.week.nb), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.displayedRhs)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "col-5" }, " UNITÉ MÉDICALE ", -1)),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.displayedRhs.medicalUnit ? _ctx.displayedRhs.medicalUnit.reference : '-'), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})