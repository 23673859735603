import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card aside-gme" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "row align-items-end" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row align-items-end" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6 grouping-actions-block d-flex flex-column justify-content-center" }

import { computed } from 'vue'

import GroupingMoneyGap from '@/stay-displayer/grouping/GroupingMoneyGap.vue'
import GmeDisplayer from '@/stay-displayer/grouping/ssr/GmeDisplayer.vue'
import { hasCriticalError } from '@/stay-displayer/grouping/ssr/grouping-validators'
import GroupingCost from '@/stay-displayer/grouping/ssr/GroupingCost.vue'
import GroupingErrorList from '@/stay-displayer/grouping/ssr/GroupingErrorList.vue'
import ValidationButton from '@/stay-displayer/grouping/ssr/ValidationButton.vue'
import HospitalizationTypeBadge from '@/stay-displayer/ssr/HospitalizationTypeBadge.vue'
import { AsideStayGmeProps } from '@/store/modules/stay/ssr/grouping/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AsideStayGme',
  props: {
    hospitalizationType: {},
    groupingResult: {},
    moneyGap: { default: 0 }
  },
  setup(__props: any) {

const props = __props

const gme = computed(() => props.groupingResult.gme)
const gmt = computed(() => props.groupingResult.gmt)
const cost = computed(() => props.groupingResult.value)
const groupingErrors = computed(() => props.groupingResult.groupingErrors)
const isGroupingValid = computed(() => !hasCriticalError(groupingErrors.value))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h1", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "card-title__label" }, [
                _createElementVNode("span", { class: "font-weight-normal" }, "Groupage"),
                _createTextVNode(" Séjour ")
              ], -1)),
              _createVNode(HospitalizationTypeBadge, { type: _ctx.hospitalizationType }, null, 8, ["type"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-label" }, " GME ", -1)),
                _createVNode(GmeDisplayer, {
                  class: "mb-2",
                  gme: gme.value
                }, null, 8, ["gme"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col" }, null, -1)),
              _createElementVNode("div", _hoisted_10, [
                (isGroupingValid.value)
                  ? (_openBlock(), _createBlock(GroupingCost, {
                      key: 0,
                      cost: cost.value,
                      gmt: gmt.value
                    }, null, 8, ["cost", "gmt"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col" }, null, -1)),
              (isGroupingValid.value)
                ? (_openBlock(), _createBlock(GroupingMoneyGap, {
                    key: 0,
                    "money-gap": _ctx.moneyGap,
                    class: "col-auto"
                  }, null, 8, ["money-gap"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(ValidationButton, {
              disabled: !isGroupingValid.value
            }, null, 8, ["disabled"]),
            _createVNode(GroupingErrorList, {
              errors: groupingErrors.value,
              class: "mt-2 flex-grow-1"
            }, null, 8, ["errors"])
          ])
        ])
      ])
    ])
  ]))
}
}

})