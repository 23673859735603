import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader loader-center"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "badge badge-primary" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "badge badge-primary" }
const _hoisted_6 = { class: "badge badge-primary" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = {
  key: 3,
  class: "text-center"
}
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { class: "badge badge-primary" }
const _hoisted_13 = { key: 5 }
const _hoisted_14 = { class: "badge badge-primary" }
const _hoisted_15 = { key: 6 }
const _hoisted_16 = { class: "badge badge-primary" }
const _hoisted_17 = { key: 7 }
const _hoisted_18 = { class: "badge badge-primary" }
const _hoisted_19 = { class: "badge badge-primary" }

import { computed } from 'vue'

import { useStore } from '@/store'

import CriteriaDisplayer from '../filters/CriteriaDisplayer.vue'
import { actionLabels, AutomationGroup, AutomationTypeEnum, ConditionOperatorEnum, GroupSearchOpEnum } from '../store/modules/automation/types'
import { ModeEnum } from '../store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AutomationGroupDetails',
  props: {
    fullAutomationGroup: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const enableWebpimsAccess = computed(() => store.getters.enableWebpimsAccess)
const internalOperator = computed(() => props.fullAutomationGroup.searchOperators === GroupSearchOpEnum.ORAND ? ConditionOperatorEnum.AND : ConditionOperatorEnum.OR)
const externalOperator = computed(() => props.fullAutomationGroup.searchOperators === GroupSearchOpEnum.ANDOR ? ConditionOperatorEnum.AND : ConditionOperatorEnum.OR)
const getAutomationGroupRequest = computed(() => store.state.automation.getAutomationGroupRequest)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (getAutomationGroupRequest.value.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.fullAutomationGroup.presentGhmList.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[0] || (_cache[0] = _createTextVNode(" GHM détecté(s) : ")),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fullAutomationGroup.presentGhmList.join(', ')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.fullAutomationGroup.absentGhmList.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createTextVNode(" GHM exclu(s) : ")),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.fullAutomationGroup.absentGhmList.join(', ')), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullAutomationGroup.actions, (action) => {
            return (_openBlock(), _createElementBlock("div", {
              key: action.id
            }, [
              _createTextVNode(_toDisplayString(_unref(actionLabels)[action.action]) + " : ", 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(action.value), 1)
            ]))
          }), 128)),
          _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
          (_ctx.fullAutomationGroup.globalCriteriaGroup !== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(CriteriaDisplayer, {
                  class: "card",
                  "can-remove-criteria": false,
                  "display-grouped-criteria": true,
                  "internal-operator": internalOperator.value,
                  "criteria-group": _ctx.fullAutomationGroup.globalCriteriaGroup,
                  mode: _unref(ModeEnum).AUTOMATION
                }, null, 8, ["internal-operator", "criteria-group", "mode"])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullAutomationGroup.criteriaGroups, (criteriaGroup, idx) => {
            return (_openBlock(), _createElementBlock("div", { key: idx }, [
              (idx > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(externalOperator.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(CriteriaDisplayer, {
                class: "card",
                "can-remove-criteria": false,
                "internal-operator": internalOperator.value,
                "criteria-group": criteriaGroup,
                mode: _unref(ModeEnum).AUTOMATION
              }, null, 8, ["internal-operator", "criteria-group", "mode"])
            ]))
          }), 128)),
          (_ctx.fullAutomationGroup.criteriaGroups.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[2] || (_cache[2] = [
                _createElementVNode("small", null, "Ce groupe ne contient aucun critère supplémentaire.", -1)
              ])))
            : _createCommentVNode("", true),
          _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
          (_ctx.fullAutomationGroup.type === _unref(AutomationTypeEnum).AI)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createTextVNode(" Quand une biopsie est demandée, attendre l'acte ou le compte-rendu correspondant : ")),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.fullAutomationGroup.fulfillAnapathRequests === true ? 'OUI' : 'NON'), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.fullAutomationGroup.type === _unref(AutomationTypeEnum).AI)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _cache[4] || (_cache[4] = _createTextVNode(" Attendre un acte d'anesthésie pour les GHM de type K : ")),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.fullAutomationGroup.waitForAnesthesia === true ? 'OUI' : 'NON'), 1)
              ]))
            : _createCommentVNode("", true),
          (enableWebpimsAccess.value && _ctx.fullAutomationGroup.type === _unref(AutomationTypeEnum).AI)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _cache[5] || (_cache[5] = _createTextVNode(" Alerter si pas de CRO (GHM type C et K) : ")),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.fullAutomationGroup.alertNoCro === true ? 'OUI' : 'NON'), 1)
              ]))
            : _createCommentVNode("", true),
          (enableWebpimsAccess.value && _ctx.fullAutomationGroup.type === _unref(AutomationTypeEnum).AI)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[6] || (_cache[6] = _createTextVNode(" Alerter si pas de CRH : ")),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.fullAutomationGroup.alertNoCrh === true ? 'OUI' : 'NON'), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createTextVNode(" Délai d'attente : ")),
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.fullAutomationGroup.waitDuration) + " j ", 1)
          ])
        ], 64))
  ]))
}
}

})