import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "btn sancare-btn btn-fixed-width" }
const _hoisted_2 = { key: 1 }

import { ExportButton } from '@sancare/ui-frontend-commons'
import { computed, ref } from 'vue'

import LoadingMark from '@/misc/LoadingMark.vue'
import { filterAvailableColumns, filterDisplayedColumns } from '@/stay-list/stay-list-table/helpers/columnFilters'
import availableColumns from '@/stay-list/stay-list-table/mco/StayListColumns'
import { useStore } from '@/store'
import { ModeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportStayListButton',
  props: {
    request: {},
    mode: {}
  },
  emits: ["error"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const store = useStore()

const isExporting = ref(false)

const userColumns = computed(() => store.state.settings.userSettings.stayListColumns)
const pmsiCountry = computed(() => store.state.settings.pmsi.country)

async function exportStays() {
  isExporting.value = true
  emit('error', null)

  const exportUrl = new URL('/api/export/search-csv', window.location.href)
  const modeColumns = filterAvailableColumns(
    availableColumns,
    {
      pmsiCountry: pmsiCountry.value,
      mode: props.mode
    }
  )
  const displayedColumns = filterDisplayedColumns(modeColumns, userColumns.value)

  const requestData = {
    mode: props.mode,
    request: props.request,
    columnList: Object.keys(displayedColumns),
  }

  try {
    /* global RequestInit */
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0',
        expires: '0',
        pragma: 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
      }),
      body: JSON.stringify(requestData),
    }

    const response = await fetch(exportUrl, requestOptions)
    const fileName = getFileNameFromResponse(response)
    const downloadUrl = window.URL.createObjectURL(await response.blob())
    downloadFile(downloadUrl, fileName)
  } catch {
    emit('error', 'Une erreur est survenue lors de l\'export.')
  } finally {
    isExporting.value = false
  }
}

function getFileNameFromResponse(response: Response) {
  const contentDispositionHeader = response.headers.get('Content-Disposition')
  const matchFileName = contentDispositionHeader.match(/filename="?([^"]+)"?/)
  return matchFileName && matchFileName[1] ? matchFileName[1] : 'stay_list_export.xlsx'
}

function downloadFile(downloadUrl: string, fileName: string) {
  const link = document.createElement('a')
  link.href = downloadUrl
  link.download = fileName
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(downloadUrl)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ExportButton), {
      onConfirm: _cache[0] || (_cache[0] = ($event: any) => (exportStays()))
    }, {
      button: _withCtx(() => [
        _createElementVNode("div", null, [
          (isExporting.value)
            ? (_openBlock(), _createBlock(LoadingMark, {
                key: 0,
                finished: !isExporting.value,
                class: "spinner",
                color: "blue"
              }, null, 8, ["finished"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, "Exporter"))
        ])
      ]),
      _: 1
    })
  ]))
}
}

})