import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from 'vue'

import AutomationGroupStayListValidationButton from '@/automation/stay-list/AutomationGroupStayListValidationButton.vue'
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import ExportStayListButton from '@/stay-list/stay-list-table/actions/ExportStayListButton.vue'
import ReadingListButton from '@/stay-list/stay-list-table/actions/ReadingListButton.vue'
import StayListActions from '@/stay-list/stay-list-table/actions/StayListActions.vue'
import { ListMcoStay } from '@/stay-list/stay-list-table/mco/types'
import { useStore } from '@/store'
import { ModeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AutomationGroupStayListActions',
  emits: ["refresh-staylist", "set-stay-list-parameter"],
  setup(__props, { emit: __emit }) {

const store = useStore()

const userRole = store.state.login.userRole

const stayList = computed(() => store.state.automation.automationStayList.items.map((stay: ListMcoStay) => stay.id))
const emit = __emit

const exportRequest = computed(() => store.getters.getAutomationGroupStayListExportRequest)

const params = computed(() => ({
  showSeen: store.state.automation.automationStayListParams.showSeen,
  pageSize: store.state.automation.automationStayListParams.pageSize
}))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(StayListActions, {
    params: params.value,
    onSetStayListParameter: _cache[1] || (_cache[1] = (type, value) => emit('set-stay-list-parameter', type, value))
  }, {
    default: _withCtx(() => [
      (_unref(hasPermission)(_unref(RoleEnum).ROLE_STAY_BULK_VALIDATOR, _unref(userRole)))
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(AutomationGroupStayListValidationButton, {
              onRefreshStaylist: _cache[0] || (_cache[0] = ($event: any) => (emit('refresh-staylist')))
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(ExportStayListButton, {
        mode: _unref(ModeEnum).AUTOMATION,
        request: exportRequest.value
      }, null, 8, ["mode", "request"]),
      _createVNode(ReadingListButton, {
        mode: _unref(ModeEnum).AUTOMATION,
        "stay-list": stayList.value,
        "base-stay-displayer-path": "/id"
      }, null, 8, ["mode", "stay-list"])
    ]),
    _: 1
  }, 8, ["params"]))
}
}

})