import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-align" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, ref } from 'vue'

import LoadingMark from '@/misc/LoadingMark.vue'
import { useStore } from '@/store'
import { Stay } from '@/store/modules/stay/types'

import StayActions from './StayActions.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StaySynchronizator',
  props: {
    stay: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const actionsLoadedOnce = ref(false)

const syncStayRequest = computed(() => store.state.stayDisplayer.syncStayToExternalConnectorRequest)
const fetchStayActionsRequest = computed(() => store.state.mcoStay.requests.fetchStayActions)
const currentActions = computed(() => store.state.mcoStay.currentActions)

const buttonLabel = computed(() => {
  if (syncStayRequest.value.fetching) {
    return 'En cours de synchronisation'
  } else if (syncStayRequest.value.ok) {
    return 'Séjour synchronisé'
  } else if (syncStayRequest.value.error) {
    return 'La synchronisation a échoué'
  } else if (store.getters.enableWebpimsAccess) {
    return 'Synchroniser le séjour vers Webpims'
  } else if (store.getters.enableCoraAccess) {
    return 'Synchroniser le séjour vers Cora'
  } else if (store.getters.enableMedstatAccess) {
    return 'Synchroniser le séjour vers Medstat'
  } else {
    return ''
  }
})

const isSyncDisabled = computed(() => syncStayRequest.value.ok || syncStayRequest.value.error)
function syncStay() {
  if (syncStayRequest.value.fetching || isSyncDisabled.value) {
    return
  }
  store.dispatch('syncStayToExternalConnector', { stayId: props.stay.id })
  actionsLoadedOnce.value = false
}

function showStayActions() {
  if (actionsLoadedOnce.value) {
    return
  }
  store.dispatch('mcoStay/fetchStayActions', props.stay.id)
  actionsLoadedOnce.value = true
}

return (_ctx: any,_cache: any) => {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (buttonLabel.value !== '')
    ? (_openBlock(), _createBlock(_component_v_tooltip, {
        key: 0,
        placement: "bottom",
        onShow: showStayActions
      }, {
        popper: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(buttonLabel.value) + " ", 1),
            _createVNode(StayActions, {
              "is-loading": fetchStayActionsRequest.value.fetching,
              "stay-actions": currentActions.value
            }, null, 8, ["is-loading", "stay-actions"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["btn btn-icon__action", {
        'loading': syncStayRequest.value.fetching,
        'active': syncStayRequest.value.ok,
        'error': syncStayRequest.value.error,
        'sync-disabled': isSyncDisabled.value
      }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (syncStay()))
          }, [
            (syncStayRequest.value.ok)
              ? (_openBlock(), _createBlock(_unref(SancareOcticon), {
                  key: 0,
                  name: "check"
                }))
              : (syncStayRequest.value.error)
                ? (_openBlock(), _createBlock(_unref(SancareOcticon), {
                    key: 1,
                    name: "alert"
                  }))
                : (syncStayRequest.value.fetching)
                  ? (_openBlock(), _createBlock(LoadingMark, {
                      key: 2,
                      finished: !syncStayRequest.value.fetching,
                      color: "blue",
                      style: {"width":"16px","height":"16px"}
                    }, null, 8, ["finished"]))
                  : (_openBlock(), _createBlock(_unref(SancareOcticon), {
                      key: 3,
                      name: "sync"
                    }))
          ], 2)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})