import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card aside-ghm" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col-auto text-right" }

import GroupingField from '@/stay-displayer/grouping/GroupingField.vue'
import GroupingErrorMessage from '@/stay-displayer/grouping/mco/GroupingErrorMessage.vue'
import { Drg } from '@/store/modules/stay/mco/types'
import { GroupingError } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AsideDrg',
  props: {
    cflag: {},
    drg: { default: null },
    ecw: {},
    groupingError: { default: null },
    pccl: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", {
            class: _normalizeClass([{'ghm-error': _ctx.groupingError}, "col-auto card-title"])
          }, _toDisplayString(_ctx.groupingError ? 'Erreur' : 'Groupage'), 3)
        ]),
        (_ctx.groupingError)
          ? (_openBlock(), _createBlock(GroupingErrorMessage, {
              key: 0,
              error: _ctx.groupingError,
              "is-critical": ""
            }, null, 8, ["error"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(GroupingField, {
                    label: "DRG",
                    value: _ctx.drg?.reference,
                    description: _ctx.drg?.description
                  }, null, 8, ["value", "description"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(GroupingField, {
                    label: "ECW",
                    value: _ctx.ecw
                  }, null, 8, ["value"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(GroupingField, {
                    label: "PCCL",
                    value: _ctx.pccl
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(GroupingField, {
                    label: "CFLAG",
                    value: _ctx.cflag
                  }, null, 8, ["value"])
                ])
              ])
            ]))
      ])
    ])
  ]))
}
}

})