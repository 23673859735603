import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "maintenance-home container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  class: "d-flex flex-row flex-wrap align-items-center align-content-center",
  style: {"justify-content":"space-evenly","gap":"3vw"}
}

import MaintenanceThumbnail from '@/maintenance/MaintenanceThumbnail.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceHome',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('maintenance.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(MaintenanceThumbnail, {
            title: _ctx.$t('maintenance.event-observer.title'),
            to: "maintenance-event-observer"
          }, null, 8, ["title"]),
          _createVNode(MaintenanceThumbnail, {
            title: _ctx.$t('maintenance.stay-history.title'),
            to: "maintenance-stay-history-search"
          }, null, 8, ["title"])
        ])
      ])
    ])
  ]))
}
}

})