import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pl-1 pr-3" }
const _hoisted_2 = { class: "row justify-content-between" }
const _hoisted_3 = { class: "d-flex justify-content-start align-items-md-center pl-2" }
const _hoisted_4 = { class: "btn-group pl-1" }
const _hoisted_5 = { class: "d-flex justify-content-end align-items-center border-info" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "row error-block mt-2 pl-3"
}

import { SancareToggle } from '@sancare/ui-frontend-commons'
import { onMounted } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayListActions',
  props: {
    params: {},
    errorMessage: { default: null }
  },
  emits: ["set-stay-list-parameter"],
  setup(__props: any, { emit: __emit }) {



const store = useStore()

const STAYS_PER_PAGE_OPTIONS = [10, 20, 30, 50, 80, 100, 150]

const emit = __emit

onMounted(() => {
  store.commit('resetStaySelection')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(SancareToggle), {
            class: "mr-3",
            label: "Afficher les séjours vus",
            value: _ctx.params.showSeen,
            onToggleChange: _cache[0] || (_cache[0] = ($event: any) => (emit('set-stay-list-parameter', 'showSeen', !_ctx.params.showSeen)))
          }, null, 8, ["value"]),
          _createElementVNode("div", _hoisted_6, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-shrink-0 mr-2" }, " Résultats par page ", -1)),
            _createElementVNode("select", {
              value: _ctx.params.pageSize,
              class: "form-control sancare-select",
              onChange: _cache[1] || (_cache[1] = e => emit('set-stay-list-parameter', 'pageSize', Number(e.target.value)))
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(STAYS_PER_PAGE_OPTIONS, (option) => {
                return _createElementVNode("option", {
                  key: option,
                  value: option
                }, _toDisplayString(option), 9, _hoisted_8)
              }), 64))
            ], 40, _hoisted_7)
          ])
        ])
      ])
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})