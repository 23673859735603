import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loader loader-xs",
  style: {"width":"12px","height":"12px","margin":"3px"}
}
const _hoisted_2 = { class: "px-2 flex-grow-1" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "ml-auto" }
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = { class: "col act-data px-0" }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { ref } from 'vue'

import PredictionIcon from '@/common/PredictionIcon.vue'
import JustificationSummary from '@/stay-displayer/diagnosis-lists/JustificationSummary.vue'
import { CodingSelection } from '@/stay-displayer/types'
import { useStore } from '@/store'
import { PredictedLabel, PredictedLabelType, PredictedMedicalAct } from '@/store/modules/diagnosis/types'
import { StayHealthData } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'PredictedMedicalAct',
  props: {
    displayedRumIdx: {},
    healthData: {},
    predictedMedicalAct: {},
    readonly: { type: Boolean },
    rumPredictedLabels: {},
    selection: {}
  },
  emits: ['make-selection', 'promote-medical-act'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const emit = __emit
const props = __props

const isLoading = ref(false)
const showJustificationSummary = ref(false)

function promoteMedicalAct() {
  emit('promote-medical-act', props.predictedMedicalAct)
}
function handleHealthDataSelection(mainContent: { type: string, targetId: number }) {
  store.commit('shouldScroll', true)
  store.commit('setMainContent', mainContent)
  emit('make-selection', {
    labelType: PredictedLabelType.MEDICAL_ACTS,
    labelReference: props.predictedMedicalAct.medicalAct.reference,
    savedSearchIds: [],
  })
  showJustificationSummary.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.readonly && !isLoading.value)
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "cp d-flex",
          onClick: promoteMedicalAct
        }, [
          _createVNode(_unref(SancareOcticon), {
            name: "arrow-left",
            width: 18,
            height: 18
          })
        ])), [
          [_directive_tooltip, _ctx.$t('medicalAct.promote')]
        ])
      : (isLoading.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_dropdown, {
        shown: showJustificationSummary.value,
        placement: "left",
        class: "popover-container",
        "popover-class": "justification-popover",
        onShow: _cache[0] || (_cache[0] = ($event: any) => (showJustificationSummary.value = true))
      }, {
        popper: _withCtx(() => [
          _createVNode(JustificationSummary, {
            "is-loading": false,
            chunks: _ctx.predictedMedicalAct.chunks,
            "health-data": _ctx.healthData,
            "no-value-label": 'Pas de justification',
            "displayed-rum-idx": _ctx.displayedRumIdx,
            "rum-predicted-labels": _ctx.rumPredictedLabels,
            selection: _ctx.selection,
            "saved-searches": [],
            readonly: _ctx.readonly,
            onSelectHealthData: handleHealthDataSelection
          }, null, 8, ["chunks", "health-data", "displayed-rum-idx", "rum-predicted-labels", "selection", "readonly"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_ctx.predictedMedicalAct.medicalAct.reference), 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(PredictionIcon, { size: 16 }),
                _createElementVNode("small", _hoisted_5, _toDisplayString(Math.round(_ctx.predictedMedicalAct.confidence*100)) + "%", 1)
              ])
            ]),
            _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.predictedMedicalAct.medicalAct.description), 1)
          ])
        ]),
        _: 1
      }, 8, ["shown"])
    ])
  ], 64))
}
}

})