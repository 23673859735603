import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-items-center mt-4 pt-3"
}
const _hoisted_5 = { class: "col" }

import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import { computed, ComputedRef, onMounted, Ref, ref, watch } from 'vue'
import VueSlider from 'vue-slider-component'

import { useFilterOptions } from '@/filters/composables/useFilterOptions'
import { FilterData, FilterEvent, isGroupOption } from '@/store/modules/filters/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RangeFilter',
  props: {
    filterData: {},
    pmsiType: {}
  },
  emits: ["add-filter"],
  setup(__props: any, { emit: __emit }) {

const { getOptionValue, displayedOption } = useFilterOptions()

const props = __props

const emit = __emit

const selectedSubFilterValue: Ref<string> = ref('')
const value: Ref<(string | number)[]> = ref([])
const subFiltersValues: Ref<Record<string, string>> = ref({})

const visibleSubFilters: ComputedRef<FilterData[]> = computed(() => {
  if (!props.filterData.subFilters) return []

  return props.filterData.subFilters?.filter(
    (filter) => !filter.pmsiTypes || filter.pmsiTypes.includes(props.pmsiType)
  )
})

visibleSubFilters.value.forEach((filter) => subFiltersValues.value[filter.name] = filter.default ?? '')

const areAllSubFiltersSet: ComputedRef<boolean> = computed(() =>
  Object.values(subFiltersValues.value).every((value: string) => value.trim() !== '')
)

const selectedRange = computed(() => {
  if (props.filterData.range) return props.filterData.range

  if (!selectedSubFilterValue.value) return null

  return (props.filterData.multiValueRanges).find(
    (range) => range.values.includes(selectedSubFilterValue.value)
  )?.range
})

const rangeList = computed(() => {
  if (!selectedRange.value) return []

  const params = selectedRange.value

  const list = []
  let currentValue = params.min

  if (params.less) {
    list.push(`<${params.min}`)
  }
  while (currentValue <= params.max) {
    list.push(currentValue)
    currentValue += params.interval
  }
  if (params.more) {
    list.push(`>${params.max}`)
  }

  return list
})

const rangeFormat = computed(() => {
  if (!selectedRange.value) {
    return '{value}'
  }
  return selectedRange.value.unit ? `{value}${selectedRange.value.unit}` : '{value}'
})

watch([() => props.filterData.name, selectedSubFilterValue], () => {
  initializeRange()
})

function initializeRange() {
  if (selectedRange.value) {
    const params = selectedRange.value
    const newMin = params.less ? `<${params.min}` : params.min
    const newMax = params.more ? `>${params.max}` : params.max
    resetRange(newMin, newMax)
  }
}

function setRange(): void {
  const { name, format, subFilters } = props.filterData
  let type = format

  if (subFilters) {
    subFilters.forEach((filter) => {
      type = type.replace(`{${filter.name}}`, capitalize(subFiltersValues.value[filter.name]))
    })
    type = type.charAt(0).toLowerCase() + type.slice(1)
  }

  if (value.value.length) {
    const rangeValue = `${value.value[0]}_${value.value[1]}`
    emit('add-filter', { name, type, value: rangeValue })
  }
}

function resetRange(newMin: string | number, newMax: string | number) {
  value.value = [newMin, newMax]
}

function updateSelectedSubFilterValue() {
  if (visibleSubFilters.value.length === 1) {
    selectedSubFilterValue.value = subFiltersValues.value[visibleSubFilters.value[0].name]
  } else {
    // For multiple subfilters, take the last non-empty value
    const subFilterNames = visibleSubFilters.value.map((filter) => filter.name)
    for (let i = subFilterNames.length - 1; i >= 0; i--) {
      const value = subFiltersValues.value[subFilterNames[i]]
      if (value !== '') {
        selectedSubFilterValue.value = value
        return
      }
    }
    selectedSubFilterValue.value = ''
  }
}

onMounted(() => {
  initializeRange()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (visibleSubFilters.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleSubFilters.value, (filter) => {
            return _withDirectives((_openBlock(), _createElementBlock("select", {
              key: filter.name,
              "onUpdate:modelValue": ($event: any) => ((subFiltersValues.value[filter.name]) = $event),
              class: "custom-select form-control",
              onChange: updateSelectedSubFilterValue
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("option", {
                value: "",
                disabled: "",
                selected: ""
              }, " --- ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (option) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: _unref(getOptionValue)(option),
                  value: _unref(getOptionValue)(option),
                  class: _normalizeClass({ 'font-weight-bold text-dark': _unref(isGroupOption)(option) && option.isTitle })
                }, _toDisplayString(`${(option.isTitle === false ? '    ' : '') + _unref(displayedOption)(filter, option)}`), 11, _hoisted_3))
              }), 128))
            ], 40, _hoisted_2)), [
              [_vModelSelect, subFiltersValues.value[filter.name]]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (areAllSubFiltersSet.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_unref(VueSlider), {
              key: `${_ctx.filterData.name}-${selectedSubFilterValue.value}`,
              modelValue: value.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
              "v-data": rangeList.value,
              formatter: rangeFormat.value,
              tooltip: 'always',
              range: ""
            }, null, 8, ["modelValue", "v-data", "formatter"]))
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              type: "button",
              onClick: setRange
            }, " Ok ")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})