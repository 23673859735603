import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stay-history container" }
const _hoisted_2 = { class: "stay-list-history card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row p-3" }
const _hoisted_5 = {
  key: 0,
  class: "card-body"
}
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "row no-gutters nav nav-tabs" }
const _hoisted_8 = { class: "nav-item col text-center home-list-tab" }
const _hoisted_9 = { class: "nav-item col text-center home-list-tab" }

import _ from 'lodash'
import { computed, ref } from 'vue'

import FindBar from '@/common/FindBar.vue'
import StayEditionsPage from '@/maintenance/StayEditionsPage.vue'
import StayStatusListPage from '@/maintenance/StayStatusListPage.vue'
import router from '@/router'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayHistory',
  setup(__props) {

const store = useStore()

const selectedTab = ref('editions')

const stayRssId = computed(() => store.state.route.params.stayRssId)

async function handleSearch({ result, input }) {
  const stayRssidInput = !_.isEmpty(result) ? result.rssId : input

  await store.commit('logsReset')
  await router.push({
    path: `/maintenance/stay-history/${stayRssidInput}`,
    query: {},
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "mb-4" }, " Rechercher un séjour ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(FindBar, {
            class: "w-100",
            onHandleSubmit: handleSearch,
            onHandleClick: handleSearch
          })
        ])
      ]),
      (stayRssId.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("h1", _hoisted_6, " Historique du séjour à l'identifiant RSS #" + _toDisplayString(stayRssId.value), 1),
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("span", {
                  class: _normalizeClass([{'active': selectedTab.value === 'editions'}, "nav-link"]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (selectedTab.value = 'editions'))
                }, " Historique des actions sur le séjour ", 2)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass([{'active': selectedTab.value === 'consultations'}, "nav-link"]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedTab.value = 'consultations'))
                }, " Historique de consultations du séjour ", 2)
              ])
            ]),
            (selectedTab.value === 'editions')
              ? (_openBlock(), _createBlock(StayEditionsPage, {
                  key: 0,
                  class: "home-list p-3",
                  "rss-id": stayRssId.value
                }, null, 8, ["rss-id"]))
              : _createCommentVNode("", true),
            (selectedTab.value === 'consultations')
              ? (_openBlock(), _createBlock(StayStatusListPage, {
                  key: 1,
                  class: "home-list p-3",
                  "rss-id": stayRssId.value
                }, null, 8, ["rss-id"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})