import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row no-gutters nav nav-tabs" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item col text-center home-list-tab"
}
const _hoisted_3 = {
  key: 1,
  class: "nav-item col text-center home-list-tab"
}
const _hoisted_4 = {
  key: 2,
  class: "nav-item col text-center home-list-tab"
}
const _hoisted_5 = { class: "nav-item col text-center home-list-tab" }

import { CountryEnum, ModeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeListSelector',
  props: {
    isAutomationAllowed: { type: Boolean },
    isNewCodingAllowed: { type: Boolean, default: true },
    isAutomationEnabled: { type: Boolean },
    selectedList: {}
  },
  emits: ['set-list'],
  setup(__props: any) {






return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.isAutomationAllowed)
      ? _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass([{ 'active': _ctx.selectedList === _unref(ModeEnum).AUTOMATION, 'disabled': !_ctx.isAutomationEnabled }, "nav-link"]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAutomationEnabled ? _ctx.$emit('set-list', _unref(ModeEnum).AUTOMATION) : null))
          }, _toDisplayString(_ctx.$t('tab.automation')), 3)
        ])), [
          [_directive_tooltip, _ctx.isAutomationEnabled ? null : 'Cette fonctionnalité a été désactivée par un administrateur']
        ])
      : _createCommentVNode("", true),
    (_ctx.isNewCodingAllowed)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass([{ 'active': _ctx.selectedList === _unref(ModeEnum).NEW_CODING }, "nav-link"]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('set-list', _unref(ModeEnum).NEW_CODING)))
          }, _toDisplayString(_ctx.$t('tab.new-coding')), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$i18n.locale !== _unref(CountryEnum).CH)
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass([{ 'active': _ctx.selectedList === _unref(ModeEnum).OPTIMIZABLE }, "nav-link"]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('set-list', _unref(ModeEnum).OPTIMIZABLE)))
          }, _toDisplayString(_ctx.$t('tab.optimizable')), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_5, [
      _createElementVNode("span", {
        class: _normalizeClass([{ 'active': _ctx.selectedList === _unref(ModeEnum).QUALITY_STAYS }, "nav-link"]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('set-list', _unref(ModeEnum).QUALITY_STAYS)))
      }, _toDisplayString(_ctx.$t('tab.quality-stays')), 3)
    ])
  ]))
}
}

})