import _ from 'lodash'
import lodashFp from 'lodash/fp'

import { Condition, ConditionGroup } from '@/store/modules/automation/types'
import { PredictedLabel, PredictedLabelType } from '@/store/modules/diagnosis/types'
import { Stay } from '@/store/modules/stay/types'
import { StaySavedSearch } from '@/store/modules/stay-saved-search/types'
import {
  KeywordParams,
  KeywordSearch,
  PredictionsMatches, SearchesMatches,
  StayParseContents,
} from '@/text-processing/types'

import {
  getPredictionMatches,
  getSavedSearchMatches,
  parseContentWords,
} from './splitReportContent'

// We construct a fake search for every keyword that is in the current search
export function getAdditionnalKeywordSearches(keywordParam: KeywordParams): KeywordSearch[] {
  const additionnalKeywordSearches = []
  if (keywordParam) {
    let keywords: string[] = []
    let keywordType: string
    let documentType: string | null
    for (const [key, value] of Object.entries(keywordParam)) {
      keywords = value
      keywordType = key === 'kw' ? 'presentContent' : key.replace('kw-', 'presentContent__')
      documentType = key === 'kw' ? null : key.replace('kw-', '')
      keywords.forEach((keyword, idx) => {
        additionnalKeywordSearches.push({
          documentType,
          value: keyword,
          search: {
            id: `kw-${keywordType}-${idx}`,
            criteriaGroups: [{
              reference: 'tmp',
              criteriaList: [{ type: keywordType, value: keyword }]
            }]
          }
        })
      })
    }
  }
  return additionnalKeywordSearches
}

export function getSearchesMatches(staySavedSearches: StaySavedSearch[], additionnalKeywordSearches: KeywordSearch[], parsedContent: StayParseContents): { [type: string]: SearchesMatches } {
  return _.flow([
    lodashFp.concat(_.values(additionnalKeywordSearches)),
    lodashFp.keyBy('search.id'),                                                                        // build an object {id1: staySavedSearch, id2: staySavedSearch}
    lodashFp.mapValues((staySavedSearch: StaySavedSearch) => getSavedSearchMatches(staySavedSearch.search, parsedContent)) // { id1: matches, id2: matches...}
  ])(staySavedSearches)
}

export function getKeywordList(conditionGroups: ConditionGroup[]): KeywordParams {
  const keywordList: KeywordParams = {}
  let presentContentConditions: Condition[] = []
  conditionGroups.forEach((conditionGroup) => {
    const currentPresentContentCriteria = conditionGroup.criteriaList.filter((f) => f.type.startsWith('presentContent'))
    if (currentPresentContentCriteria) {
      presentContentConditions = _.uniq(presentContentConditions.concat(currentPresentContentCriteria))
    }
  })

  presentContentConditions.forEach((condition) => {
    const typeTokens = condition.type.split('__')
    const docType = typeTokens.length > 1 ? typeTokens[1] : null
    const key = docType !== null ? `kw-${docType}` : 'kw'

    if (!keywordList[key]) {
      keywordList[key] = []
    }
    keywordList[key].push(condition.value)
  })

  return keywordList
}

export function getPredictedLabelsMatches(summaryUnits: { displayablePredictedLabels: PredictedLabel[] }[], stay: Stay, labelTypes: PredictedLabelType[], hintThreshold: number): { [labelType: string]: { [labelReference: string]: PredictionsMatches } }[] {
  return summaryUnits.map((summaryUnit) => {
    const summaryUnitPredictionMatches: { [type: string]: { [labelReference: string]: PredictionsMatches } } = {}
    labelTypes.forEach((labelType) => summaryUnitPredictionMatches[labelType] = {})

    for (const predictedLabel of summaryUnit.displayablePredictedLabels ?? []) {
      let filteredHints = predictedLabel.hints.filter((h) => h.confidence >= hintThreshold) // Add primo/revalo
      // if no hint is above threshold, take the most confident one
      if (!filteredHints.length && predictedLabel.hints.length) {
        filteredHints = [_.maxBy(predictedLabel.hints, (h) => h.confidence)]
        filteredHints = filteredHints[0] ? filteredHints : [predictedLabel.hints[0]]
      }

      summaryUnitPredictionMatches[predictedLabel.type][getPredictedLabelReference(predictedLabel)] = getPredictionMatches(stay, filteredHints)
    }

    return summaryUnitPredictionMatches
  })
}

export function getParseContent(stay: Stay): StayParseContents {
  return {
    reports: _.forEach(stay.reports, (report) => {
      report.parsedWords = parseContentWords(report.content, {
        negation: report.markedNegationIndexSets,
        antecedent: report.markedAntecedentIndexSets,
        familyAntecedent: report.markedFamilyAntecedentIndexSets,
        doubt: report.markedDoubtIndexSets,
      })
    }),
    textualHealthEntries: _.forEach(stay.textualHealthEntries, (entry) => {
      entry.parsedWords = parseContentWords(entry.content, {
        negation: entry.markedNegationIndexSets,
        doubt: entry.markedDoubtIndexSets,
        familyAntecedent: entry.markedFamilyAntecedentIndexSets,
        antecedent: entry.markedAntecedentIndexSets,
      })
    }),
    categoricalLabResults: _.forEach(stay.categoricalLabResults, (catLab) => {
      catLab.parsedWords = parseContentWords(catLab.value, {
        negation: catLab.markedNegationIndexSets,
        doubt: catLab.markedDoubtIndexSets,
      })
    }),
    drugEvents: stay.drugEvents,
  }
}

export function getPredictedLabelReference(predictedLabel: PredictedLabel): string {
  return predictedLabel.diagnosis?.reference ?? predictedLabel.medicalAct.reference
}
