import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-label" }
const _hoisted_2 = ["title"]


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupingField',
  props: {
    label: {},
    value: {},
    description: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("h2", { title: _ctx.description }, _toDisplayString(_ctx.value || '-'), 9, _hoisted_2)
  ], 64))
}
}

})