import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-100 pl-5 pr-5" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row justify-content-between align-baseline no-gutters mb-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "w-100 h-100" }
const _hoisted_7 = {
  class: "table",
  style: {"table-layout":"fixed"}
}
const _hoisted_8 = {
  class: "align-top font-weight-bold",
  style: {"width":"250px"}
}
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "float-right pr-3" }
const _hoisted_12 = { class: "align-top" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }

import { computed, ComputedRef, onMounted, ref } from 'vue'

import { event } from '@/maintenance/types'
import ViewerJSON from '@/maintenance/ViewerJSON.vue'
import ViewerXML from '@/maintenance/ViewerXML.vue'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'EventViewer',
  setup(__props) {

const store = useStore()

const logEventViewer = ref({})

const logEventShown: ComputedRef<event> = computed((): event => store.state.maintenance.logs.logEventShown)

const parsers = [
  'json',
  'raw',
  'xml'
]

onMounted(() => {
  refresh()
})

function refresh() {
  logEventViewer.value = {}
  for (const [dataName, dataContent] of Object.entries(logEventShown.value)) {
    logEventViewer.value[dataName] = messageType(dataContent)
  }
}

function messageType(message) {
  switch (/\S/.exec(message).toString()) {
    case '[':
    case '{':
      return 'json'
    case '<':
      return 'xml'
    default:
      return 'raw'
  }
}

function moveLog(move) {
  store.commit('moveLogEventShown', move)
  refresh()
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: { name: 'maintenance-event-observer', hash: `#event-${logEventShown.value.id}`}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" < " + _toDisplayString(_ctx.$t('maintenance.event-observer.title')), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          (Object.keys(logEventViewer.value).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "btn btn-outline-primary mr-1",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (moveLog(-1)))
                }, _toDisplayString(_ctx.$t('maintenance.event-viewer.previous')), 1),
                _createElementVNode("button", {
                  class: "btn btn-outline-primary ml-1",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (moveLog(+1)))
                }, _toDisplayString(_ctx.$t('maintenance.event-viewer.next')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(logEventShown.value, (dataContent, dataName) => {
                return (_openBlock(), _createElementBlock("tr", { key: dataName }, [
                  _createElementVNode("td", _hoisted_8, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": ($event: any) => ((logEventViewer.value[dataName]) = $event)
                    }, [
                      _cache[2] || (_cache[2] = _createElementVNode("option", {
                        value: "",
                        disabled: ""
                      }, " --- Format --- ", -1)),
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(parsers, (parser) => {
                        return _createElementVNode("option", {
                          key: parser,
                          value: parser
                        }, _toDisplayString(parser), 9, _hoisted_10)
                      }), 64))
                    ], 8, _hoisted_9), [
                      [_vModelSelect, logEventViewer.value[dataName]]
                    ]),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(dataName) + " :", 1)
                  ]),
                  _createElementVNode("td", _hoisted_12, [
                    (logEventViewer.value[dataName] === 'json')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createVNode(ViewerJSON, {
                            data: dataContent.toString()
                          }, null, 8, ["data"])
                        ]))
                      : (logEventViewer.value[dataName] === 'xml')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(ViewerXML, {
                              data: dataContent.toString()
                            }, null, 8, ["data"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("pre", null, _toDisplayString(dataContent), 1)
                          ]))
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})