import { computed, h } from 'vue'

import McoStayListLine from '@/stay-list/stay-list-table/mco/McoStayListLine.vue'
import McoStayListColumns from '@/stay-list/stay-list-table/mco/StayListColumns'
import SsrStayListLine from '@/stay-list/stay-list-table/ssr/SsrStayListLine.vue'
import SsrStayListColumns from '@/stay-list/stay-list-table/ssr/StayListColumns'
import StayListTable from '@/stay-list/stay-list-table/StayListTable.vue'
import { ListStay, StayListLineSlotProps } from '@/stay-list/stay-list-table/types'
import { useStore } from '@/store'
import { PmsiTypeEnum } from '@/store/modules/settings/types'

const StayListLineComponents = {
  [PmsiTypeEnum.MCO]: McoStayListLine,
  [PmsiTypeEnum.SSR]: SsrStayListLine
}

const PmsiStayListColumns = {
  [PmsiTypeEnum.MCO]: McoStayListColumns,
  [PmsiTypeEnum.SSR]: SsrStayListColumns,
}

export default function usePmsiStayListTable<TStay extends ListStay>(pmsiType: PmsiTypeEnum) {
  const store = useStore()

  const stayList = computed(() => store.state.stayList.stayList.items)
  const mode = computed(() => store.state.stayList.stayListParams.mode)

  const params = computed(() => store.state.stayList.stayListParams)

  const requestFetching = computed(() => store.state.stayList.fetchStayListRequest.fetching)

  const updateOrder = (toBeOrderedColumn: string) => store.dispatch('updateStayListOrder', toBeOrderedColumn)

  return () => {
    return h(StayListTable, {
      stayList: stayList.value,
      mode: mode.value,
      pmsiType: pmsiType,
      availableColumns: PmsiStayListColumns[pmsiType],
      order: params.value.order,
      reverseOrder: params.value.reverseOrder,
      isLoading: requestFetching.value,
      onOrderUpdate: (toBeOrderedColumn: string) => updateOrder(toBeOrderedColumn)
    },
    {
      line: (lineProps: StayListLineSlotProps<TStay>) => h(StayListLineComponents[pmsiType], {
        stay: lineProps.stay,
        columns: lineProps.columns,
        allowSelection: lineProps.canSelectStay,
        selected: lineProps.selected,
        mode: mode.value,
        onToggleSelectedStay: lineProps.toggleSelectedStay
      })
    })
  }
}

