import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dashboard-title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "aligned-row mt-2" }

import { computed, ref } from 'vue'

import MedicalActForm from '@/stay-displayer/medical-act/MedicalActForm.vue'
import MedicalAct from '@/stay-displayer/ssr/rhs/medical-act/MedicalAct.vue'
import { CodedMedicalAct } from '@/store/modules/medical-act/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { Rhs, RhsDashboardButtonLabel, RhsDashboardTitle, RhsDashboardType } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalActs',
  props: {
    codedActs: {},
    rhs: {}
  },
  setup(__props: any) {

const props = __props

const actFormVisible = ref(false)
const displayedCodedActs = computed(() => props.codedActs.filter((codedAct) => !codedAct.disabled))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("small", null, _toDisplayString(_unref(RhsDashboardTitle)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CCAM]), 1)
    ]),
    (_ctx.codedActs.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
          _createElementVNode("small", { class: "aligned-row font-italic" }, "Aucun acte", -1)
        ])))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(displayedCodedActs.value, (codedAct) => {
          return (_openBlock(), _createBlock(MedicalAct, {
            key: codedAct.id,
            "coded-act": codedAct,
            "rhs-id": _ctx.rhs.id
          }, null, 8, ["coded-act", "rhs-id"]))
        }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "col-12 btn btn-sm",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (actFormVisible.value = true))
      }, _toDisplayString(_unref(RhsDashboardButtonLabel)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CCAM]), 1)
    ]),
    _createVNode(MedicalActForm, {
      "summary-unit": _ctx.rhs,
      "pmsi-type": _unref(PmsiTypeEnum).SSR,
      "presence-days": _ctx.rhs.weekDays+_ctx.rhs.weekEndDays,
      visible: actFormVisible.value,
      onCloseForm: _cache[1] || (_cache[1] = ($event: any) => (actFormVisible.value = false))
    }, null, 8, ["summary-unit", "pmsi-type", "presence-days", "visible"])
  ], 64))
}
}

})