import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { PropType } from 'vue'

import { StayNotificationEnum, StayStatus } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationLineColumn',
  props: {
  displayed: { type: Boolean, required: true },
  staySeen: { type: Object as PropType<StayStatus>, required: true }
},
  setup(__props) {



const StayNotificationStyleClass: { [key in StayNotificationEnum]: string } = {
  [StayNotificationEnum.NONE]: '',
  [StayNotificationEnum.NOT_READ]: '',
  [StayNotificationEnum.SOFT]: 'soft-notification-icon-cell',
  [StayNotificationEnum.HARD]: 'hard-notification-icon-cell'
}

const StayNotificationIcon: {
  [key in StayNotificationEnum]: { name: string, width: number, height: number }
} = {
  [StayNotificationEnum.NONE]: { name: 'dot-fill', width: 4, height: 11 },
  [StayNotificationEnum.NOT_READ]: { name: 'dot-fill', width: 8, height: 13 },
  [StayNotificationEnum.SOFT]: { name: 'dot-fill', width: 8, height: 13 },
  [StayNotificationEnum.HARD]: { name: 'bell-fill', width: 14, height: 14 }
}


return (_ctx: any,_cache: any) => {
  return (__props.displayed)
    ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: _normalizeClass(`column-icon ${StayNotificationStyleClass[__props.staySeen.notification]}`)
      }, [
        _createVNode(_unref(SancareOcticon), {
          name: StayNotificationIcon[__props.staySeen.notification].name,
          width: StayNotificationIcon[__props.staySeen.notification].width,
          height: StayNotificationIcon[__props.staySeen.notification].height
        }, null, 8, ["name", "width", "height"])
      ], 2))
    : _createCommentVNode("", true)
}
}

})