import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "dashboard__code" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "dashboard dashboard-severity" }

import { computed, PropType } from 'vue'

import { defaultGme, Gme } from '@/store/modules/stay/ssr/grouping/types'
import { RhsDashboardClass, RhsDashboardType } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GmeDisplayer',
  props: {
  gme: {
    type: Object as PropType<Gme>,
    required: false,
    default: null
  },
  size: {
    validator(value: string) {
      return ['sm', 'md'].includes(value)
    },
    default: 'md'
  }
},
  setup(__props) {

const props = __props

const displayedGme = computed(() => props.gme !== null ? props.gme : defaultGme())
const style = computed(() => `gme-reference-${props.size}`)

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gme-reference row no-gutters", style.value])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["dashboard", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_DIAGNOSES]])
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(displayedGme.value.gn || '????'), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["dashboard", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CSARR]])
      }, [
        _createElementVNode("div", null, _toDisplayString(displayedGme.value.gr || '?'), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["dashboard", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_DEPENDENCIES]])
      }, [
        _createElementVNode("div", null, _toDisplayString(displayedGme.value.gl || '?'), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, _toDisplayString(displayedGme.value.level || '?'), 1)
      ])
    ])
  ], 2)), [
    [_directive_tooltip, displayedGme.value.description]
  ])
}
}

})