import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dashboard-title" }
const _hoisted_2 = {
  key: 0,
  class: "no-rehabilitation-acts"
}

import { computed } from 'vue'

import RehabilitationAct from '@/stay-displayer/ssr/rhs/rehabilitation-act/RehabilitationAct.vue'
import { CodedRehabilitationAct, RhsDashboardTitle, RhsDashboardType } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RehabilitationActs',
  props: {
    codedRehabilitationActs: {}
  },
  setup(__props: any) {

const props = __props

const displayedCodedRehabilitationActs = computed(() =>
  props.codedRehabilitationActs.filter((codedRehabilitationAct) => !codedRehabilitationAct.disabled),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("small", null, _toDisplayString(_unref(RhsDashboardTitle)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CSARR]), 1)
    ]),
    (displayedCodedRehabilitationActs.value.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("small", { class: "aligned-row font-italic" }, "Aucun acte", -1)
        ])))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(displayedCodedRehabilitationActs.value, (codedRehabilitationAct) => {
          return (_openBlock(), _createBlock(RehabilitationAct, {
            key: codedRehabilitationAct.id,
            "coded-rehabilitation-act": codedRehabilitationAct
          }, null, 8, ["coded-rehabilitation-act"]))
        }), 128))
  ], 64))
}
}

})