import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import usePmsiStayListTable from '@/stay-list/stay-list-table/composables/pmsiStayListTable'
import { ListMcoStay } from '@/stay-list/stay-list-table/mco/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'McoStayListTable',
  setup(__props) {

const PmsiStayListTable = usePmsiStayListTable<ListMcoStay>(PmsiTypeEnum.MCO)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(PmsiStayListTable))))
}
}

})