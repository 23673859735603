import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = {
  key: 0,
  class: "loader loader-lg loader-center mb-4"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "row"
}
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "list-group" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "col-9" }
const _hoisted_10 = { class: "card px-3" }

import { SancareToggle } from '@sancare/ui-frontend-commons'
import { computed, onMounted, onUpdated, ref } from 'vue'

import StayEditionDetail from '@/maintenance/StayEditionDetail.vue'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayEditionsPage',
  props: {
    rssId: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const selectedHistory = ref(0)

const prefs = computed(() => store.state.maintenance.prefs.stayEditions)
const stayEditions = computed(() => {
  const isStayEditionsReady = store.state.maintenance.stayEditions && store.state.maintenance.stayEditions.formated

  return isStayEditionsReady ? store.state.maintenance.stayEditions.formated : []
})

const stayEditionsRequest = computed(() => store.state.maintenance.requests.getStayEditionRequest)

onMounted(refresh)
onUpdated(refresh)

function refresh() {
  if ((stayEditionsRequest.value.fetching || stayEditionsRequest.value.ok)) {
    return
  }
  store.dispatch('getStayEditions', { rssId: props.rssId })
}

async function setPref(key: string, value:string) {
  await store.commit('updatePrefs', { prefArea: 'stayEditions', prefKey: key, prefValue: value })
  store.dispatch('getStayEditions', { rssId: props.rssId })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
      _createVNode(_unref(SancareToggle), {
        value: prefs.value.seMoreEventsGrouping,
        label: 'Moins de détails',
        onToggleChange: _cache[0] || (_cache[0] = (moreGrouping) => setPref('seMoreEventsGrouping', moreGrouping))
      }, null, 8, ["value"])
    ]),
    (stayEditionsRequest.value.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (!stayEditionsRequest.value.ok || stayEditionsRequest.value.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Erreur: " + _toDisplayString(stayEditionsRequest.value.error), 1))
        : (stayEditions.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Aucun historique à afficher pour l'identifiant RSS " + _toDisplayString(_ctx.rssId), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stayEditions.value, (stayEdition, index) => {
                    return (_openBlock(), _createElementBlock("a", {
                      key: stayEdition._eventtime,
                      href: "#",
                      class: _normalizeClass(["list-group-item list-group-item-action", {active: selectedHistory.value === index}]),
                      onClick: _withModifiers(($event: any) => (selectedHistory.value = index), ["prevent"])
                    }, [
                      _createElementVNode("h6", null, "Changement du " + _toDisplayString(stayEdition._eventtime), 1)
                    ], 10, _hoisted_8))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "mb-3" }, " Détails de l'événement ", -1)),
                  _createElementVNode("div", null, [
                    _createVNode(StayEditionDetail, {
                      "stay-edition": stayEditions.value[selectedHistory.value] ?? []
                    }, null, 8, ["stay-edition"])
                  ])
                ])
              ])
            ]))
  ]))
}
}

})