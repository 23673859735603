import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row no-gutters aligned-row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-3 text-right" }
const _hoisted_4 = { class: "col-3 font-weight-bold text-right" }
const _hoisted_5 = { class: "row no-gutters aligned-row dependency-section-title" }
const _hoisted_6 = { class: "col-6 mt-3" }
const _hoisted_7 = { class: "font-weight-bold text-uppercase" }
const _hoisted_8 = { class: "col-3 mt-3 font-weight-bold text-right" }
const _hoisted_9 = { class: "col-3 mt-3 font-weight-bold text-right" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-3 d-flex justify-content-end" }
const _hoisted_12 = { class: "col-3 d-flex justify-content-end position-relative font-weight-bold" }
const _hoisted_13 = {
  key: 1,
  class: "d-none flex-column mr-2 dep-arrows position-absolute",
  style: {"top":"-1px","right":"5px"}
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

import _ from 'lodash'
import { computed, ref, watch } from 'vue'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { getWeekNbText } from '@/stay-displayer/ssr/utils'
import { useStore } from '@/store'
import {
  DependenciesLabels,
  DependenciesSections,
  DependenciesSectionsLabels,
  DependenciesType,
  Rhs,
  RhsDashboardClass,
  RhsDashboardTitle,
  RhsDashboardType
} from '@/store/modules/stay/ssr/types'


const MAX_DEPENDENCY_VALUE = 4
const MIN_DEPENDENCY_VALUE = 1


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsDependencies',
  props: {
    currentRhs: {},
    previousRhs: { default: null }
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const initialDependenciesValues = ref({})

const hasPreviousRhs = computed(() => {
  return props.previousRhs !== null
})

const canEditDependencies = computed(() => (hasPermission(RoleEnum.ROLE_STAY_WRITER, store.state.login.userRole)))
const dependenciesSums = computed(() => {
  const sums = {
    previousRhs:{},
    currentRhs:{}
  }

  for(const [sectionKey] of Object.entries(DependenciesLabels)) {
    sums.previousRhs[sectionKey] = 0
    sums.currentRhs[sectionKey] = 0

    for(const [dependencyKey] of Object.entries(DependenciesLabels[sectionKey])) {
      sums.previousRhs[sectionKey] += hasPreviousRhs.value ? props.previousRhs[dependencyKey] : 0
      sums.currentRhs[sectionKey] += props.currentRhs[dependencyKey]
    }
  }

  return sums
})

function changeDependencyValue(dependencyType: DependenciesType, valueChange: number) {
  const newDepValue = props.currentRhs[dependencyType] + valueChange ?? MIN_DEPENDENCY_VALUE

  if (newDepValue > MAX_DEPENDENCY_VALUE || newDepValue < MIN_DEPENDENCY_VALUE) {
    return
  }

  // save previous value to restore it if request fails
  initialDependenciesValues.value[dependencyType] = initialDependenciesValues.value[dependencyType] ?? props.currentRhs[dependencyType] ?? MIN_DEPENDENCY_VALUE

  store.commit('ssrStay/setRhsDependency', { dependencyType, value: newDepValue })
}

function storeDependencyValue(dependencyType) {
  store.dispatch('ssrStay/updateRhsDependency', {
    rhsId: props.currentRhs.id,
    dependencyType,
    value: props.currentRhs[dependencyType]
  }).then((response) => {
    // restore value from initial value if an error occured
    if (response && response.rawError) {
      store.commit('ssrStay/setRhsDependency', { dependencyType, value: initialDependenciesValues.value[dependencyType] })
    }
    // reset initial value
    initialDependenciesValues.value[dependencyType] = null
  })
}

for(const dependencyType of Object.values(DependenciesType)) {
  watch(() => props.currentRhs[dependencyType], _.debounce(function() {
    // send request only if a change is pending
    if(initialDependenciesValues.value[dependencyType] !== null) {
      storeDependencyValue(dependencyType)
    }
  }, 500))
}

function dependencyUpdateIsPending(dependencyType) {
  return initialDependenciesValues.value[dependencyType]
    && initialDependenciesValues.value[dependencyType] !== null
}

function dependenciesTotalIsPending(dependencySection) {
  for (const dependencyType of Object.keys(DependenciesLabels[dependencySection])) {
    if (dependencyUpdateIsPending(dependencyType)) {
      return true
    }
  }
  return false
}

function arrowButtonStyle(depType, limitValue) {
  return {
    opacity: (props.currentRhs[depType] ?? MIN_DEPENDENCY_VALUE) === limitValue ? 0.3 : 1
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dashboard mb-2", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_DEPENDENCIES]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", _hoisted_2, _toDisplayString(_unref(RhsDashboardTitle)[_unref(RhsDashboardType).RHS_DASHBOARD_DEPENDENCIES]), 1),
      _createElementVNode("small", _hoisted_3, _toDisplayString(hasPreviousRhs.value ? _unref(getWeekNbText)(_ctx.previousRhs) : ''), 1),
      _createElementVNode("small", _hoisted_4, _toDisplayString(_unref(getWeekNbText)(_ctx.currentRhs)), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(DependenciesSections), (section) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: section }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("small", _hoisted_7, _toDisplayString(_unref(DependenciesSectionsLabels)[section]), 1)
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(hasPreviousRhs.value ? dependenciesSums.value.previousRhs[section] : ''), 1),
          _createElementVNode("div", _hoisted_9, [
            (canEditDependencies.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "loader loader-sm loader-center m-0 ml-3",
                  style: _normalizeStyle({opacity:dependenciesTotalIsPending(section) ? 1 : 0})
                }, null, 4))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              style: _normalizeStyle({ opacity: dependenciesTotalIsPending(section) ? '0.5' : '1' })
            }, _toDisplayString(dependenciesSums.value.currentRhs[section]), 5)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(DependenciesLabels)[section], (depLabel, depType) => {
          return (_openBlock(), _createElementBlock("div", {
            key: depType,
            class: "row no-gutters hoverable-item"
          }, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(depLabel), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(hasPreviousRhs.value ? _ctx.previousRhs[depType] : ''), 1),
            _createElementVNode("div", _hoisted_12, [
              (canEditDependencies.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "loader loader-sm loader-center m-0 ml-3 position-absolute",
                    style: _normalizeStyle([{"width":"10px","height":"10px","right":"30px","top":"6px"}, {opacity:dependencyUpdateIsPending(depType) ? 1 : 0}])
                  }, null, 4))
                : _createCommentVNode("", true),
              (canEditDependencies.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("span", {
                      class: "dep-button",
                      style: _normalizeStyle(arrowButtonStyle(depType, MAX_DEPENDENCY_VALUE)),
                      onClick: ($event: any) => (changeDependencyValue(depType, +1))
                    }, "▲", 12, _hoisted_14),
                    _createElementVNode("span", {
                      class: "dep-button",
                      style: _normalizeStyle(arrowButtonStyle(depType, MIN_DEPENDENCY_VALUE)),
                      onClick: ($event: any) => (changeDependencyValue(depType, -1))
                    }, "▼", 12, _hoisted_15)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                style: _normalizeStyle({opacity: dependencyUpdateIsPending(depType) ? 0.5 : 1})
              }, _toDisplayString(_ctx.currentRhs[depType]), 5)
            ])
          ]))
        }), 128))
      ], 64))
    }), 128))
  ], 2))
}
}

})