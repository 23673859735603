import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-items-end" }
const _hoisted_3 = { class: "col-auto text-center" }
const _hoisted_4 = { name: "stayList_count" }
const _hoisted_5 = { class: "undertitle" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "row align-items-end"
}
const _hoisted_8 = { class: "col-auto text-center" }
const _hoisted_9 = {
  key: 0,
  name: "precision_rate"
}
const _hoisted_10 = {
  key: 1,
  name: "precision_rate"
}
const _hoisted_11 = { class: "undertitle" }
const _hoisted_12 = { class: "col-auto text-center" }
const _hoisted_13 = {
  key: 0,
  name: "recall_rate"
}
const _hoisted_14 = {
  key: 1,
  name: "recall_rate"
}
const _hoisted_15 = { class: "undertitle" }
const _hoisted_16 = {
  key: 1,
  class: "ml-0"
}
const _hoisted_17 = { class: "col-auto text-center" }
const _hoisted_18 = {
  key: 0,
  class: "text-center"
}
const _hoisted_19 = { class: "col-auto ml-auto" }
const _hoisted_20 = { class: "undertitle" }

import { SancarePagination, SancareToggle } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed, h, onMounted, watch } from 'vue'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import AdvancedStayListCriteria from '@/stay-list/AdvancedStayListCriteria.vue'
import McoStayListActions from '@/stay-list/stay-list-table/mco/McoStayListActions.vue'
import McoStayListTable from '@/stay-list/stay-list-table/mco/McoStayListTable.vue'
import SsrStayListActions from '@/stay-list/stay-list-table/ssr/SsrStayListActions.vue'
import SsrStayListTable from '@/stay-list/stay-list-table/ssr/SsrStayListTable.vue'
import StayListCriteria from '@/stay-list/StayListCriteria.vue'
import { useStore } from '@/store'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayListPage',
  props: {
    pmsiType: {}
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const StayListTables = {
  [PmsiTypeEnum.MCO]: McoStayListTable,
  [PmsiTypeEnum.SSR]: SsrStayListTable
}
const StayListTable = computed(() => StayListTables[props.pmsiType])
const StayListActionsComponents = {
  [PmsiTypeEnum.MCO]: McoStayListActions,
  [PmsiTypeEnum.SSR]: SsrStayListActions
}
const StayListActions = computed(() => StayListActionsComponents[props.pmsiType])
const isMcoPmsi = computed(() => props.pmsiType === PmsiTypeEnum.MCO)

const stayCount = computed(() => store.state.stayList.stayList.totalCount)
const pageCount = computed(() => store.state.stayList.stayList.pageCount)
const currentPage = computed(() => store.state.stayList.stayListParams.currentPage)
const mode = computed(() => store.state.stayList.stayListParams.mode)
const isSearchCreation = computed(() => store.state.stayList.stayListParams.searchCreation)
const isSearchEdition = computed(() => store.state.stayList.stayListParams.searchEdition !== -1)
const isSearchEditor = computed(() => hasPermission(RoleEnum.ROLE_SANCARE_SEARCH_MANAGER, store.state.login.userRole))
const isAdvancedSearchUser = computed(() => store.state.login.userRole === 'ROLE_DIM' || isSearchEditor.value)
const precision = computed(() => store.state.stayList.stayList.precision)
const recall = computed(() => store.state.stayList.stayList.recall)
const selected = computed(() => store.state.stayList.stayList.selected)
const totalMatching = computed(() => store.state.stayList.stayList.totalMatching)
const expected = computed(() => store.state.stayList.stayList.expected)
const params = computed(() => store.state.stayList.stayListParams)
const currentSearch = computed(() => store.state.stayList.currentSearch)
const teamMode = computed(() => store.state.settings.currentUser.teamMode)

function setParameter(type: string, value: string | number): void {
  store.commit('setStayListParam', { type, value })
  store.dispatch('refreshStayList', { resetStaySelection: type !== 'pageSize' })
  store.dispatch('refreshStayListUrl', {})
}
function setPage(value: string | number): void {
  setParameter('currentPage', value)
}
function setSearchIsAdvanced(isAdvanced: boolean): void {
  store.commit('setSearchIsAdvanced', isAdvanced)
}
function setPmsiType(pmsiType: PmsiTypeEnum): void {
  store.commit('setSearchPmsiType', pmsiType)
}

watch(() => teamMode, (curr, prev) => {
  // FIXME: little hack that prevents refresh() to be called twice on browser refresh
  if (!_.isNull(prev)) {
    store.dispatch('refreshStayList', { resetStaySelection: true, refreshUrl: false })
  }
})

onMounted(() => {
  if (isSearchCreation.value && props.pmsiType === PmsiTypeEnum.SSR) {
    setSearchIsAdvanced(true)
  }

  setPmsiType(props.pmsiType)
})

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(stayCount.value) + _toDisplayString(stayCount.value > 9999 ? '+' : ''), 1),
        _createElementVNode("div", _hoisted_5, [
          (isSearchEditor.value && params.value.mode !== _unref(ModeEnum).AUTOMATION)
            ? (_openBlock(), _createElementBlock("br", _hoisted_6))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createTextVNode(" séjours "))
        ])
      ]),
      (isMcoPmsi.value && isSearchEditor.value && params.value.mode !== _unref(ModeEnum).AUTOMATION)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("h2", _hoisted_8, [
              (precision.value !== null)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(precision.value) + "% ", 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, " -- ")),
              _createElementVNode("div", _hoisted_11, _toDisplayString(selected.value) + "/" + _toDisplayString(totalMatching.value), 1),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "undertitle" }, " précision ", -1))
            ]),
            _createElementVNode("h2", _hoisted_12, [
              (recall.value !== null)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(recall.value) + "% ", 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_14, " -- ")),
              _createElementVNode("div", _hoisted_15, _toDisplayString(selected.value) + "/" + _toDisplayString(expected.value), 1),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "undertitle" }, " rappel ", -1))
            ]),
            _createElementVNode("h2", null, [
              _createVNode(_unref(SancareToggle), {
                class: "px-5 py-1",
                value: params.value.improveRecallMode,
                disabled: mode.value !== _unref(ModeEnum).OPTIMIZABLE,
                onToggleChange: _cache[0] || (_cache[0] = (improve) => setParameter('improveRecallMode', improve))
              }, null, 8, ["value", "disabled"]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "undertitle" }, [
                _createElementVNode("br"),
                _createTextVNode(" Optimiser le rappel ")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true),
      (isAdvancedSearchUser.value && (isSearchCreation.value || isSearchEdition.value) && params.value.mode !== _unref(ModeEnum).AUTOMATION)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("h2", _hoisted_17, [
              _createVNode(_component_v_dropdown, { triggers: ['hover'] }, {
                popper: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (!currentSearch.value.isAdvancedSearch)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Cette action est irréversible "))
                      : _createCommentVNode("", true),
                    _cache[6] || (_cache[6] = _createTextVNode(" Une recherche avancée ne peut-être convertie en recherche standard "))
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_unref(SancareToggle), {
                    class: "px-5 py-1",
                    value: currentSearch.value.isAdvancedSearch,
                    disabled: currentSearch.value.isAdvancedSearch,
                    onToggleChange: _cache[1] || (_cache[1] = (isAdvanced) => setSearchIsAdvanced(isAdvanced))
                  }, null, 8, ["value", "disabled"])
                ]),
                _: 1
              }),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "undertitle" }, [
                _createElementVNode("br"),
                _createTextVNode("Recherche avancée ")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, " Mode " + _toDisplayString(_ctx.$t('mode.'+params.value.mode)), 1)
      ])
    ]),
    (currentSearch.value.isAdvancedSearch)
      ? (_openBlock(), _createBlock(AdvancedStayListCriteria, {
          key: 0,
          mode: mode.value,
          "pmsi-type": _ctx.pmsiType,
          onSetStayListParameter: setParameter
        }, null, 8, ["mode", "pmsi-type"]))
      : (_openBlock(), _createBlock(StayListCriteria, {
          key: 1,
          mode: mode.value,
          "pmsi-type": _ctx.pmsiType,
          onSetStayListParameter: setParameter
        }, null, 8, ["mode", "pmsi-type"])),
    (_openBlock(), _createBlock(_resolveDynamicComponent(StayListActions.value), { onSetStayListParameter: setParameter }, null, 32)),
    (_openBlock(), _createBlock(_resolveDynamicComponent(StayListTable.value))),
    _createVNode(_unref(SancarePagination), {
      "page-count": pageCount.value,
      "current-page": currentPage.value,
      onSetPage: setPage
    }, null, 8, ["page-count", "current-page"])
  ]))
}
}

})