import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'

import useCoreGroupingCostProps from '@/stay-displayer/grouping/grouping-cost-props'
import { formatCost } from '@/stay-displayer/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupingCost',
  props: useCoreGroupingCostProps(),
  setup(__props) {

const props = __props

const formattedCost = computed(() => formatCost(props.cost))
const style = computed(() => `grouping-cost-${props.size}`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grouping-cost", style.value])
  }, _toDisplayString(formattedCost.value), 3))
}
}

})