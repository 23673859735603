import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { name: "CriteriaDisplayer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "col-12 mb-3 font-italic text-secondary"
}
const _hoisted_4 = { class: "col-5 px-2 criteria-label text-right" }
const _hoisted_5 = { class: "col row no-gutters px-0 py-1" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "text-center font-italic text-secondary"
}
const _hoisted_9 = {
  key: 2,
  class: "container"
}
const _hoisted_10 = { class: "internal-operator col-auto ml-2" }
const _hoisted_11 = { class: "col-auto" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiple_value_filter = _resolveComponent("multiple-value-filter")!
  const _component_sancare_octicon = _resolveComponent("sancare-octicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.displayGroupedCriteria)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.criteriaList, (criteria, criteriaName) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `criteria_list_${criteriaName}`,
              class: "row no-gutters align-items-center"
            }, [
              (criteriaName === 'exclusiveMedicalAct')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Les séjours doivent posséder uniquement des actes présents dans la liste d'actes exclusifs suivante : "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, _toDisplayString(criteria.label), 1),
              _createElementVNode("div", _hoisted_5, [
                (criteria.currentValues)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectDisplayedCriteria(criteria.currentValues), (value, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `criteria__${criteriaName}__${idx}`,
                          class: _normalizeClass(["col-auto mx-1 mb-1 pl-2 pr-2 badge text-wrap", $props.isGlobal ? ['badge-highlight'] : ['badge-primary']])
                        }, [
                          _createVNode(_component_multiple_value_filter, {
                            filter: value,
                            "can-edit": $props.canRemoveCriteria,
                            onRemoveCriteria: $options.removeCriteria
                          }, null, 8, ["filter", "can-edit", "onRemoveCriteria"])
                        ], 2))
                      }), 128)),
                      ($props.maxCriteria > 0 && criteria.currentValues.length > $props.maxCriteria)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, " ... "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]))
      : ((!$props.criteriaGroup.criteriaList || $props.criteriaGroup.criteriaList.length === 0))
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
            _createElementVNode("small", null, "Ce groupe ne contient aucun critère.", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectDisplayedCriteria($props.criteriaGroup.criteriaList), (criterion, criterionIdx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `criteria_list_${criterionIdx}`,
                class: "text-center row"
              }, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(criterionIdx > 0 ? $props.internalOperator : ''), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["badge text-wrap", $props.isGlobal ? ['badge-highlight'] : ['badge-primary']])
                  }, [
                    (['presentContent', 'absentContent'].indexOf(criterion.type) < 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          _createTextVNode(_toDisplayString($options.getCriteriaLabel(criterion.type)) + " : " + _toDisplayString($options.renderCriteria(criterion.type, criterion.value)) + " ", 1),
                          ($props.canRemoveCriteria)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "criteria-remove",
                                onClick: ($event: any) => ($options.removeCriteria(criterion))
                              }, [
                                _createVNode(_component_sancare_octicon, {
                                  name: "x",
                                  width: 14,
                                  height: 11
                                })
                              ], 8, _hoisted_13))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                          _createTextVNode(_toDisplayString($options.getCriteriaLabel(criterion.type)) + " : ", 1),
                          _createVNode(_component_multiple_value_filter, {
                            filter: criterion,
                            "can-edit": $props.canRemoveCriteria,
                            onRemoveCriteria: $options.removeCriteria
                          }, null, 8, ["filter", "can-edit", "onRemoveCriteria"])
                        ]))
                  ], 2)
                ])
              ]))
            }), 128)),
            ($props.maxCriteria > 0 && $props.criteriaGroup.criteriaList.length > $props.maxCriteria)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, " ... "))
              : _createCommentVNode("", true)
          ]))
  ]))
}