import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, onMounted } from 'vue'

import { filterStayListColumns } from '@/stay-list/stay-list-table/helpers/columnFilters'
import StayListLine from '@/stay-list/stay-list-table/mco/McoStayListLine.vue'
import StayListColumns from '@/stay-list/stay-list-table/mco/StayListColumns'
import { ListMcoStay } from '@/stay-list/stay-list-table/mco/types'
import StayListTable from '@/stay-list/stay-list-table/StayListTable.vue'
import { useStore } from '@/store'
import { AutomationModeEnum } from '@/store/modules/automation/types'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AutomationGroupStayListTable',
  emits: ["display-stay-missing-criteria"],
  setup(__props, { emit: __emit }) {

const store = useStore()

onMounted(() => {
  store.commit('setStayListParam', { type: 'mode', value: ModeEnum.AUTOMATION })
})

const stayList = computed(() => store.state.automation.automationStayList.items)
const automationMode = computed(() => store.state.automation.automationStayListParams.automationMode)
const automationGroup = computed(() => store.state.automation.editedAutomationGroup)
const highlightedStayMissingAutomationCriteriaDisplayed = computed(
  () => [AutomationModeEnum.WAITING, AutomationModeEnum.EXPIRED].includes(automationMode.value) && automationGroup.value
)

const order = computed(() => store.state.automation.automationStayListParams.order)
const reverseOrder = computed(() => store.state.automation.automationStayListParams.reverseOrder)

const automationStayListRequestFetching = computed(() => store.state.automation.automationStayListRequest.fetching)

function getModeColumns() {
  return filterStayListColumns(
    StayListColumns,
    (columnKey) => StayListColumns[columnKey].mode.includes(ModeEnum.AUTOMATION)
    && StayListColumns[columnKey].automationType?.includes(automationGroup.value.type)
  )
}

function updateOrder(toBeOrderedColumn: string) {
  store.dispatch('updateAutomStayListOrder', toBeOrderedColumn)
}

const emit = __emit
function displayStayMissingCriteria(stay: ListMcoStay) {
  if (!highlightedStayMissingAutomationCriteriaDisplayed.value) {
    return
  }
  emit('display-stay-missing-criteria', stay)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(StayListTable, {
    "is-loading": automationStayListRequestFetching.value,
    "available-columns": getModeColumns(),
    "pmsi-type": _unref(PmsiTypeEnum).MCO,
    mode: _unref(ModeEnum).AUTOMATION,
    "automation-type": automationGroup.value.type,
    "stay-list": stayList.value,
    order: order.value,
    "reverse-order": reverseOrder.value,
    onStayLineHighlight: displayStayMissingCriteria,
    onOrderUpdate: updateOrder
  }, {
    line: _withCtx((lineProps) => [
      _createVNode(StayListLine, {
        stay: lineProps.stay,
        columns: lineProps.columns,
        mode: _unref(ModeEnum).AUTOMATION,
        selected: lineProps.selected,
        "allow-selection": lineProps.canSelectStay,
        onToggleSelectedStay: lineProps.toggleSelectedStay,
        onMouseover: ($event: any) => (lineProps.onStayLineHighlight(lineProps.stay))
      }, null, 8, ["stay", "columns", "mode", "selected", "allow-selection", "onToggleSelectedStay", "onMouseover"])
    ]),
    _: 1
  }, 8, ["is-loading", "available-columns", "pmsi-type", "mode", "automation-type", "stay-list", "order", "reverse-order"]))
}
}

})