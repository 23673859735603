import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-12 col-lg-4" }
const _hoisted_3 = {
  key: 0,
  class: "row px-2 py-2 align-items-center",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_4 = { class: "col-auto px-2" }
const _hoisted_5 = { class: "col-auto px-2" }
const _hoisted_6 = { class: "col-12 col-lg-4 my-3 my-lg-0 text-lg-center" }
const _hoisted_7 = { class: "col-12 col-lg-4 my-3 my-lg-0 text-lg-center" }
const _hoisted_8 = {
  key: 0,
  class: "col-12 my-3"
}

import { DatePicker, SancareMultiselect } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { computed } from 'vue'

import { statusLabels } from '@/misc/constants'
import { HospitalizationTypeFullName, HospitalizationTypeIcon } from '@/misc/constants'
import { useStore } from '@/store'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayListCriteriaParameters',
  props: {
    mode: {},
    pmsiType: {}
  },
  emits: ['edit', 'set-parameter'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()



const emit = __emit

const params = computed(() => store.state.stayList.stayListParams)
const earlyLimit = computed(() => store.state.settings.earlyLimit)
const lateLimit = computed(() => store.state.settings.lateLimit)
const medicalUnits = computed(() => store.state.settings.medicalUnits)
const selectedMedicalUnits = computed(() => store.state.settings.selectedMedicalUnits || [])
const medicalUnitsRequest = computed(() => store.state.medicalUnitFilter.medicalUnitsRequest)
const multicheckStatus = computed(() => createMulticheckOptions(statusLabels))
const multicheckHospitalizationType = computed(() => createMulticheckOptions(
  HospitalizationTypeFullName,
  true,
  HospitalizationTypeIcon
))

const createMulticheckOptions = (
  object: Record<string | number, string>,
  convertValueToNumber: boolean = false,
  icon?: Record<string, string>
) => {
  return Object.entries(object).map(([key, text]) => ({
    value: convertValueToNumber ? Number(key) : key,
    label: text + (icon ? ` ${icon[key]}` : '')
  }))
}

function setEarlylimit(limit: Date | null) {
  store.commit('setEarlyLimit', limit)
  emit('edit')
  store.dispatch('refreshStayList', { resetStaySelection: true })
  store.dispatch('refreshStayListUrl', {})
}

function setLateLimit(limit: Date | null) {
  store.commit('setLateLimit', limit)
  emit('edit')
  store.dispatch('refreshStayList', { resetStaySelection: true })
  store.dispatch('refreshStayListUrl', {})
}

function updateMulticheck(
  newMulticheckValues: (string | number)[],
  labels: Record<string | number, string>,
  paramName: string
) {
  if (newMulticheckValues === null || newMulticheckValues.length === 0) {
    const keys = Object.keys(labels)
    const shouldBeNumbers = params.value[paramName]?.every((val: string | number) => typeof val === 'number')
    newMulticheckValues = shouldBeNumbers
      ? keys.map((key) => Number(key))
      : keys
  }
  if (_.isEqual(params.value[paramName], newMulticheckValues)) {
    return
  }
  emit('set-parameter', paramName, newMulticheckValues)
}

function updateMedicalUnits(medicalUnitList: number[]) {
  store.commit('setSelectedMedicalUnits', medicalUnitList)
  store.dispatch('refreshStayList', { resetStaySelection: false })
  store.dispatch('refreshStayListUrl', {})
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row px-3 py-3 align-items-end" }, [
      _createElementVNode("h2", null, "Paramètres")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (params.value.improveRecallMode === false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-auto px-2" }, " Du ", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(DatePicker), {
                  "model-value": earlyLimit.value,
                  "onUpdate:modelValue": setEarlylimit
                }, null, 8, ["model-value"])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-auto px-1" }, " au ", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(DatePicker), {
                  "model-value": lateLimit.value,
                  "onUpdate:modelValue": setLateLimit
                }, null, 8, ["model-value"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.mode !== _unref(ModeEnum).AUTOMATION && params.value.improveRecallMode === false)
          ? (_openBlock(), _createBlock(_unref(SancareMultiselect), {
              key: 0,
              title: 'Unités médicales',
              "selected-items": selectedMedicalUnits.value,
              items: medicalUnits.value,
              "is-ready": !medicalUnitsRequest.value.fetching,
              "none-label": 'Toutes',
              "all-label": 'Toutes',
              onUpdateSelection: updateMedicalUnits
            }, null, 8, ["selected-items", "items", "is-ready"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(SancareMultiselect), {
          title: 'Statuts',
          "selected-items": params.value.showStatus,
          items: multicheckStatus.value,
          onUpdateSelection: _cache[0] || (_cache[0] = (newMulticheckStatus) => updateMulticheck(newMulticheckStatus, _unref(statusLabels), 'showStatus'))
        }, null, 8, ["selected-items", "items"])
      ]),
      (_ctx.pmsiType === _unref(PmsiTypeEnum).SSR)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_unref(SancareMultiselect), {
              title: 'Types d\'hospitalisation',
              "selected-items": params.value.hospitalizationType,
              items: multicheckHospitalizationType.value,
              onUpdateSelection: _cache[1] || (_cache[1] = (newMulticheckHospitalizationType) =>
          updateMulticheck(newMulticheckHospitalizationType, _unref(HospitalizationTypeFullName), 'hospitalizationType')
        )
            }, null, 8, ["selected-items", "items"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})