import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "stay-edition-detail" }
const _hoisted_2 = { class: "row p-1" }
const _hoisted_3 = { class: "col-auto badge badge-primary" }
const _hoisted_4 = { class: "row p-1" }
const _hoisted_5 = { class: "col-auto badge badge-primary" }
const _hoisted_6 = { class: "row p-1" }
const _hoisted_7 = { class: "col-auto badge badge-primary" }
const _hoisted_8 = {
  key: 0,
  class: "row p-1"
}
const _hoisted_9 = { class: "col-auto badge badge-primary" }
const _hoisted_10 = {
  key: 1,
  class: "row p-1"
}
const _hoisted_11 = { class: "col-auto badge badge-primary" }
const _hoisted_12 = { class: "row p-1" }
const _hoisted_13 = { class: "col-auto badge badge-primary" }

import _ from 'lodash'

import { StayEdition } from '@/maintenance/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayEditionDetail',
  props: {
    stayEdition: {}
  },
  setup(__props: any) {

const props = __props
function isEmpty(arr: string[]): boolean {
  return _.isEmpty(arr) || (!_.isEmpty(arr) && _.isEmpty(arr[0].trim()))
}
function formatDiagnosis(diagnosis: string[]): string {
  if (isEmpty(diagnosis)) {
    return 'Aucun diagnostic / Diagnostic supprimé'
  }

  return diagnosis.join(', ')
}
function formatActs(acts: string[]): string {
  if (isEmpty(acts)) {
    return 'Aucun acte / Acte supprimé'
  }

  return acts.join(', ')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.stayEdition.events, (stayEvent) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stayEvent.creation_date + stayEvent.author + stayEvent.type,
        class: "card"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-2" }, " Auteur ", -1)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(stayEvent.author), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-2" }, " Mode ", -1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(stayEvent.mode), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-2" }, " Type ", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(stayEvent.type), 1)
        ]),
        (!stayEvent.type.includes('_act'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-2" }, " Diagnostics ", -1)),
              _createElementVNode("div", _hoisted_9, _toDisplayString(formatDiagnosis(stayEvent.target)), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-2" }, " Actes ", -1)),
              _createElementVNode("div", _hoisted_11, _toDisplayString(formatActs(stayEvent.target)), 1)
            ])),
        _createElementVNode("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-2" }, " Date ", -1)),
          _createElementVNode("div", _hoisted_13, _toDisplayString(stayEvent.creation_date), 1)
        ])
      ]))
    }), 128))
  ]))
}
}

})