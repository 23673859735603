import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row my-5" }
const _hoisted_2 = { class: "col-auto p-0 pt-5" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col-auto p-0 pt-5" }

import { computed, ComputedRef } from 'vue'

import { CursorHelper, getWeekNbText } from '@/stay-displayer/ssr/utils'
import { DependenciesLabels, DependenciesSections, Rhs } from '@/store/modules/stay/ssr/types'

type CellData = {
  value: string | number
  class: string
}
type RowData = {
  label: string
  class: string
  cells: CellData[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsTable',
  props: {
    rhsList: {}
  },
  setup(__props: any) {

const props = __props

const rhsNavigator = new CursorHelper<Rhs>(props.rhsList, 14)

function getCells(values: (string | number)[], empty: string = null): CellData[] {
  let lastVal = null
  return values.map((currentVal) => {
    if (!currentVal) {
      return {
        value: empty,
        class: 'empty',
      }
    }
    const result = {
      value: currentVal,
      class: lastVal !== null && currentVal !== lastVal ? 'deviation' : null
    }
    lastVal = currentVal
    return result
  })
}

function sumDep(rhs: Rhs, section: DependenciesSections): number {
  let sum = 0
  for (const dep of Object.keys(DependenciesLabels[section])) {
    sum += rhs[dep] ?? 0
  }
  return sum
}

const gmeList = computed(() => rhsNavigator.displayedElements.value.map((rhs) => rhs.currentModeDiagnosisGroup.groupingResult.gme?.reference ?? null))
const cmGnList = computed(() => getCells(gmeList.value.map((gme) => gme?.substring(0, 4), '-')))
// todo https://app.clickup.com/t/2628858/WEB-2388
// const csarrList = computed(() => getCells(rhsNavigator.displayedElements.value.map(rhs => 'todo')))
const physicalList = computed(() => getCells(rhsNavigator.displayedElements.value.map((rhs) => sumDep(rhs, DependenciesSections.PHYSICAL))))
const cognitiveList = computed(() => getCells(rhsNavigator.displayedElements.value.map((rhs) => sumDep(rhs, DependenciesSections.COGNITIVE))))
const severityList = computed(() => getCells(gmeList.value.map((gme) => gme?.substring(gme.length - 1), '-')))
// todo https://app.clickup.com/t/2628858/WEB-2381
// const statusList = computed(() => getCells(rhsNavigator.displayedElements.value.map(rhs => 'todo')))
const tableData = computed(() => [
  {
    label: 'CM GN',
    class: 'nosology',
    cells: cmGnList.value,
  },
  // {
  //   label: 'Score CSARR',
  //   class: 'rehabilitation',
  //   cells: csarrList.value,
  // },
  {
    label: 'Physique',
    class: 'weight',
    cells: physicalList.value,
  },
  {
    label: 'Cognitive',
    class: 'weight',
    cells: cognitiveList.value,
  },
  // {
  //   label: 'Niveau sévérité',
  //   class: 'severity',
  //   cells: severityList.value,
  // },
  // {
  //   label: 'Statut',
  //   class: null',
  //   cells: statusList.value,
  // }
]) as ComputedRef<RowData[]>

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["display-5", {cp: _unref(rhsNavigator).canMoveDown.value}]),
        style: _normalizeStyle({opacity: !_unref(rhsNavigator).canMove.value ? 0 : _unref(rhsNavigator).canMoveDown.value ? 1 : 0.3}),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(rhsNavigator).moveCursor(-4)), ["prevent"]))
      }, "←", 6)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", {
        id: "rhs-table",
        class: _normalizeClass(_unref(rhsNavigator).canMove.value ? 'w-100' : null)
      }, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("th", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rhsNavigator).displayedElements.value, (rhs) => {
              return (_openBlock(), _createElementBlock("th", {
                key: rhs.id,
                class: "font-weight-normal"
              }, [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(_unref(getWeekNbText)(rhs, true, true)), 1)
                ])), [
                  [_directive_tooltip, rhs.startDate.format('DD/MM/YYYY')]
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableData.value, (row) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.label
            }, [
              _createElementVNode("td", {
                class: _normalizeClass(["text-right", null === row.class ? null : 'color-'+row.class])
              }, _toDisplayString(row.label), 3),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell, idx) => {
                return (_openBlock(), _createElementBlock("td", { key: idx }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(cell?.class ?? null)
                  }, _toDisplayString(cell?.value ?? '-'), 3)
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", {
        style: _normalizeStyle({opacity: !_unref(rhsNavigator).canMove.value ? 0 : (_unref(rhsNavigator).canMoveUp.value ? 1 : 0.3)}),
        class: _normalizeClass(["display-5", {cp: _unref(rhsNavigator).canMoveUp.value}]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(rhsNavigator).moveCursor(4)), ["prevent"]))
      }, "→", 6)
    ])
  ]))
}
}

})