import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader loader-lg loader-center mb-4"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "row m-3" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "col" }

import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'
import { computed, onMounted, onUpdated } from 'vue'

import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayStatusListPage',
  props: {
    rssId: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const stayStatusList = computed(() => {
  return store.state.maintenance.stayStatusList ? _.orderBy(_.filter(store.state.maintenance.stayStatusList, 'lastSeen'), 'lastSeen', 'desc') : []
})

const stayStatusListRequest = computed(() => store.state.maintenance.requests.getStayStatusListRequest)

onMounted(refresh)
onUpdated(refresh)

function refresh() {
  if ((stayStatusListRequest.value.fetching || stayStatusListRequest.value.ok)) {
    return
  }
  store.dispatch('getStayStatusList', { rssId: props.rssId })
}

function getLastSeenDate(stayStatus) {
  return `${dayjs(stayStatus.lastSeen).format('L')} à ${dayjs(stayStatus.lastSeen).format('LTS')}`
}

function getStatusLabel(stayStatus) {
  return _.lowerFirst(stayStatus.statusValueLabel)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (stayStatusListRequest.value.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (!stayStatusListRequest.value.ok || stayStatusListRequest.value.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Erreur: " + _toDisplayString(stayStatusListRequest.value.error), 1))
        : (stayStatusList.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Aucun historique à afficher pour l'identifiant RSS " + _toDisplayString(_ctx.rssId), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stayStatusList.value, (stayStatus) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: stayStatus.id,
                  class: "card"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h2", null, _toDisplayString(getLastSeenDate(stayStatus)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, " Séjour " + _toDisplayString(getStatusLabel(stayStatus)) + " le " + _toDisplayString(getLastSeenDate(stayStatus)) + " par " + _toDisplayString(stayStatus.user.firstName && stayStatus.user.lastName ? stayStatus.user.firstName + ' ' + stayStatus.user.lastName : stayStatus.user.username), 1)
                  ])
                ]))
              }), 128))
            ]))
  ]))
}
}

})