import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "badge hosptype-badge" }
const _hoisted_2 = {
  key: 0,
  class: "hosptype-badge__icon"
}

import { computed } from 'vue'

import { HospitalizationTypeFullName, HospitalizationTypeIcon, HospitalizationTypeLabel } from '@/misc/constants'
import { HospitalizationType } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'HospitalizationTypeBadge',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props
const displayedIcon = computed(() => HospitalizationTypeIcon[props.type])
const displayedLabel = computed(() => HospitalizationTypeLabel[props.type])
const displayedFullName = computed(() => `Hospitalisation ${HospitalizationTypeFullName[props.type]}`)


return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
    (displayedIcon.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(displayedIcon.value), 1))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(displayedLabel.value), 1)
  ])), [
    [_directive_tooltip, displayedFullName.value]
  ])
}
}

})