import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "col-12 mb-4 p-3 shadow-sm",
  style: {"border-left":"3px solid"}
}
const _hoisted_2 = { class: "form-group row" }
const _hoisted_3 = { class: "col-sm-10" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "form-group row" }
const _hoisted_6 = { class: "col-sm-10" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex justify-content-between align-items-center"
}
const _hoisted_8 = {
  key: 1,
  class: "loader loader-sm loader-center mx-0 my-1",
  style: {"width":"18px","height":"18px"}
}
const _hoisted_9 = {
  key: 2,
  class: "text-success"
}
const _hoisted_10 = {
  key: 3,
  class: "text-danger"
}
const _hoisted_11 = { class: "form-group row" }
const _hoisted_12 = { class: "col-sm-10" }
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  key: 0,
  class: "form-group row"
}
const _hoisted_15 = { class: "col-sm-10" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "form-group row m-0" }
const _hoisted_18 = { class: "text-center" }

import { reactive, ref, watch } from 'vue'

import { useStore } from '@/store'
import {
  ConnectorFlow,
  ConnectorFlowType,
  connectorFlowVersions,
  connectorsConnexionInputs,
  ConnectorType,
  fileConnectors
} from '@/store/modules/connector-config/types'
import { pmsiTypesLabels } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectorFlowForm',
  props: {
    index: {},
    connectorIndex: {},
    connectorType: {},
    flow: {}
  },
  emits: ['update-flow', 'delete-flow'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit

const store = useStore()
const props = __props

const flowData = reactive({ ...props.flow })
const showDeleteDropdown = ref(false)
const isTestingWriteRight = ref(false)
const pathIsWritable = ref(null)

watch(flowData, function() {
  emit('update-flow', props.index, flowData)
})

function testWriteRight() {
  isTestingWriteRight.value = true
  pathIsWritable.value = null // restore value to null so we don't display any message

  store.dispatch('connectorConfig/testWriteRight', flowData.path).then((response) => {
    isTestingWriteRight.value = false
    pathIsWritable.value = response['writable']
  })
}

function deleteFlow() {
  showDeleteDropdown.value = false
  emit('delete-flow', props.index)
}


return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-sm-2" }, "Type", -1)),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((flowData.type) = $event)),
          class: "form-control"
        }, [
          _cache[7] || (_cache[7] = _createElementVNode("option", { value: null }, " --- Choisissez un type --- ", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ConnectorFlowType), (item) => {
            return (_openBlock(), _createElementBlock("option", {
              key: item,
              value: item
            }, _toDisplayString(item), 9, _hoisted_4))
          }), 128))
        ], 512), [
          [_vModelSelect, flowData.type]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-sm-2" }, "Chemin", -1)),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((flowData.path) = $event)),
          type: "text",
          class: "form-control"
        }, null, 512), [
          [_vModelText, flowData.path]
        ]),
        (_unref(fileConnectors).includes(_ctx.connectorType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (!isTestingWriteRight.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-link btn-sm",
                    onClick: testWriteRight
                  }, " Tester les droits d'écriture "))
                : _createCommentVNode("", true),
              (isTestingWriteRight.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                : _createCommentVNode("", true),
              (pathIsWritable.value === true)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, " Vous avez les droits d'écriture ! "))
                : _createCommentVNode("", true),
              (pathIsWritable.value === false)
                ? (_openBlock(), _createElementBlock("small", _hoisted_10, " Vous n'avez pas les droits d'écriture :/ "))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-sm-2" }, "Schéma", -1)),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((flowData.formatVersion) = $event)),
          class: "form-control"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(connectorFlowVersions)[_ctx.connectorType], (item) => {
            return (_openBlock(), _createElementBlock("option", {
              key: item,
              value: item
            }, _toDisplayString(item), 9, _hoisted_13))
          }), 128))
        ], 512), [
          [_vModelSelect, flowData.formatVersion]
        ])
      ])
    ]),
    (_unref(connectorsConnexionInputs)['pmsiType'].includes(_ctx.connectorType))
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-sm-2" }, "Type de PMSI", -1)),
          _createElementVNode("div", _hoisted_15, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((flowData.pmsiType) = $event)),
              class: "form-control"
            }, [
              _cache[11] || (_cache[11] = _createElementVNode("option", { value: null }, " --- Choisissez un type --- ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pmsiTypesLabels), (label, value) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: value,
                  value: value
                }, _toDisplayString(label), 9, _hoisted_16))
              }), 128))
            ], 512), [
              [_vModelSelect, flowData.pmsiType]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_v_dropdown, {
        placement: "top",
        shown: showDeleteDropdown.value,
        onHide: _cache[6] || (_cache[6] = ($event: any) => (!showDeleteDropdown.value))
      }, {
        popper: _withCtx(() => [
          _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "title-light text-center" }, " Êtes-vous sûr ? ", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("button", {
              class: "btn btn-danger btn-padded",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (deleteFlow()))
            }, " Supprimer ")
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-danger btn-sm",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (showDeleteDropdown.value = true))
          }, " Supprimer le flux ")
        ]),
        _: 1
      }, 8, ["shown"])
    ])
  ]))
}
}

})