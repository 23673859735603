import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "row align-items-center mt-5"
}
const _hoisted_3 = {
  key: 1,
  class: "row mt-1 mb-2"
}
const _hoisted_4 = { class: "col-lg-7 col-md-12" }
const _hoisted_5 = {
  key: 2,
  class: "row"
}
const _hoisted_6 = { class: "col-lg-7 col-md-12" }
const _hoisted_7 = { class: "col-lg-5 col-md-12" }
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = { class: "col p-0" }
const _hoisted_10 = {
  key: 1,
  class: "row"
}
const _hoisted_11 = { class: "col p-0" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-lg-6 col-md-12 p-0 pr-1" }
const _hoisted_14 = { class: "col-lg-6 col-md-12 p-0 pl-1" }
const _hoisted_15 = { class: "aligned-row mt-2" }
const _hoisted_16 = {
  type: "button",
  class: "col-12 btn btn-sm"
}

import { computed, onMounted, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'

import DocumentViewer from '@/stay-displayer/DocumentViewer.vue'
import AsideStayGme from '@/stay-displayer/grouping/ssr/AsideStayGme.vue'
import AsideRhsGme from '@/stay-displayer/grouping/ssr/rhs/AsideRhsGme.vue'
import ReportSelector from '@/stay-displayer/ReportSelector.vue'
import { asyncTextProcessing, refreshRhs, refreshStay } from '@/stay-displayer/ssr/dataRefreshers'
import GeneralStayData from '@/stay-displayer/ssr/GeneralStayData.vue'
import MedicalActs from '@/stay-displayer/ssr/rhs/medical-act/MedicalActs.vue'
import RehabilitationActs from '@/stay-displayer/ssr/rhs/rehabilitation-act/RehabilitationActs.vue'
import RhsDependencies from '@/stay-displayer/ssr/rhs/RhsDependencies.vue'
import RhsDiagnoses from '@/stay-displayer/ssr/rhs/RhsDiagnoses.vue'
import RhsLoadingError from '@/stay-displayer/ssr/rhs/RhsLoadingError.vue'
import { useStore } from '@/store'
import {
  HospitalizationType,
  RhsDashboardButtonLabel,
  RhsDashboardClass,
  RhsDashboardType,
  RhsLoadingErrorReason,
  SsrLoadingErrorReason
} from '@/store/modules/stay/ssr/types'
import { formattedHealthData } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsPage',
  setup(__props) {

const route = useRoute()
const store = useStore()

const ssrId = computed(() => Number(route.params.ssrId))
const rhsId = computed(() => Number(route.params.rhsId))
const mode = computed(() => store.state.settings.pmsi.mode)

const currentStay = computed(() => store.state.ssrStay.currentStay)
const currentDocument = computed(() => store.state.ssrStay.mainContent)
const justificationSelection = computed(() => store.state.ssrStay.justificationSelection)
const justificationShouldScroll = computed(() => store.state.ssrStay.justificationShouldScroll)
const selectedChunks = computed(() => {
  if (!justificationSelection.value) {
    return null
  }
  // todo: keyword from current search, see: https://app.clickup.com/t/86c0mw50c

  // for now we cannot have more than one search, see: https://app.clickup.com/t/86c0mvn18
  const staySavedSearch = currentStay.value.staySavedSearches.find((s) => s.id === justificationSelection.value.savedSearchIds[0])
  return staySavedSearch?.chunks
})
const currentRhs = computed(() => store.state.ssrStay.currentRhs)
const previousRhs = computed(() => {
  let previousRhs = null
  for (const rhs of store.state.ssrStay.currentStay.rhsList) {
    if (rhs.id === rhsId.value) {
      return previousRhs
    }
    previousRhs = rhs
  }
  return previousRhs
})
const predictedDiagnoses = computed(() => currentRhs.value.predictedLabels.filter((predictedLabel) => predictedLabel.diagnosis))
const isGroupingResultRefreshing = computed(() => store.state.ssrStay.isGroupingResultRefreshing)

const healthData = computed(() => {
  return formattedHealthData(currentStay.value)
})
const initialRhsModeGroupingResult = computed(() => currentRhs.value.initialModeDiagnosisGroup.groupingResult)
const currentRhsModeGroupingResult = computed(() => currentRhs.value.currentModeDiagnosisGroup.groupingResult)
const isCurrentGmeSet = computed(() => currentRhsModeGroupingResult.value.gme !== null)
const displayedRhsGroupingResult = computed(() => isCurrentGmeSet.value
  ? currentRhsModeGroupingResult.value : initialRhsModeGroupingResult.value
)
const rhsMoneyGap = computed(() => isCurrentGmeSet.value
  ? currentRhsModeGroupingResult.value.value - initialRhsModeGroupingResult.value.value
  : 0)
const stayMoneyGap = computed(() => isCurrentStayGmeSet.value
  ? currentStay.value.standardModeGroupingResult.value - currentStay.value.firstStandardModeGroupingResult.value
  : 0)

const initialStayModeGroupingResult = computed(() => currentStay.value.firstStandardModeGroupingResult)
const currentStayModeGroupingResult = computed(() => currentStay.value.standardModeGroupingResult)
const isCurrentStayGmeSet = computed(() => currentStayModeGroupingResult.value.gme !== null)
const displayedStayGroupingResult = computed(() => isCurrentStayGmeSet.value
  ? currentStayModeGroupingResult.value : initialStayModeGroupingResult.value
)

const refreshErrorReasons = { ...RhsLoadingErrorReason, ...SsrLoadingErrorReason }
const refreshErrorReason: Ref<RhsLoadingErrorReason | SsrLoadingErrorReason | null> = ref(null)
const isRefreshed: Ref<boolean> = ref(false)
const isLoading: Ref<boolean> = ref(false)

async function refresh() {
  refreshErrorReason.value = null
  isRefreshed.value = false

  await refreshStay(ssrId.value, store)
  asyncTextProcessing(store)
  if (!currentStay.value.rhsList.some((rhs) => rhs.id === rhsId.value)) {
    throw new Error(RhsLoadingErrorReason.RHS_UNKNOWN)
  }
  await refreshRhs(rhsId.value, store)

  isRefreshed.value = true
}

onMounted(async () => {
  isLoading.value = true
  try {
    await refresh()
  } catch (e) {
    refreshErrorReason.value = Object.values(refreshErrorReasons).includes(e.message) ? e.message : null
  }
  isLoading.value = false
})

function disableJustificationScrolling() {
  store.commit('ssrStay/setJustificationShouldScroll', false)
}
function setMainContent(mainContent) {
  store.commit('ssrStay/setMainContent', { type: mainContent.type, targetId: mainContent.targetId, drugEventPrestationType: mainContent.drugEventPrestationType })
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "col-sm-12" }, [
            _createElementVNode("div", { class: "loader loader-md loader-center mb-2" }),
            _createElementVNode("div", { class: "text-center font-italic" }, " Chargement des informations du RHS... ")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (isRefreshed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: { name: 'ssr-stay', params: { ssrId: currentStay.value.id }, query: {mode: mode.value} }
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" ← Retour au séjour ")
              ])),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    (isRefreshed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(GeneralStayData, {
              stay: currentStay.value,
              "displayed-rhs": currentRhs.value
            }, null, 8, ["stay", "displayed-rhs"]),
            _createVNode(ReportSelector, {
              "health-data": healthData.value,
              "selected-chunks": selectedChunks.value,
              "main-content": currentDocument.value,
              onSetMainContent: setMainContent
            }, null, 8, ["health-data", "selected-chunks", "main-content"]),
            _createVNode(DocumentViewer, {
              "current-stay": currentStay.value,
              "current-document": currentDocument.value,
              "displayed-rum-idx": 0,
              selection: justificationSelection.value,
              "rum-predicted-labels": [],
              "should-scroll": justificationShouldScroll.value,
              "enable-false-justification-features": false,
              onDisableJustificationScrolling: disableJustificationScrolling
            }, null, 8, ["current-stay", "current-document", "selection", "should-scroll"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (currentStay.value.hospitalizationType !== _unref(HospitalizationType).COMPLETE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(AsideRhsGme, {
                      "grouping-result": displayedRhsGroupingResult.value,
                      "is-refreshing": isGroupingResultRefreshing.value,
                      "money-gap": rhsMoneyGap.value,
                      "hospitalization-type": currentStay.value.hospitalizationType,
                      "stay-grouping-result": displayedStayGroupingResult.value
                    }, null, 8, ["grouping-result", "is-refreshing", "money-gap", "hospitalization-type", "stay-grouping-result"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(AsideStayGme, {
                      "grouping-result": displayedStayGroupingResult.value,
                      "money-gap": stayMoneyGap.value,
                      "hospitalization-type": currentStay.value.hospitalizationType
                    }, null, 8, ["grouping-result", "money-gap", "hospitalization-type"])
                  ])
                ])),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(RhsDiagnoses, {
                  "rhs-id": currentRhs.value.id,
                  "rhs-end-date": currentRhs.value.endDate,
                  "current-mode-diagnosis-group": currentRhs.value.currentModeDiagnosisGroup,
                  "predicted-diagnoses": predictedDiagnoses.value,
                  "health-data": healthData.value,
                  "saved-search-intersection-chunks": currentStay.value.savedSearchIntersectionChunks ?? [],
                  "stay-saved-searches": currentStay.value.staySavedSearches
                }, null, 8, ["rhs-id", "rhs-end-date", "current-mode-diagnosis-group", "predicted-diagnoses", "health-data", "saved-search-intersection-chunks", "stay-saved-searches"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(RhsDependencies, {
                  "current-rhs": currentRhs.value,
                  "previous-rhs": previousRhs.value
                }, null, 8, ["current-rhs", "previous-rhs"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["dashboard mb-2", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CCAM]])
                }, [
                  _createVNode(MedicalActs, {
                    "coded-acts": currentRhs.value.codedMedicalActs,
                    rhs: currentRhs.value
                  }, null, 8, ["coded-acts", "rhs"])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["dashboard", _unref(RhsDashboardClass)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CSARR]])
                }, [
                  _createVNode(RehabilitationActs, {
                    "coded-rehabilitation-acts": currentRhs.value.codedRehabilitationActs
                  }, null, 8, ["coded-rehabilitation-acts"]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("button", _hoisted_16, _toDisplayString(_unref(RhsDashboardButtonLabel)[_unref(RhsDashboardType).RHS_DASHBOARD_ACT_CSARR]), 1)
                  ])
                ], 2)
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(RhsLoadingError, {
          key: 3,
          reason: refreshErrorReason.value,
          "ssr-id": ssrId.value,
          "rhs-id": rhsId.value
        }, null, 8, ["reason", "ssr-id", "rhs-id"]))
  ]))
}
}

})