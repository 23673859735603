import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-auto p-0 pt-5" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "nav-header mb-2 text-center" }
const _hoisted_6 = { class: "border" }
const _hoisted_7 = { class: "col-auto p-0 pt-5" }

import { computed } from 'vue'

import RhsSummary from '@/stay-displayer/ssr/rhs/RhsSummary.vue'
import { CursorHelper, getWeekNbText } from '@/stay-displayer/ssr/utils'
import { useStore } from '@/store'
import { HospitalizationType, Rhs } from '@/store/modules/stay/ssr/types'

export default /*@__PURE__*/_defineComponent({
  __name: 'RhsSummaries',
  props: {
    currentStayId: {},
    hospitalizationType: {},
    rhsList: {}
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const rhsNavigator = new CursorHelper<Rhs>(props.rhsList, 4)
const mode = computed(() => store.state.settings.pmsi.mode)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["display-5", {cp: _unref(rhsNavigator).canMoveDown.value}]),
        style: _normalizeStyle({opacity: !_unref(rhsNavigator).canMove.value ? 0 : (_unref(rhsNavigator).canMoveDown.value ? 1 : 0.3)}),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(rhsNavigator).moveCursor(-1)), ["prevent"]))
      }, "←", 6)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rhsNavigator).displayedElements.value, (rhs) => {
          return (_openBlock(), _createElementBlock("div", {
            key: rhs.id,
            class: "col-3"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: { name: 'rhs', params: {ssrId: _ctx.currentStayId, rhsId: rhs.id}, query: {mode: mode.value} }
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("span", null, [
                    _createTextVNode(_toDisplayString(_unref(getWeekNbText)(rhs, true)), 1)
                  ])), [
                    [_directive_tooltip, rhs.startDate.format('DD/MM/YYYY')]
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(RhsSummary, {
                rhs: rhs,
                "hospitalization-type": _ctx.hospitalizationType
              }, null, 8, ["rhs", "hospitalization-type"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", {
        style: _normalizeStyle({opacity: !_unref(rhsNavigator).canMove.value ? 0 : (_unref(rhsNavigator).canMoveUp.value ? 1 : 0.3)}),
        class: _normalizeClass(["display-5", {cp: _unref(rhsNavigator).canMoveUp.value}]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(rhsNavigator).moveCursor(1)), ["prevent"]))
      }, "→", 6)
    ])
  ]))
}
}

})