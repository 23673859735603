import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed } from 'vue'

import { Criterion } from '@/store/modules/filters/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleValueFilter',
  props: {
    filter: {},
    canEdit: { type: Boolean, default: false }
  },
  emits: ["remove-criteria"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props

const displayedContent = computed(() => props.filter.content)
const hasWordDistance = computed(() => (props.filter.modifiers.wordDistance ?? 0) > 0)

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.filter.modifiers.includeAntecedents)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_unref(SancareOcticon), {
            name: "eye",
            width: 14,
            height: 11
          })
        ])), [
          [_directive_tooltip, {content: 'Recherche également dans les antécédents', offset: [5, 5], placement: 'bottom'}]
        ])
      : _createCommentVNode("", true),
    (_ctx.filter.modifiers.includeFamilyAntecedents)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_unref(SancareOcticon), {
            name: "organization",
            width: 14,
            height: 11
          })
        ])), [
          [_directive_tooltip, {content: 'Recherche également dans les antécédents familiaux', offset: [5, 5], placement: 'bottom'}]
        ])
      : _createCommentVNode("", true),
    (hasWordDistance.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " [Intervalle: " + _toDisplayString(_ctx.filter.modifiers.wordDistance) + "] ", 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(displayedContent.value), 1),
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("span", {
          key: 3,
          class: "criteria-remove",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove-criteria', _ctx.filter)))
        }, [
          _createVNode(_unref(SancareOcticon), {
            name: "x",
            width: 14,
            height: 11
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})