import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grouping-error-list"
}
const _hoisted_2 = { class: "grouping-error-list__wrapper flex-grow-1" }
const _hoisted_3 = { class: "list-group list-group-flush" }
const _hoisted_4 = { class: "grouping-error-list__description" }

import { computed } from 'vue'

import { GroupingError } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupingErrorList',
  props: {
    errors: {}
  },
  setup(__props: any) {

const props = __props

const isDisplayed = computed(() => props.errors.length > 0)

return (_ctx: any,_cache: any) => {
  return (isDisplayed.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, "Erreur" + _toDisplayString(_ctx.errors.length > 1 ? 's' : '') + " de groupage" + _toDisplayString(_ctx.errors.length > 1 ? ` (${_ctx.errors.length})` : ''), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
              return (_openBlock(), _createElementBlock("li", {
                key: error.code,
                class: "list-group-item"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["grouping-error-list__code", { 'grouping-error-list__code-critical': error.critical }])
                }, _toDisplayString(error.code), 3),
                _createElementVNode("div", _hoisted_4, _toDisplayString(error.description), 1)
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})