import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ActionButton } from '@sancare/ui-frontend-commons'
import { ButtonState } from '@sancare/ui-frontend-commons/src/components/types'
import { computed, Ref, ref } from 'vue'

import { useStore } from '@/store'
import { StayStatusEnum } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ValidationButton',
  props: {
    disabled: { type: Boolean }
  },
  setup(__props: any) {



const store = useStore()
const isStayValidated = computed<boolean>(() => store.state.ssrStay.currentStay.status === StayStatusEnum.VALIDATED)

const buttonStatus: Ref<ButtonState | null> = ref(null)
const buttonLabel = computed<string>(() => isStayValidated.value ? 'Dé-valider le séjour' : 'Valider le séjour')

async function validateStay() {
  await updateStayStatus(StayStatusEnum.VALIDATED)
}

async function unvalidateStay() {
  await updateStayStatus(StayStatusEnum.UNVALIDATED)
}

async function updateStayStatus(status: StayStatusEnum) {
  buttonStatus.value = ButtonState.LOADING

  try {
    await sendStayStatusUpdateRequest(status)
  } catch (e) {
    buttonStatus.value = ButtonState.FAILED
    return
  }

  buttonStatus.value = ButtonState.SUCCESSFUL
}

async function sendStayStatusUpdateRequest(status: StayStatusEnum) {
  await store.dispatch('ssrStay/updateStayStatus', {
    stayId: store.state.ssrStay.currentStay.id,
    status: status
  })

  const request = store.state.ssrStay.requests.updateStayStatus
  if (request.error) {
    throw new Error(request.error)
  }
}

// TODO: Display error message

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ActionButton), {
    class: "btn-grouping-validation",
    label: buttonLabel.value,
    disabled: _ctx.disabled,
    status: buttonStatus.value,
    title: _ctx.disabled ? 'La fonction de groupage a retourné des erreurs, veuillez les résoudre afin de valider le séjour': '',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (isStayValidated.value ? unvalidateStay() : validateStay()))
  }, null, 8, ["label", "disabled", "status", "title"]))
}
}

})