import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "table-container text-center" }
const _hoisted_2 = { class: "stay-list-table stripped" }
const _hoisted_3 = { class: "align-items-center" }
const _hoisted_4 = ["name", "onClick"]
const _hoisted_5 = { class: "column-content" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  class: "order-button",
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "12",
  viewBox: "0 0 17 20",
  version: "1.1"
}
const _hoisted_9 = ["fill"]
const _hoisted_10 = ["fill"]
const _hoisted_11 = { class: "column-icon" }
const _hoisted_12 = { class: "column-content" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["colspan"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "stay-list-line" }
const _hoisted_18 = ["colspan"]

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, onMounted } from 'vue'

import { filterAvailableColumns, filterDisplayedColumns } from '@/stay-list/stay-list-table/helpers/columnFilters'
import { ListStay, StayListLineSlotProps } from '@/stay-list/stay-list-table/types'
import { useStore } from '@/store'
import { ModeEnum, PmsiTypeEnum, StayListColumn, StayListColumns } from '@/store/modules/settings/types'

import StayListColumnSettings from './StayListColumnSettings.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayListTable',
  props: {
    stayList: {},
    availableColumns: {},
    mode: {},
    pmsiType: { default: PmsiTypeEnum.MCO },
    automationType: { default: null },
    order: { default: 'id' },
    reverseOrder: { type: Boolean, default: true },
    isLoading: { type: Boolean }
  },
  emits: ["stay-line-highlight", "order-update"],
  setup(__props: any, { emit: __emit }) {

const store = useStore()

const props = __props


const pmsiCountry = computed(() => store.state.settings.pmsi.country)
const userSettings = computed(() => store.state.settings.userSettings)

const canSelectStay = computed(() => !store.state.stayList.stayListParams.searchCreation
  && store.state.stayList.stayListParams.searchEdition === -1)

const displayableColumns = computed(() => filterAvailableColumns(
  props.availableColumns,
  {
    pmsiCountry: pmsiCountry.value,
    mode: props.mode
  }
))
const displayedColumns = computed<StayListColumns>(() => filterDisplayedColumns(displayableColumns.value, userSettings.value.stayListColumns))

onMounted(() => {
  store.commit('resetStaySelection')
})

function setColumns(columns: StayListColumns) {
  const settings = { ...userSettings.value }
  settings.stayListColumns = columns
  store.dispatch('setUserSettings', settings)
}

function toggleSelectedStay(stayId: number) {
  store.dispatch('toggleSelectedStay', stayId)
}
const selectedStayIds = computed(() => store.state.stayList.selectedStayIds)

const emit = __emit
function updateOrder(order: string) {
  emit('order-update', order)
}
function coloredButtonIndex(columnInfo: StayListColumn): number {
  if (columnInfo.order !== props.order) {
    return -1
  }

  return ((!props.reverseOrder && columnInfo.invertedOrder)
    || (props.reverseOrder && !columnInfo.invertedOrder)) ? 1 : 0
}

/**
 * Use this event in a line's handler when current stay needs to be sent to parent for processing
 * TODO: Better typing (see https://team-git.sancare.net/dev/ui/-/merge_requests/2792#note_156850)
 */
function onStayLineHighlight(stay: TStay) {
  emit('stay-line-highlight', stay)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedColumns.value, (columnInfo, columnName) => {
            return (_openBlock(), _createElementBlock("th", {
              key: 'column_'+columnName,
              name: 'column_'+columnName,
              class: _normalizeClass({ 'column-icon': columnInfo.icon }),
              onClick: ($event: any) => (updateOrder(columnInfo.order))
            }, [
              _createElementVNode("div", _hoisted_5, [
                (columnInfo.icon)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_unref(SancareOcticon), {
                        name: columnInfo.icon,
                        width: 14,
                        height: 14
                      }, null, 8, ["name"])
                    ]))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(columnInfo.isTranslated ? _ctx.$t(columnInfo.label) : columnInfo.label) + " ", 1),
                (columnInfo.order)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_8, [
                        _createElementVNode("path", {
                          fill: coloredButtonIndex(columnInfo) === 0 ? 'white' : 'darkgrey',
                          d: "M0 8 l 7 -7 7 7 z"
                        }, null, 8, _hoisted_9),
                        _createElementVNode("path", {
                          fill: coloredButtonIndex(columnInfo) === 1 ? 'white' : 'darkgrey',
                          d: "M0 13 l 7 7 7 -7 z"
                        }, null, 8, _hoisted_10)
                      ]))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_4))
          }), 128)),
          _createElementVNode("th", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(StayListColumnSettings, {
                id: "column-settings",
                mode: _ctx.mode,
                "user-columns": Object.keys(displayedColumns.value),
                "selectable-columns": displayableColumns.value,
                onSetColumns: setColumns
              }, null, 8, ["mode", "user-columns", "selectable-columns"])
            ])
          ])
        ])
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: Object.keys(displayedColumns.value).length + 2
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("div", {
                  name: "stayList_loader",
                  class: "loader loader-lg loader-center"
                }, null, -1)
              ]), 8, _hoisted_14)
            ])
          ]))
        : (_ctx.stayList && _ctx.stayList.length)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stayList, (stay) => {
                return _renderSlot(_ctx.$slots, "line", {
                  key: 'stayList_Stay_'+stay.id,
                  stay: stay,
                  columns: displayedColumns.value,
                  selected: _unref(store).getters.isStaySelected(stay.id),
                  canSelectStay: canSelectStay.value,
                  toggleSelectedStay: toggleSelectedStay,
                  onStayLineHighlight: onStayLineHighlight
                })
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("tbody", _hoisted_16, [
              _createElementVNode("tr", _hoisted_17, [
                _createElementVNode("td", {
                  colspan: Object.keys(displayedColumns.value).length + 1
                }, " Aucun séjour correspondant pour la période donnée ", 8, _hoisted_18)
              ])
            ]))
    ])
  ]))
}
}

})