import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-100",
  style: {"background":"#F8F9FA","padding":"15px","border-radius":"10px"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerJSON',
  props: {
    data: {}
  },
  setup(__props: any) {


const props = __props

function pretify(data) {
  try {
    return JSON.stringify(JSON.parse(data), null, 2)
  } catch (error) {
    return data
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("pre", null, _toDisplayString(pretify(props.data)), 1)
  ]))
}
}

})