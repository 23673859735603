import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card aside-ghm" }
const _hoisted_3 = {
  key: 0,
  class: "card-body"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 1,
  class: "col-auto ml-auto"
}
const _hoisted_6 = {
  key: 0,
  class: "row no-gutters align-items-start"
}
const _hoisted_7 = { class: "col" }
const _hoisted_8 = {
  key: 0,
  class: "col-auto ml-auto mr-3"
}
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = {
  key: 1,
  class: "row no-gutters align-items-end"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = {
  key: 2,
  class: "row no-gutters align-items-end"
}

import { computed } from 'vue'

import PredictionIcon from '@/common/PredictionIcon.vue'
import GroupingCost from '@/stay-displayer/grouping/GroupingCost.vue'
import GroupingField from '@/stay-displayer/grouping/GroupingField.vue'
import GroupingMoneyGap from '@/stay-displayer/grouping/GroupingMoneyGap.vue'
import GroupingErrorMessage from '@/stay-displayer/grouping/mco/GroupingErrorMessage.vue'
import { ModeEnum } from '@/store/modules/settings/types'
import { Ghm } from '@/store/modules/stay/mco/types'
import { GroupingError } from '@/store/modules/stay/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AsideGhm',
  props: {
    mode: {},
    stayDuration: {},
    ghm: { default: null },
    ghs: { default: null },
    value: {},
    moneyGap: { default: 0 },
    groupingError: { default: null },
    isAutomatable: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const hasGhmError = computed(() => props.ghm && props.ghm.reference.startsWith('90Z'))

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (hasGhmError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (hasGhmError.value)
                ? (_openBlock(), _createElementBlock("h1", {
                    key: 0,
                    class: _normalizeClass([{'ghm-error': hasGhmError.value}, "col-auto card-title"])
                  }, " Erreur ", 2))
                : _createCommentVNode("", true),
              (_ctx.isAutomatable)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(PredictionIcon)
                  ])), [
                    [_directive_tooltip, 'Automatisable']
                  ])
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([{'ghm-error': hasGhmError.value}, "card-body"])
      }, [
        (!hasGhmError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(GroupingField, {
                  label: "GHM",
                  value: _ctx.ghm?.reference,
                  description: _ctx.ghm?.description
                }, null, 8, ["value", "description"])
              ]),
              (_ctx.isAutomatable)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(PredictionIcon)
                  ])), [
                    [_directive_tooltip, 'Automatisable']
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.stayDuration > 1 ? _ctx.stayDuration + ' jours' : _ctx.stayDuration + ' jour'), 1)
            ]))
          : _createCommentVNode("", true),
        (!hasGhmError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(GroupingField, {
                  label: "GHS",
                  value: _ctx.ghs
                }, null, 8, ["value"])
              ]),
              _createVNode(GroupingCost, {
                cost: props.value
              }, null, 8, ["cost"])
            ]))
          : _createCommentVNode("", true),
        (!hasGhmError.value && _ctx.mode !== _unref(ModeEnum).AUTOMATION && _ctx.moneyGap != 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col" }, null, -1)),
              _createVNode(GroupingMoneyGap, {
                "money-gap": _ctx.moneyGap,
                class: "col-auto"
              }, null, 8, ["money-gap"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(GroupingErrorMessage, {
          "is-critical": hasGhmError.value,
          error: _ctx.groupingError
        }, null, 8, ["is-critical", "error"])
      ], 2)
    ])
  ]))
}
}

})