export default {
  interface: {
    label: 'Paramètres d\'interface',
    settings: [
      {
        name: 'enable_ssr',
        label: 'Activer le SMR',
        type: 'bool',
      },
      {
        name: 'enable_pseudonymization',
        label: 'Activer la pseudonymisation',
        type: 'bool',
      },
      {
        name: 'enable_data_downgrading',
        label: 'Activer le déclassement des séjours issus de la source DATA',
        type: 'bool',
      },
      {
        name: 'enable_manual_acts_external_export',
        label: 'Activer l\'export des actes manuels vers le connecteur externe',
        type: 'bool',
      },
      {
        name: 'enable_comments_external_export',
        label: 'Activer l\'export des commentaires vers le connecteur externe',
        type: 'bool',
      },
      {
        name: 'hide_sancare_searches_details',
        label: 'Masquer les détails des recherches sancare',
        type: 'bool',
      }
    ],
  },
  automation: {
    label: 'Paramètres de l\'automatisation',
    settings: [
      {
        name: 'demo_mode',
        label: 'Activer le mode démo',
        type: 'bool',
      },
      {
        name: 'session_automation',
        label: 'Automatisation des séances',
        type: 'bool',
      },
      {
        name: 'non_session_automation',
        label: 'Automatisation des séjours standards (non séances)',
        type: 'bool',
      },
      {
        name: 'rules_automation',
        label: 'Automatisation par règles',
        type: 'bool',
      },
      {
        name: 'renewal_automation',
        label: 'Automatisation par reconduction',
        type: 'bool'
      }
    ],
  },
  import: {
    label: 'Paramètres d\'import des séjours',
    settings: [
      {
        name: 'enable_ml_negation_detection',
        label: 'Activation de la détection de négations par ML',
        type: 'bool',
      },
      {
        name: 'restricted_legal_finess',
        label: 'Restriction par FINESS juridique',
        type: 'finess',
      },
      {
        name: 'restricted_geo_finess',
        label: 'Restriction par FINESS géographique',
        type: 'finess',
      }
    ]
  }
}
