import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "ml-1" }
const _hoisted_3 = {
  key: 1,
  class: "ml-1"
}
const _hoisted_4 = {
  key: 2,
  class: "ml-1"
}
const _hoisted_5 = { class: "row pl-3" }
const _hoisted_6 = { class: "col act-data" }
const _hoisted_7 = {
  key: 0,
  class: "col-auto pl-0"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "loader loader-xs"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, ref } from 'vue'

import { useStore } from '@/store'
import { CodedActChop, CodedMedicalAct, lateralityOptions } from '@/store/modules/medical-act/types'
import { CountryEnum, PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalAct',
  props: {
    pmsiCountry: {},
    codedMedicalAct: {},
    readonly: { type: Boolean, default: true },
    rumId: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const isLoading = ref(false)
const removeConfirm = ref(false)

const actExtention = computed(() => props.pmsiCountry === CountryEnum.FR && 'extension' in props.codedMedicalAct ? (props.codedMedicalAct.extension ?? '') : '')

function getLateralityLabel(value: string): string {
  const option = lateralityOptions.find((lo) => lo.value === value)
  return option.label ?? 'Inconnu'
}

async function removeMedicalAct() {
  isLoading.value = true
  await store.dispatch('medicalAct/removeMedicalAct', {
    id: props.codedMedicalAct.id,
    urlParams: {
      pmsiType: PmsiTypeEnum.MCO,
      summaryUnitId: props.rumId,
    },
    successMut: {
      name: 'updateMedicalActsSuccess',
    }
  })
  isLoading.value = false
  removeConfirm.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ opacity: (isLoading.value) ? 0.5 : 1 })
  }, [
    _createElementVNode("h2", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.codedMedicalAct.associatedAct.reference) + _toDisplayString(actExtention.value), 1),
      (_ctx.codedMedicalAct.associatedAct.isUncommon)
        ? _withDirectives((_openBlock(), _createBlock(_unref(SancareOcticon), {
            key: 0,
            name: "alert",
            width: 15,
            height: 15
          }, null, 512)), [
            [_directive_tooltip, _ctx.$t('medicalAct.uncommonWarning')]
          ])
        : _createCommentVNode("", true),
      (_ctx.pmsiCountry === _unref(CountryEnum).FR && 'activity' in _ctx.codedMedicalAct.associatedAct)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, "- Activité " + _toDisplayString(_ctx.codedMedicalAct.associatedAct.activity), 1))
        : (_ctx.pmsiCountry === _unref(CountryEnum).CH && 'laterality' in _ctx.codedMedicalAct)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, "- " + _toDisplayString(getLateralityLabel(_ctx.codedMedicalAct.laterality)), 1))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.codedMedicalAct.associatedAct.description), 1),
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_v_dropdown, { shown: removeConfirm.value }, {
              popper: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "title-light text-center" }, " Êtes-vous sûr(e) ? ", -1)),
                _createElementVNode("div", { class: "text-center" }, [
                  _createElementVNode("button", {
                    class: "btn btn-danger btn-padded",
                    onClick: removeMedicalAct
                  }, " Confirmer ")
                ])
              ]),
              default: _withCtx(() => [
                (!isLoading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _withDirectives(_createVNode(_unref(SancareOcticon), {
                        name: "x",
                        width: 15,
                        height: 15,
                        class: "icon-danger cp"
                      }, null, 512), [
                        [_directive_tooltip, _ctx.$t('medicalAct.delete')]
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9))
              ]),
              _: 1
            }, 8, ["shown"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}
}

})