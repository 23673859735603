import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card border-0 bg-light-grey py-1 mb-0" }
const _hoisted_2 = {
  key: 0,
  class: "card-body"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12 card-title" }
const _hoisted_6 = { class: "card-body border-top" }
const _hoisted_7 = { class: "p-2" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-7" }
const _hoisted_10 = { class: "col-5 font-weight-bold" }
const _hoisted_11 = { class: "col-7" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-7" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-7" }

import { computed } from 'vue'

import GmeDisplayer from '@/stay-displayer/grouping/ssr/GmeDisplayer.vue'
import WeekDaysPresence from '@/stay-displayer/ssr/rhs/WeekDaysPresence.vue'
import { countRhsPresenceDays } from '@/stay-displayer/ssr/utils'
import { formatCost } from '@/stay-displayer/utils'
import { getSingleDiagnosisTypes } from '@/store/modules/diagnosis/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { HospitalizationType, Rhs } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsSummary',
  props: {
    rhs: {},
    hospitalizationType: {}
  },
  setup(__props: any) {

const props = __props

const dasList = computed(() => props.rhs.currentModeDiagnosisGroup.dasList.map((das) => das.associatedDiagnosis))
const medicalActs = computed(() => props.rhs.codedMedicalActs.filter((codedAct) => !codedAct.disabled).map((medcialAct) => medcialAct.associatedAct))

const cost = computed(() => formatCost(props.rhs.currentModeDiagnosisGroup.groupingResult.value))

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hospitalizationType !== _unref(HospitalizationType).COMPLETE)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-weight-bold mr-3" }, "GMT", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.rhs.currentModeDiagnosisGroup.groupingResult.gmt ?? '-'), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(cost.value), 1)
              ])
            ]),
            _createVNode(GmeDisplayer, {
              gme: _ctx.rhs.currentModeDiagnosisGroup.groupingResult.gme
            }, null, 8, ["gme"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-weight-bold mr-3" }, "Total jours", -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(countRhsPresenceDays)(_ctx.rhs)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-5 font-weight-bold" }, " Jours ", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(WeekDaysPresence, { rhs: _ctx.rhs }, null, 8, ["rhs"])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getSingleDiagnosisTypes)(_unref(PmsiTypeEnum).SSR, _ctx.rhs.endDate), (diagType) => {
        return (_openBlock(), _createElementBlock("div", {
          key: diagType,
          class: "row"
        }, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(diagType), 1),
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.rhs.currentModeDiagnosisGroup[diagType.toLowerCase()]?.associatedDiagnosis?.reference ?? '-'), 1)
          ])), [
            [_directive_tooltip, _ctx.rhs.currentModeDiagnosisGroup[diagType.toLowerCase()]?.associatedDiagnosis?.description ?? null]
          ])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_12, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-5 font-weight-bold" }, " DAS ", -1)),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dasList.value, (das, idx) => {
            return _withDirectives((_openBlock(), _createElementBlock("span", {
              key: das.reference
            }, [
              _createTextVNode(_toDisplayString((idx > 0 ? ', ' : '') + das.reference), 1)
            ])), [
              [_directive_tooltip, das.description ?? null]
            ])
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-5 font-weight-bold" }, " Actes CCAM ", -1)),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(medicalActs.value, (medicalAct, idx) => {
            return _withDirectives((_openBlock(), _createElementBlock("span", {
              key: medicalAct.reference
            }, [
              _createTextVNode(_toDisplayString((idx > 0 ? ', ' : '') + medicalAct.reference), 1)
            ])), [
              [_directive_tooltip, medicalAct.description ?? null]
            ])
          }), 128))
        ])
      ])
    ])
  ], 64))
}
}

})