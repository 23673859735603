import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col d-flex" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row flex-nowrap" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = {
  key: 0,
  class: "loader loader-xs"
}
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = {
  key: 0,
  class: "loader loader-xs"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, Ref, ref, toRef } from 'vue'

import useFixedHeight from '@/misc/composables/fixedHeight'
import GmeDisplayer from '@/stay-displayer/grouping/ssr/GmeDisplayer.vue'
import { hasCriticalError } from '@/stay-displayer/grouping/ssr/grouping-validators'
import GroupingCost from '@/stay-displayer/grouping/ssr/GroupingCost.vue'
import { SsrGroupingResult } from '@/store/modules/stay/ssr/grouping/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayGroupingReminder',
  props: {
    result: {},
    isRefreshing: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const gme = computed(() => props.result.gme)
const gmt = computed(() => props.result.gmt)
const cost = computed(() => props.result.value)

const errorShowed = computed(() => hasCriticalError(props.result.groupingErrors))

const mainDiv: Ref<HTMLDivElement | null> = ref(null)
const { elementHeight } = useFixedHeight(toRef(props, 'isRefreshing'), mainDiv)

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "mainDiv",
    ref: mainDiv,
    class: "stay-grouping-reminder",
    style: _normalizeStyle({minHeight: _unref(elementHeight)})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-uppercase" }, [
          _createElementVNode("span", { class: "font-weight-normal" }, "Groupage"),
          _createTextVNode(" Séjour ")
        ], -1)),
        (errorShowed.value)
          ? _withDirectives((_openBlock(), _createBlock(_unref(SancareOcticon), {
              key: 0,
              class: "ml-2 stay-grouping-reminder__error-icon",
              name: "alert"
            }, null, 512)), [
              [_directive_tooltip, 'Groupage en erreur, impossible d\'afficher le tarif']
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-auto mr-auto" }, " GME ", -1)),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.isRefreshing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : (_openBlock(), _createBlock(GmeDisplayer, {
                  key: 1,
                  gme: gme.value,
                  size: "sm",
                  displayable: ""
                }, null, 8, ["gme"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-auto mr-auto" }, " Tarif ", -1)),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.isRefreshing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : (_openBlock(), _createBlock(GroupingCost, {
                  key: 1,
                  cost: cost.value,
                  gmt: gmt.value,
                  size: "sm"
                }, null, 8, ["cost", "gmt"]))
          ])
        ])
      ])
    ])
  ], 4))
}
}

})