import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useStore } from '@/store'
import { ModeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadingListButton',
  props: {
    stayList: {},
    mode: {},
    baseStayDisplayerPath: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()
const route = useRoute()
const store = useStore()

const selectedStayIds = computed(() => store.state.stayList.selectedStayIds)
const selectedStaysCount = computed(() => selectedStayIds.value.length)
const label = computed(() => `Parcourir${selectedStaysCount.value > 1 ? ` (${selectedStaysCount.value})` : ''}`)

function startReadingList() {
  if (!selectedStayIds.value.length) {
    props.stayList.forEach((stayId) => {
      if (!store.getters.isStaySelected(stayId)) {
        store.dispatch('toggleSelectedStay', stayId)
      }
    })
  }
  window.sessionStorage.setItem('stayListRoute', route.fullPath)

  store.dispatch('createReadingList').then(() => {
    const stayId = store.getters.readingListCurrentStayId
    if (stayId) {
      router.push({
        path: `${props.baseStayDisplayerPath}/${stayId}`,
        query: {
          readingList: 1,
          mode: props.mode,
        }
      })
    }
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn sancare-btn btn-fixed-width", { disabled: !_ctx.stayList.length || selectedStaysCount.value === 1 }]),
    onClick: startReadingList
  }, _toDisplayString(label.value), 3))
}
}

})