import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, resolveDirective as _resolveDirective, vShow as _vShow, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-3 font-weight-bold text-right" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "input-group" }

import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { computed, Ref, ref } from 'vue'

import { useStore } from '@/store'
import { actionLabels, AutomationActionEnum } from '@/store/modules/automation/types'

type CompletionDiag = {
  reference: string
  description: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AutomationActionEditor',
  emits: ['save-action'],
  setup(__props, { emit: __emit }) {

const store = useStore()
const emit = __emit

const typedInput: Ref<string> = ref('')
const showCompletion = ref(false)
const actionAction = ref(AutomationActionEnum.ADD_DP)
const actionValue = ref('')

const completionItems = computed(() => store.state.medicalUnitFilter.completionItems)
const completionRequest = computed(() => store.state.medicalUnitFilter.completionRequest)

function selectItem(diag: CompletionDiag) {
  actionValue.value = diag.reference
  emit('save-action', {
    action: actionAction.value,
    value: actionValue.value,
  })
  showCompletion.value = false
}
function openCompletion() {
  showCompletion.value = true
}
function closeCompletion() {
  showCompletion.value = false
}

function fetchCompletionItems(search: string, signal: AbortSignal) {
  store.dispatch('medicalUnitFilter/getCompletionItems', {
    url: '/api/stay-search-criteria/completion',
    urlParams: {
      type: 'diagnosis',
      search,
    },
    signal,
  })
}

function clearCompletionItems() {
  store.commit('medicalUnitFilter/clearCompletionItems')
}

useAutoCompletionDebouncedWatcher(typedInput, completionItems, fetchCompletionItems, clearCompletionItems)

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((actionAction).value = $event)),
        class: "custom-select"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AutomationActionEnum), (action) => {
          return (_openBlock(), _createElementBlock("option", {
            key: action,
            value: action
          }, _toDisplayString(_unref(actionLabels)[action]), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, actionAction.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((typedInput).value = $event)),
          class: "form-control",
          onClick: openCompletion,
          onKeydown: _withKeys(closeCompletion, ["esc"])
        }, null, 544), [
          [_vModelText, typedInput.value],
          [_directive_on_click_outside, closeCompletion]
        ])
      ]),
      _withDirectives(_createVNode(_unref(InputAutoCompletion), {
        "completion-list": completionItems.value,
        "is-loading": completionRequest.value.fetching,
        "title-prop": 'reference',
        "description-prop": 'description',
        onSelectItem: selectItem
      }, null, 8, ["completion-list", "is-loading"]), [
        [_vShow, showCompletion.value && typedInput.value !== '']
      ])
    ])
  ]))
}
}

})