import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, Fragment as _Fragment, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "badge badge-nosology mr-1"
}
const _hoisted_4 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_5 = {
  class: "text-right",
  style: {"width":"25px"}
}
const _hoisted_6 = {
  class: "diagnosis-searches",
  style: {"max-width":"80px"}
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "error-block mt-2"
}

import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import { HealthDataDocument } from '@sancare/ui-frontend-commons/src/types/health-data'
import { Dayjs } from 'dayjs'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import PredictionIcon from '@/common/PredictionIcon.vue'
import LoadingMark from '@/misc/LoadingMark.vue'
import { extractChunksFromSuggestedDiagnosis } from '@/stay-displayer/diagnosis-lists/diagnosis_justifications'
import JustificationSummary from '@/stay-displayer/diagnosis-lists/JustificationSummary.vue'
import {
  Diagnosis,
  DiagnosisType,
  getSingleDiagnosisTypes,
} from '@/store/modules/diagnosis/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'
import { StayHealthData } from '@/store/modules/stay/types'
import { Search } from '@/store/modules/stay-saved-search/types'
import { TextProcessingStaySavedSearch } from '@/text-processing/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'RhsDiagnosisItem',
  props: {
    diagnosis: {},
    diagnosisType: { default: null },
    confidence: {},
    isSuggestion: { type: Boolean },
    rhsId: {},
    rhsEndDate: {},
    search: { default: null },
    healthData: { default: null },
    savedSearchIntersectionChunks: { default: [] as string[] },
    staySavedSearches: { default: [] as TextProcessingStaySavedSearch[] }
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const removeDiagnosisRequest = ref({ fetching: false, error: null, ok: true })
const updateDiagnosisRequest = ref({ fetching: false, error: null, ok: true })
const isDropdownOpen = ref(false)
const showJustificationSummary = ref(false)

const isDiagnosisRequestFetching = computed(() => removeDiagnosisRequest.value.fetching || updateDiagnosisRequest.value.fetching)

const dropdownButtons = computed(() => {
  const buttons = []

  for (const type of [...getSingleDiagnosisTypes(PmsiTypeEnum.SSR, props.rhsEndDate), DiagnosisType.DAS]) {
    if ((props.isSuggestion || type !== props.diagnosisType)
      && props.diagnosis[`canBe${capitalize(type.toLowerCase())}`]
    ) {
      buttons.push({
        type: type,
        text: `${displayMoveArrow(props.diagnosisType, type, props.isSuggestion)} Déplacer en ${type}`,
        click: () => setDiagnosisAsType(type),
      })
    }
  }

  if (!props.isSuggestion) {
    buttons.push({
      type: 'delete',
      text: 'Supprimer',
      class: 'text-danger',
      click: () => removeDiagnosis(),
    })
  }

  return buttons
})

const chunks = computed(() => {
  const suggestedDiag = {
    ...props.diagnosis,
    staySavedSearches: props.staySavedSearches?.filter((sss) => sss.search.id === props.search.id),
  }
  return extractChunksFromSuggestedDiagnosis(suggestedDiag, props.savedSearchIntersectionChunks)
})
const canShowRational = computed(() => Boolean(chunks.value))

function toggleRationals() {
  showJustificationSummary.value = !showJustificationSummary.value
}

function handleHealthDataSelection(mainContent: HealthDataDocument) {
  store.commit('ssrStay/setJustificationShouldScroll', true)
  store.commit('ssrStay/setMainContent', mainContent)
  store.commit('ssrStay/setJustificationSelection', {
    labelType: props.diagnosisType,
    labelReference: props.diagnosis.reference,
    savedSearchIds: [props.search.id],
  })
  toggleRationals()
}

function displayMoveArrow(currentType: DiagnosisType | null, moveToType: DiagnosisType | null, isSuggestion: boolean) {
  return (isSuggestion
    || Object.values(DiagnosisType).indexOf(moveToType) < Object.values(DiagnosisType).indexOf(currentType)
  )
    ? '↑'
    : '↓'
}

function displayDescription() {
  return getSingleDiagnosisTypes(PmsiTypeEnum.SSR, props.rhsEndDate).includes(props.diagnosisType)
    && !props.isSuggestion
}

const setDiagnosisAsType = async (type: DiagnosisType) => {
  isDropdownOpen.value = false
  updateDiagnosisRequest.value = { fetching: true, error: null, ok: false }
  let response: { error: string } | null

  if (!props.isSuggestion) {
    response = await store.dispatch('ssrDiagnosis/updateSsrDiagnosis', {
      urlParams: {
        rhsId: props.rhsId,
        associatedDiagnosisId: props.diagnosis.id,
      },
      body: {
        currentDiagnosisType: props.diagnosisType,
        newDiagnosisType: type,
        diagnosis: props.diagnosis.reference,
      },
    })
  } else {
    response = await store.dispatch('ssrDiagnosis/addSsrDiagnosis', {
      urlParams: {
        rhsId: props.rhsId,
      },
      body: {
        newDiagnosisType: type,
        diagnosis: props.diagnosis.reference,
      },
    })
  }

  if (response?.error) {
    updateDiagnosisRequest.value.error = response.error
  } else {
    updateDiagnosisRequest.value.ok = true
  }

  updateDiagnosisRequest.value.fetching = false
}

const removeDiagnosis = async () => {
  isDropdownOpen.value = false
  removeDiagnosisRequest.value = { fetching: true, error: null, ok: false }

  const response = await store.dispatch('ssrDiagnosis/removeSsrDiagnosis', {
    urlParams: {
      rhsId: props.rhsId,
      associatedDiagnosisId: props.diagnosis.id,
    },
    body: {
      currentDiagnosisType: props.diagnosisType,
    },
  })

  if (response?.error) {
    removeDiagnosisRequest.value.error = response.error
  } else {
    removeDiagnosisRequest.value.ok = true
  }

  removeDiagnosisRequest.value.fetching = false
}


return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["diagnosis-line hoverable-item", {
      highlighted: _ctx.diagnosisType && _ctx.isSuggestion,
      'is-opaque': isDiagnosisRequestFetching.value
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.diagnosisType && _ctx.isSuggestion)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.diagnosisType), 1))
          : _createCommentVNode("", true),
        _createElementVNode("strong", null, _toDisplayString(_ctx.diagnosis.reference), 1)
      ])), [
        [
          _directive_tooltip,
          {
          content: _ctx.diagnosis.description,
          disabled: displayDescription(),
          delay: 0
        },
          void 0,
          { left: true }
        ]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_dropdown, {
          shown: showJustificationSummary.value,
          placement: "left",
          class: "popover-container flex-row align-items-center",
          "popover-class": "justification-popover"
        }, {
          popper: _withCtx(() => [
            _createVNode(JustificationSummary, {
              "is-loading": false,
              chunks: canShowRational.value ? chunks.value : null,
              "health-data": _ctx.healthData,
              "no-value-label": canShowRational.value ? 'Justification non disponible en raison des paramètres' : 'Pas de justification',
              "displayed-rum-idx": 1,
              "rum-predicted-labels": [],
              selection: null,
              "saved-searches": [{search: _ctx.search}],
              readonly: true,
              onSelectHealthData: handleHealthDataSelection
            }, null, 8, ["chunks", "health-data", "no-value-label", "saved-searches"])
          ]),
          default: _withCtx(() => [
            (_ctx.confidence !== null)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(PredictionIcon, { size: 13 }),
                  _createElementVNode("small", _hoisted_5, _toDisplayString(Math.round(_ctx.confidence * 100)) + "% ", 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.search)
              ? _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "d-flex align-items-center",
                  onClick: toggleRationals
                }, [
                  _createVNode(_unref(SancareOcticon), {
                    name: "search",
                    width: 13,
                    height: 13,
                    class: "mr-1"
                  }),
                  _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.search.name), 1)
                ])), [
                  [
                    _directive_tooltip,
                    {
              content: _ctx.search?.name,
              delay: 0
            },
                    void 0,
                    { left: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["shown"]),
        _createVNode(_component_v_dropdown, {
          placement: "top",
          class: _normalizeClass(["popover-container ml-2", { invisible: !dropdownButtons.value.length }]),
          "popover-class": "rhs-diagnosis-settings-popover",
          shown: isDropdownOpen.value,
          onAutoHide: _cache[1] || (_cache[1] = ($event: any) => (isDropdownOpen.value = false))
        }, {
          popper: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdownButtons.value, (button) => {
              return (_openBlock(), _createElementBlock("button", {
                key: `btn-${button.type}-${_ctx.diagnosis.id}`,
                class: _normalizeClass(["btn btn-link d-block", button.class || '']),
                onClick: ($event: any) => (button.click())
              }, _toDisplayString(button.text), 11, _hoisted_7))
            }), 128))
          ]),
          default: _withCtx(() => [
            (!isDiagnosisRequestFetching.value)
              ? (_openBlock(), _createBlock(_unref(SancareOcticon), {
                  key: 0,
                  name: "kebab-horizontal",
                  class: "rhs-diagnosis-settings-icon",
                  width: 18,
                  height: 18,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (isDropdownOpen.value = !isDropdownOpen.value))
                }))
              : (_openBlock(), _createBlock(LoadingMark, {
                  key: 1,
                  finished: !isDiagnosisRequestFetching.value,
                  class: "spinner",
                  color: "blue"
                }, null, 8, ["finished"]))
          ]),
          _: 1
        }, 8, ["class", "shown"])
      ])
    ]),
    (displayDescription())
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("small", null, _toDisplayString(_ctx.diagnosis['description']), 1)
        ]))
      : _createCommentVNode("", true),
    (removeDiagnosisRequest.value.error || updateDiagnosisRequest.value.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(removeDiagnosisRequest.value.error ?? updateDiagnosisRequest.value.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})