import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row align-items-center no-gutters hoverable-item" }
const _hoisted_2 = { class: "col-auto mr-auto" }
const _hoisted_3 = {
  key: 0,
  class: "act-data"
}
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "text-center" }

import { OcticonButton } from '@sancare/ui-frontend-commons'
import { ref } from 'vue'

import { useStore } from '@/store'
import { CodedMedicalAct } from '@/store/modules/medical-act/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalAct',
  props: {
    codedAct: {},
    rhsId: {}
  },
  setup(__props: any) {

const store = useStore()
const props = __props

const isLoading = ref(false)
const removeConfirm = ref(false)

async function removeAct() {
  isLoading.value = true
  await store.dispatch('medicalAct/removeMedicalAct', {
    id: props.codedAct.id,
    urlParams: {
      summaryUnitId: props.rhsId,
      pmsiType: PmsiTypeEnum.SSR,
    },
    startMut: { name: 'ssrStay/startRefreshingGroupingResult' },
    successMut: {
      name: 'ssrStay/updateMedicalActsSuccess',
    }
  })
  isLoading.value = false
  removeConfirm.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.codedAct.associatedAct.reference), 1)
      ])), [
        [_directive_tooltip, _ctx.codedAct.associatedAct.description || null]
      ]),
      (_ctx.codedAct.associatedAct.activity)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, "Activité " + _toDisplayString(_ctx.codedAct.associatedAct.activity), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_dropdown, { shown: removeConfirm.value }, {
        popper: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "title-light text-center" }, " Êtes-vous sûr(e) ? ", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-danger btn-padded",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (removeAct()))
            }, " Confirmer ")
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(OcticonButton), {
            "is-loading": isLoading.value,
            "octicon-name": "trash",
            width: 15,
            title: "Supprimer l'acte",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (removeConfirm.value = true))
          }, null, 8, ["is-loading"])
        ]),
        _: 1
      }, 8, ["shown"])
    ])
  ]))
}
}

})