import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card aside-gme" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  key: 0,
  class: "loader loader-lg mx-auto"
}
const _hoisted_6 = { class: "row align-items-end" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row align-items-end" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-6 grouping-actions-block d-flex flex-column justify-content-center" }

import { computed, Ref, ref, toRef } from 'vue'

import useFixedHeight from '@/misc/composables/fixedHeight'
import GroupingMoneyGap from '@/stay-displayer/grouping/GroupingMoneyGap.vue'
import GmeDisplayer from '@/stay-displayer/grouping/ssr/GmeDisplayer.vue'
import { hasCriticalError } from '@/stay-displayer/grouping/ssr/grouping-validators'
import GroupingCost from '@/stay-displayer/grouping/ssr/GroupingCost.vue'
import GroupingErrorList from '@/stay-displayer/grouping/ssr/GroupingErrorList.vue'
import StayGroupingReminder from '@/stay-displayer/grouping/ssr/rhs/StayGroupingReminder.vue'
import { AsideRhsGmeProps } from '@/store/modules/stay/ssr/grouping/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AsideRhsGme',
  props: {
    isRefreshing: { type: Boolean },
    stayGroupingResult: {},
    hospitalizationType: {},
    groupingResult: {},
    moneyGap: { default: 0 }
  },
  setup(__props: any) {

const props = __props

const displayedGme = computed(() => props.groupingResult.gme)
const gmt = computed(() => props.groupingResult.gmt)
const cost = computed(() => props.groupingResult.value)
const groupingErrors = computed(() => props.groupingResult.groupingErrors)
const isGroupingValid = computed(() => !hasCriticalError(groupingErrors.value))

const fixedElement: Ref<HTMLDivElement | null> = ref(null)
const { elementHeight } = useFixedHeight(toRef(props, 'isRefreshing'), fixedElement)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          ref_key: "fixedElement",
          ref: fixedElement,
          class: "row",
          style: _normalizeStyle({minHeight: _unref(elementHeight)})
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "card-title" }, [
              _createElementVNode("span", { class: "card-title__label" }, [
                _createElementVNode("span", { class: "font-weight-normal" }, "Groupage"),
                _createTextVNode(" Rhs ")
              ])
            ], -1)),
            (_ctx.isRefreshing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-label" }, " GME ", -1)),
                      _createVNode(GmeDisplayer, {
                        class: "mb-2",
                        gme: displayedGme.value
                      }, null, 8, ["gme"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col" }, null, -1)),
                    _createElementVNode("div", _hoisted_9, [
                      (isGroupingValid.value)
                        ? (_openBlock(), _createBlock(GroupingCost, {
                            key: 0,
                            cost: cost.value,
                            gmt: gmt.value
                          }, null, 8, ["cost", "gmt"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col" }, null, -1)),
                    (isGroupingValid.value)
                      ? (_openBlock(), _createBlock(GroupingMoneyGap, {
                          key: 0,
                          "money-gap": _ctx.moneyGap,
                          class: "col-auto"
                        }, null, 8, ["money-gap"]))
                      : _createCommentVNode("", true)
                  ])
                ], 64))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(GroupingErrorList, { errors: groupingErrors.value }, null, 8, ["errors"])
          ])
        ], 4)
      ]),
      _createVNode(StayGroupingReminder, {
        result: _ctx.stayGroupingResult,
        "is-refreshing": _ctx.isRefreshing
      }, null, 8, ["result", "is-refreshing"])
    ])
  ]))
}
}

})