import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-6 input-group"
}
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 1,
  class: "col-12 text-right"
}
const _hoisted_6 = { class: "text-danger pt-1" }

import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import { useAutoCompletionDebouncedWatcher } from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import _ from 'lodash'
import { computed, Ref, ref, watch } from 'vue'

import { useFilterOptions } from '@/filters/composables/useFilterOptions'
import { useStore } from '@/store'
import { FilterData } from '@/store/modules/filters/types'
import { PmsiTypeEnum } from '@/store/modules/settings/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'CompletionFilter',
  props: {
    filterData: {},
    pmsiType: {}
  },
  emits: ['add-filter'],
  setup(__props: any, { emit: __emit }) {

const { displayedOption } = useFilterOptions()

const store = useStore()

const emit = __emit
const props = __props

const errorMsg = ref('')
const showCompletion = ref(false)
const typedInput: Ref<string> = ref('')

const visibleSubFilters = computed(() => props.filterData.subFilters?.filter((filter) => !filter.pmsiTypes || filter.pmsiTypes.includes(props.pmsiType)))
const completionItems = computed(() => store.state.medicalUnitFilter.completionItems)
const completionRequest = computed(() => store.state.medicalUnitFilter.completionRequest)
const hasCurrentSearchRevaloDasCriteria = computed(() => {
  return store.state.stayList.currentSearch.globalCriteriaGroup && store.state.stayList.currentSearch.globalCriteriaGroup.criteriaList.some((criterion) => criterion.type === 'revaloDAS')
})
const hasCurrentSearchRevaloDpCriteria = computed(() => {
  return store.state.stayList.currentSearch.globalCriteriaGroup && store.state.stayList.currentSearch.globalCriteriaGroup.criteriaList.some((criterion) => criterion.type === 'revaloDP')
})

const autoCompleteItemTitleProp = computed(() => {
  if (props.filterData.name === 'drug') {
    return 'atc'
  }
  if (props.filterData.name === 'biology') {
    return 'code'
  }
  return 'reference'
})
const autoCompleteItemDescriptionProp = computed(() => {
  if (props.filterData.name === 'drug') {
    return 'atcLabel'
  }
  if (props.filterData.name === 'biology') {
    return 'title'
  }
  return 'description'
})

const subFiltersValues = computed(() => {
  const values = {}
  if (visibleSubFilters.value) {
    visibleSubFilters.value.forEach((filter) => {
      values[filter.name] = filter.default ?? (filter.options ? filter.options[0] : '')
    })
  }
  return values
})

const subFilters = computed(() => {
  if (!visibleSubFilters.value) {
    return []
  }
  if (props.filterData.name !== 'revaloDiagnosis') {
    return visibleSubFilters.value
  }

  // prevent selecting revaloDAS and revaloDP in same search
  const subFilters = []
  const diagTypeInitialOptions = _.find(visibleSubFilters.value, (subFilter) => subFilter.name === 'diagType').options
  _.forEach(visibleSubFilters.value, (subFilter) => {
    if (subFilter.name === 'diagType') {
      subFilter.options = diagTypeInitialOptions
      if (hasCurrentSearchRevaloDasCriteria.value) {
        _.remove(subFilter.options, (option) => option === 'DP')
      }
      if (hasCurrentSearchRevaloDpCriteria.value) {
        _.remove(subFilter.options, (option) => option === 'DAS')
      }
    }
    subFilters.push(subFilter)
  })
  return subFilters
})

function openCompletion() {
  showCompletion.value = true
}
function closeCompletion() {
  showCompletion.value = false
}

function selectItem(completionItem) {
  const name = props.filterData.name
  let type = props.filterData.format
  const value = props.filterData.name !== 'drug' ? completionItem.reference : completionItem.atc
  const description = props.filterData.name === 'medicalAct' ? completionItem.description : ''

  type = type.replace('{filterType}', capitalize(props.filterData.name))
  if (visibleSubFilters.value) {
    visibleSubFilters.value.forEach((filter) => {
      type = type.replace(`{${filter.name}}`, capitalize(subFiltersValues.value[filter.name]))
    })
  }
  type = type.charAt(0).toLowerCase() + type.slice(1)

  closeCompletion()
  emit('add-filter', { name, type, value, description })
  typedInput.value = ''
}
function addFilter() {
  const name = props.filterData.name
  let type = props.filterData.format
  const value = typedInput.value.trim()

  if (props.filterData.name === 'revaloDiagnosis' && value === '*') {
    errorMsg.value = 'Impossible d\'ajouter ce critère'
    return
  }

  if (value.length) {
    type = type.replace('{filterType}', capitalize(props.filterData.name))
    if (visibleSubFilters.value) {
      visibleSubFilters.value.forEach((filter) => {
        type = type.replace(`{${filter.name}}`, capitalize(subFiltersValues.value[filter.name]))
      })
    }
    type = type.charAt(0).toLowerCase() + type.slice(1)
    closeCompletion()
    emit('add-filter', { name, type, value })
  }
  typedInput.value = ''
}

function fetchCompletionItems(search: string, signal: AbortSignal) {
  errorMsg.value = ''
  store.dispatch('medicalUnitFilter/getCompletionItems', {
    url: '/api/stay-search-criteria/completion',
    urlParams: {
      type: props.filterData.completionName,
      search,
    },
    signal,
  })
}

function clearCompletionItems() {
  store.commit('medicalUnitFilter/clearCompletionItems')
  errorMsg.value = ''
}

useAutoCompletionDebouncedWatcher(typedInput, completionItems, fetchCompletionItems, clearCompletionItems)

watch(() => props.filterData, () => {
  clearCompletionItems()
  typedInput.value = ''
})

return (_ctx: any,_cache: any) => {
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.filterData && subFilters.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subFilters.value, (filter) => {
              return _withDirectives((_openBlock(), _createElementBlock("select", {
                key: filter.name,
                "onUpdate:modelValue": ($event: any) => ((subFiltersValues.value[filter.name]) = $event),
                class: "custom-select form-control"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (opt) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: opt,
                    value: opt
                  }, _toDisplayString(_unref(displayedOption)(filter, opt)), 9, _hoisted_4))
                }), 128))
              ], 8, _hoisted_3)), [
                [_vModelSelect, subFiltersValues.value[filter.name]]
              ])
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["input-group", subFilters.value.length ? ['col-6'] : ['col-12']])
      }, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((typedInput).value = $event)),
          type: "text",
          class: "form-control",
          onClick: openCompletion,
          onKeydown: _withKeys(closeCompletion, ["esc"])
        }, null, 544), [
          [_vModelText, typedInput.value],
          [_directive_on_click_outside, closeCompletion]
        ]),
        _createElementVNode("div", { class: "input-group-append" }, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "button",
            onClick: addFilter
          }, " Ok ")
        ])
      ], 2),
      (errorMsg.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(errorMsg.value), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createVNode(_unref(InputAutoCompletion), {
      "completion-list": completionItems.value,
      "is-loading": completionRequest.value.fetching,
      "title-prop": autoCompleteItemTitleProp.value,
      "description-prop": autoCompleteItemDescriptionProp.value,
      onSelectItem: selectItem
    }, null, 8, ["completion-list", "is-loading", "title-prop", "description-prop"]), [
      [_vShow, showCompletion.value && typedInput.value !== '']
    ])
  ]))
}
}

})