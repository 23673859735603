import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import _ from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LineClampToggle',
  props: {
    isOpen: { type: Boolean },
    lineClamp: { default: 3 }
  },
  emits: ["display-toggle-button"],
  setup(__props: any, { emit: __emit }) {


const props = __props

// todo upgrade vue3.3 and use https://vuejs.org/guide/typescript/composition-api#typing-component-emits
const emit = __emit

const container = ref(null)

const lineClampStyle = computed(() => {
  if (!props.isOpen) {
    return {
      '-webkit-line-clamp': props.lineClamp,
      'line-clamp': props.lineClamp,
    }
  }
  return {}
})

onMounted(() => {
  updateWrapperHeight()
  window.addEventListener('resize', _.throttle(updateWrapperHeight, 500, { leading: false, maxWait: 500, trailing: true }))
})

watch(() => props.isOpen, _.throttle(function() {
  updateWrapperHeight()
}, 500, { leading: false, maxWait: 500, trailing: true }))

function updateWrapperHeight() {
  emit('display-toggle-button', props.isOpen || container.value.scrollHeight > container.value.clientHeight ? true : false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "container",
    ref: container,
    class: "line-clamp-container",
    style: _normalizeStyle(lineClampStyle.value)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}
}

})