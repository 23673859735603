import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "animated-height" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-8" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-8" }
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = {
  key: 0,
  class: "col-2"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-8" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "row"
}
const _hoisted_18 = { class: "col-8" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-8" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-8" }
const _hoisted_23 = {
  key: 2,
  class: "row"
}
const _hoisted_24 = { class: "col-6" }
const _hoisted_25 = {
  key: 0,
  class: "col-2"
}

import { HeightTransition, SancareOcticon } from '@sancare/ui-frontend-commons'
import { computed, Ref, ref, watch } from 'vue'

import { LocalizedOption } from '@/store/modules/filters/types'
import { CountryEnum } from '@/store/modules/settings/types'
import { RumFr } from '@/store/modules/stay/mco/types'

import FiltersData from '../filters/FiltersData'


export default /*@__PURE__*/_defineComponent({
  __name: 'RumFr',
  props: {
    rums: {},
    displayedRumId: {}
  },
  setup(__props: any) {

const props = __props

const localDisplayedRumId = ref(null) as Ref<number | null>

const collapseRum = computed (() => {
  return props.rums.filter((rum) => rum.id !== (localDisplayedRumId.value || props.displayedRumId))
})
function isRumHidden(rumId: number): boolean {
  return Boolean(collapseRum.value.find((rum) => rum.id === rumId))
}
function explainMode(mode: string): string {
  const opt = (FiltersData.admissionMode.options.concat(FiltersData.releaseMode.options) as LocalizedOption[])
    .find((option) => option.country === CountryEnum.FR && option.value === mode)
  return opt ? opt.label : mode
}

function explainOriginDestination(type: 'origin' | 'destination', place: string): string {
  const opt = (FiltersData[type]['options'] as LocalizedOption[])
    .find((option) => option.country === CountryEnum.FR && option.value === place)
  return opt ? opt.label : place
}

function getAdmissionOriginReplacementMessage(rumPosition: number, admissionMode: string, origin: string): string {
  const admissionModeMessage = 'Mode d\'entrée utilisé pour le groupage : '
  const originMessage = '<br /> Provenance utilisée pour le groupage : '

  if (rumPosition !== 0) {
    if (admissionMode !== '6' || origin !== '1') {
      return `${admissionModeMessage}Mutation${originMessage}Unité de soins de courte durée`
    }

    return ''
  }

  if (admissionMode === '8' && [' ', '5', '7'].indexOf(origin) === -1) {
    return `${admissionModeMessage}Domicile <br /> Pas de provenance spécifiée`
  } else if (admissionMode === '7' && ['6', '4', '3', '2', '1', 'R'].indexOf(origin) === -1) {
    return `${admissionModeMessage}Transfert entre entités juridiques${originMessage}Hospitalisation à domicile`
  } else if (admissionMode === '6' && ['6', '4', '3', '2'].indexOf(origin) === -1) {
    return `${admissionModeMessage}Mutation${originMessage}Hospitalisation à domicile`
  } else if (admissionMode === '0' && ['4', '3', '2', '1', 'R'].indexOf(origin) === -1) {
    return `${admissionModeMessage}Transfert inter-établissement${originMessage}Psychiatrie`
  }

  return ''
}
function getReleaseDestinationReplacementMessage(rumPosition: number, releaseMode: string, destination: string): string {
  const releaseModeMessage = 'Mode de sortie utilisé pour le groupage : '
  const destinationMessage = '<br /> Destination utilisée pour le groupage : '

  if (rumPosition + 1 !== props.rums.length - 1 && props.rums.length > 1) {
    if (releaseMode !== '6' || destination !== '1') {
      return `${releaseModeMessage}Mutation${destinationMessage}Unité de soins de courte durée`
    }

    return ''
  }

  if (releaseMode === '8' && [' ', '7'].indexOf(destination) === -1) {
    return `${releaseModeMessage}Domicile <br /> Pas de destination spécifiée`
  } else if(releaseMode === '7' && ['6', '4', '3', '2', '1'].indexOf(destination) === -1) {
    return `${releaseModeMessage}Transfert entre entités juridiques${destinationMessage}Hospitalisation à domicile`
  } else if (releaseMode === '6' && ['6', '4', '3', '2'].indexOf(destination) === -1) {
    return `${releaseModeMessage}Mutation${destinationMessage}Hospitalisation à domicile`
  } else if (releaseMode === '0' && ['4', '3', '2', '1'].indexOf(destination) === -1) {
    return `${releaseModeMessage}Transfert inter-établissement${destinationMessage}Psychiatrie`
  }

  return ''
}
function toggleRum(rumId: number) {
  localDisplayedRumId.value = rumId
}

watch(() => props.displayedRumId, () => {
  localDisplayedRumId.value = null
})

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rums, (rum, idx) => {
    return (_openBlock(), _createElementBlock("div", {
      key: rum.id,
      class: "data-table general-data-rum"
    }, [
      _createElementVNode("div", {
        class: "rum-number",
        style: _normalizeStyle(_ctx.rums.length > 1 ? 'cursor:pointer' : 'cursor:auto'),
        onClick: ($event: any) => (toggleRum(rum.id))
      }, " RUM" + _toDisplayString(idx + 1), 13, _hoisted_1),
      _createVNode(_unref(HeightTransition), null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "col-4" }, " ID ", -1)),
              _createElementVNode("div", _hoisted_4, _toDisplayString(idx + 1), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "col-4" }, " DURÉE EN JOURS ", -1)),
              _createElementVNode("div", _hoisted_6, _toDisplayString(rum.rumEnd.diff(rum.rumStart, 'days')) + "j ", 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "col-4" }, " DATE D'ENTRÉE ", -1)),
              _createElementVNode("div", _hoisted_8, _toDisplayString(rum.rumStart.format('L')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "col-4" }, " MODE D'ENTRÉE ", -1)),
              _createElementVNode("div", _hoisted_10, _toDisplayString(explainMode(rum.admissionMode)), 1)
            ]),
            (['', ' '].indexOf(rum.origin) === -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "col-4" }, " PROVENANCE ", -1)),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(explainOriginDestination('origin', rum.origin)), 1),
                  (getAdmissionOriginReplacementMessage(idx, rum.admissionMode, rum.origin) !== '')
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_unref(SancareOcticon), {
                          name: "alert",
                          width: 15,
                          height: 15
                        })
                      ])), [
                        [_directive_tooltip, {
              content: getAdmissionOriginReplacementMessage(idx, rum.admissionMode, rum.origin),
              placement: 'left'
            }]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_14, [
              _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "col-4" }, " UNITÉ MEDICALE ", -1)),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", null, _toDisplayString(rum.medicalUnit.reference), 1),
                (rum.medicalUnit.title)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, " - " + _toDisplayString(rum.medicalUnit.title), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            (rum.supervisingDoctorName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "col-4" }, " MÉDECIN RESP. ", -1)),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", null, _toDisplayString(rum.supervisingDoctorName), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_19, [
              _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "col-4" }, " DATE DE SORTIE ", -1)),
              _createElementVNode("div", _hoisted_20, _toDisplayString(rum.rumEnd.format('L')), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "col-4" }, " MODE DE SORTIE ", -1)),
              _createElementVNode("div", _hoisted_22, _toDisplayString(explainMode(rum.releaseMode)), 1)
            ]),
            (['', ' '].indexOf(rum.destination) === -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _cache[9] || (_cache[9] = _createElementVNode("h1", { class: "col-4" }, " DESTINATION ", -1)),
                  _createElementVNode("div", _hoisted_24, _toDisplayString(explainOriginDestination('destination', rum.destination)), 1),
                  (getReleaseDestinationReplacementMessage(idx, rum.releaseMode, rum.destination) !== '')
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createVNode(_unref(SancareOcticon), {
                          name: "alert",
                          width: 15,
                          height: 15
                        })
                      ])), [
                        [_directive_tooltip, {
              content: getReleaseDestinationReplacementMessage(idx, rum.releaseMode, rum.destination),
              placement: 'left'
            }]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, !isRumHidden(rum.id)]
          ])
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}
}

})