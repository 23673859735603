import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader loader-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "col text-left" }

import { Dayjs } from 'dayjs'

import { StayAction, stayActionLabels } from '@/store/modules/stay/mco/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'StayActions',
  props: {
    isLoading: { type: Boolean },
    stayActions: {}
  },
  setup(__props: any) {



function formatDate(date: Dayjs): string {
  return `${date.format('DD/MM/YYYY')} à ${date.format('HH:mm')}`
}

return (_ctx: any,_cache: any) => {
  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.stayActions.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Aucune action "))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.stayActions, (stayAction) => {
              return (_openBlock(), _createElementBlock("div", {
                key: stayAction.id,
                class: "row p-1"
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(formatDate(stayAction.creationDate)), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(stayActionLabels)[stayAction.type]) + " par " + _toDisplayString(stayAction.username), 1)
              ]))
            }), 128))
      ]))
}
}

})