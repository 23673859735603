import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'

import { formatBaseStay, formatCodedMedicalActs } from '@/misc/stay/helper'
import { RawRhs, RawSsrStay, Rhs, SsrStay } from '@/store/modules/stay/ssr/types'

export function formatSsrStay(rawStay: RawSsrStay): SsrStay {
  // TODO: Common formatter with MCO stays
  return {
    ...formatBaseStay(rawStay),
    ssrId: rawStay.ssrId,
    hospitalizationType: rawStay.hospitalizationType,
    rhsList: formatRhsList(rawStay.rhsList),
    firstStandardModeGroupingResult: rawStay.firstStandardModeGroupingResult,
    standardModeGroupingResult: rawStay.standardModeGroupingResult
  }
}

export function formatRhsList(rawList: RawRhs[]): Rhs[] {
  const formattedList: Rhs[] = []
  rawList.forEach((rawRhs) => {
    formattedList.push(formatRhs(rawRhs))
  })
  return formattedList
}

export function formatRhs(rawRhs: RawRhs): Rhs {
  return {
    id: rawRhs.id,
    codedMedicalActs: formatCodedMedicalActs(rawRhs.codedMedicalActs),
    predictedLabels: rawRhs.predictedLabels,
    medicalUnit: rawRhs.medicalUnit,
    week: {
      nb: Number(rawRhs.weekNb.substring(0, 2)),
      year: Number(rawRhs.weekNb.substring(2))
    },
    weekDays: rawRhs.weekDays,
    weekEndDays: rawRhs.weekEndDays,
    endDate: dayjs(rawRhs.endDate),
    startDate: dayjs(rawRhs.startDate),
    depDressing: rawRhs.depDressing,
    depMoving: rawRhs.depMoving,
    depFeeding: rawRhs.depFeeding,
    depContinence: rawRhs.depContinence,
    depBehaviour: rawRhs.depBehaviour,
    depSocial: rawRhs.depSocial,
    surgeryDate: rawRhs.surgeryDate ? dayjs(rawRhs.surgeryDate) : null,
    initialModeDiagnosisGroup: rawRhs.initialModeDiagnosisGroup,
    currentModeDiagnosisGroup: rawRhs.currentModeDiagnosisGroup,
    codedRehabilitationActs: rawRhs.codedRehabilitationActs,
  }
}
