import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "stay-page-error-message col-auto mx-auto"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { SsrLoadingErrorReason } from '@/store/modules/stay/ssr/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'SsrLoadingError',
  props: {
    ssrId: {},
    reason: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_ctx.reason)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.reason === _unref(SsrLoadingErrorReason).SSR_STAY_NOT_FOUND)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h1", null, "Le séjour SMR (id : " + _toDisplayString(_ctx.ssrId) + ") n'a pas pu être trouvé.", 1),
              _cache[0] || (_cache[0] = _createElementVNode("div", null, "Cette erreur peut être provoquée par les causes suivantes :", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("ul", null, [
                _createElementVNode("li", null, "L'identifiant fourni est erroné ou n'existe pas."),
                _createElementVNode("li", null, "Le séjour n'a pas encore été importé par Sancare (probable si le séjour est récent).")
              ], -1))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Une erreur est survenue...", -1)),
              _createElementVNode("div", null, "Le séjour SMR (id : " + _toDisplayString(_ctx.ssrId) + ") ne peut pas être affiché, car une erreur est survenue lors de son chargement.", 1),
              _cache[3] || (_cache[3] = _createElementVNode("div", null, "Si le problème se répète, n'hésitez pas à envoyer un rapport de bug à Sancare (icône en haut à droite de l'écran).", -1))
            ]))
      ]))
    : _createCommentVNode("", true)
}
}

})