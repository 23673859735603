import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue'

// import ReadingListButton from '@/stay-list/stay-list-table/actions/ReadingListButton.vue'
import StayListActions from '@/stay-list/stay-list-table/actions/StayListActions.vue'
// import { ListSsrStay } from '@/stay-list/stay-list-table/ssr/types'
import { useStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'SsrStayListActions',
  emits: ["set-stay-list-parameter"],
  setup(__props, { emit: __emit }) {

const store = useStore()

const exportError = ref(null)

// TODO: https://app.clickup.com/t/2628858/WEB-3507 Display reading list button when the feature is ready
// const mode = computed(() => store.state.stayList.stayListParams.mode)
// const stayList = computed(() => store.state.stayList.stayList.items.map((stay: ListSsrStay) => stay.id)
const params = computed(() => ({
  showSeen: store.state.stayList.stayListParams.showSeen,
  pageSize: store.state.stayList.stayListParams.pageSize
}))

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(StayListActions, {
    params: params.value,
    "error-message": exportError.value,
    onSetStayListParameter: _cache[0] || (_cache[0] = (name, value) => _ctx.$emit('set-stay-list-parameter', name, value))
  }, null, 8, ["params", "error-message"]))
}
}

})