import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center gap-2" }
const _hoisted_2 = { class: "row align-items-center gap-2" }
const _hoisted_3 = ["value"]

import { MedicalUnitFilter } from '@sancare/ui-frontend-commons'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import _ from 'lodash'
import { computed, ref, watch } from 'vue'

import FiltersData from '@/filters/FiltersData'
import { useStore } from '@/store'
import { AutomationTypeEnum } from '@/store/modules/automation/types'
import { FilterData, FilterDataTypeEnum, FilterValueTypeEnum } from '@/store/modules/filters/types'
import { ModeEnum, PmsiTypeEnum } from '@/store/modules/settings/types'

import BiologyFilter from './BiologyFilter.vue'
import CompletionFilter from './CompletionFilter.vue'
import ConfidenceFilter from './ConfidenceFilter.vue'
import ConstantFilter from './ConstantFilter.vue'
import DateFilter from './DateFilter.vue'
import { getFilterType } from './FiltersMeta'
import RangeFilter from './RangeFilter.vue'
import SelectFilter from './SelectFilter.vue'
import TextContentCriteria from './TextContentCriteria.vue'
import TextCriteria from './TextCriteria.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterBuilder',
  props: {
    mode: {},
    automationType: { default: null },
    pmsiType: {},
    isGlobal: { type: Boolean, default: false },
    isLegacy: { type: Boolean, default: false }
  },
  emits: ['add-criteria', 'replace-criteria'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const emit = __emit

const props = __props

const filtersData = FiltersData
const filterName = ref('')

const hasDrugs = computed(() => store.state.medicalUnitFilter.hasDrugs)
const hasHealthConstants = computed(() => store.state.medicalUnitFilter.hasHealthConstants)
const hasBiologyResults = computed(() => store.state.medicalUnitFilter.hasBiologyResults)
const pmsiCountry = computed(() => store.state.settings.pmsi.country)
const filterType = computed(() => filtersData[filterName.value] ? filtersData[filterName.value].type : null)
const filterList = computed(() => {
  return _.keyBy(_.filter(filtersData, (filter) => {
    if (!showCriteria(filter)) {
      return false
    }

    if (!props.isLegacy) {
      const isGlobalCondition = (filter.isGlobalCondition || 'false').toString()
      if (isGlobalCondition !== 'both' && isGlobalCondition !== props.isGlobal.toString()) {
        return false
      }
    }

    if (props.mode === ModeEnum.AUTOMATION) {
      return filter.mode.indexOf(props.mode) !== -1
          && filter.automationTypes.indexOf(props.automationType) !== -1
    }

    return filter.mode.indexOf(props.mode) !== -1
  }), (el) => el['name'])
})

function showCriteria(filterData: FilterData): boolean {
  if (!filterData.country.includes(pmsiCountry.value)) {
    return false
  }
  if (!filterData.pmsiTypes.includes(props.pmsiType)) {
    return false
  }
  switch (filterData.name) {
    case 'drug':
      return hasDrugs.value
    case 'healthConstant':
      return hasHealthConstants.value
    case 'biology':
      return hasBiologyResults.value

    default:
      return true
  }
}
function addCriteria({ name, type, value }) {
  value = value.trim()
  if (['diagnosis', 'revaloDiagnosis', 'medicalAct', 'exclusiveMedicalAct', 'startingGhm', 'currentGhm', 'drug'].indexOf(name) > -1) {
    // Diagnoses are always in uppercase: K670, C059 etc...
    value = value.toUpperCase()
  }

  let event = 'add-criteria' as 'add-criteria' | 'replace-criteria'
  if (props.isLegacy && getFilterType(name) === FilterValueTypeEnum.SINGLE_VALUE) {
    event = 'replace-criteria'
  }
  emit(event, { type, value })
}

watch(filterName, (newName, oldName) => {
  if (newName !== oldName) {
    store.commit('medicalUnitFilter/clearCompletionItems')
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isLegacy ? ['col-md-5 col-12'] : ['col-12'])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.isLegacy ? ['col-md-5 col-12'] : ['col-12'])
        }, " Ajouter un critère" + _toDisplayString(_ctx.isGlobal ? ' global' : '') + " : ", 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.isLegacy ? ['col'] : ['col-12'])
        }, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterName).value = $event)),
            name: "filterBuilder_filterName",
            class: "custom-select"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("option", {
              value: "",
              disabled: ""
            }, " --- ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterList.value, (filter, type) => {
              return (_openBlock(), _createElementBlock("option", {
                key: type,
                value: type
              }, _toDisplayString(filter.isTranslated ? _unref(capitalize)(_ctx.$t(filter.label, 1+(filter.isPlurial ?? 0))) : filter.label), 9, _hoisted_3))
            }), 128))
          ], 512), [
            [_vModelSelect, filterName.value]
          ])
        ], 2)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isLegacy ? ['col'] : ['col-12'])
    }, [
      (filterType.value === _unref(FilterDataTypeEnum).COMPLETION)
        ? (_openBlock(), _createBlock(CompletionFilter, {
            key: filterName.value,
            "pmsi-type": _ctx.pmsiType,
            "filter-data": _unref(filtersData)[filterName.value],
            onAddFilter: addCriteria
          }, null, 8, ["pmsi-type", "filter-data"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).SELECT)
        ? (_openBlock(), _createBlock(SelectFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-legacy": _ctx.isLegacy,
            "pmsi-country": pmsiCountry.value,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-legacy", "pmsi-country"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).RANGE)
        ? (_openBlock(), _createBlock(RangeFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "pmsi-type": _ctx.pmsiType,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "pmsi-type"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).CONFIDENCE)
        ? (_openBlock(), _createBlock(ConfidenceFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            onAddFilter: addCriteria
          }, null, 8, ["filter-data"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).CONSTANT)
        ? (_openBlock(), _createBlock(ConstantFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-legacy": _ctx.isLegacy,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-legacy"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).TEXT_CONTENT)
        ? (_openBlock(), _createBlock(TextContentCriteria, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-global-condition": _ctx.isGlobal,
            "is-legacy": _ctx.isLegacy,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-global-condition", "is-legacy"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).TEXT)
        ? (_openBlock(), _createBlock(TextCriteria, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-advanced-search": !_ctx.isLegacy,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-advanced-search"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).MEDICAL_UNIT)
        ? (_openBlock(), _createBlock(_unref(MedicalUnitFilter), {
            key: filterName.value,
            "filter-data-name": _unref(filtersData)[filterName.value].name,
            "is-legacy": _ctx.isLegacy,
            "medical-unit-only": _ctx.mode === _unref(ModeEnum).AUTOMATION && _ctx.automationType === _unref(AutomationTypeEnum).AI,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data-name", "is-legacy", "medical-unit-only"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).BIOLOGY)
        ? (_openBlock(), _createBlock(BiologyFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-legacy": _ctx.isLegacy,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-legacy"]))
        : _createCommentVNode("", true),
      (filterType.value === _unref(FilterDataTypeEnum).DATE)
        ? (_openBlock(), _createBlock(DateFilter, {
            key: filterName.value,
            "filter-data": _unref(filtersData)[filterName.value],
            "is-legacy": _ctx.isLegacy,
            onAddFilter: addCriteria
          }, null, 8, ["filter-data", "is-legacy"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})