import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex-grow-1 flex-shrink-1 d-flex flex-column justify-content-center align-items-center align-content-center border",
  style: {"min-height":"8vh","width":"20vw"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceThumbnail',
  props: {
    title: {},
    to: {}
  },
  setup(__props: any) {


const props = __props

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: props.to}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(props.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})