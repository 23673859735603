import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "general-stay-data card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "data-table" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-8" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-8" }
const _hoisted_8 = { class: "animated-height" }
const _hoisted_9 = { class: "general-stay-data card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "data-table mb-3" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-4" }
const _hoisted_14 = { class: "col-8" }
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = {
  key: 0,
  class: "col-8"
}
const _hoisted_17 = {
  key: 1,
  class: "col-8"
}
const _hoisted_18 = {
  key: 1,
  class: "row"
}
const _hoisted_19 = { class: "col-8" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 2,
  class: "row"
}
const _hoisted_22 = { class: "col-8" }

import { HeightTransition, SancareOcticon, SancareSticky } from '@sancare/ui-frontend-commons'
import { computed } from 'vue'

import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import PatientData from '@/stay-displayer/PatientData.vue'
import RumCh from '@/stay-displayer/RumCh.vue'
import RumFr from '@/stay-displayer/RumFr.vue'
import { useStore } from '@/store'
import { CountryEnum } from '@/store/modules/settings/types'
import { McoStay } from '@/store/modules/stay/mco/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralStayData',
  props: {
    stay: {},
    displayedRumId: {}
  },
  setup(__props: any) {

const store = useStore()

const props = __props

const pmsiCountry = computed(() => store.state.settings.pmsi.country)
const hiddenAdministrativeInfo = computed(() => store.state.stayDisplayer.hiddenAdministrativeInfo)
const hasMaintenancePermission = computed(() => hasPermission(RoleEnum.ROLE_MAINTENANCE_VIEWER, store.state.login.userRole))
const stayRums = computed(() => props.stay.rums.filter((rum) => rum.id > 0))
const pseudonymizationEnabled = computed(() => store.getters.flatSancareSettings['enable_pseudonymization'])
const fullName = computed(() => store.getters.fullName)
const rumComponent = computed(() => pmsiCountry.value === CountryEnum.CH ? RumCh : RumFr)

function toggleDisplay() {
  store.commit('setHiddenAdministrativeInfo', !hiddenAdministrativeInfo.value)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(SancareSticky), { class: "patient-data-container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(PatientData, {
                stay: _ctx.stay,
                "pseudonymization-enabled": pseudonymizationEnabled.value
              }, null, 8, ["stay", "pseudonymization-enabled"]),
              _createElementVNode("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "col-4" }, " DATES DU SÉJOUR ", -1)),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.stay.stayStart.format('L')) + " - " + _toDisplayString(_ctx.stay.stayEnd.format('L')) + " (" + _toDisplayString(_ctx.stay.stayDuration) + "j) ", 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "col-4" }, " N° ADMINISTRATIF ", -1)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.stay.administrativeLocalStayId || '-'), 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "btn-icon col-auto text-right",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleDisplay()))
    }, [
      _withDirectives(_createVNode(_unref(SancareOcticon), {
        name: hiddenAdministrativeInfo.value ? 'eye' : 'eye-closed',
        width: 15
      }, null, 8, ["name"]), [
        [_directive_tooltip, hiddenAdministrativeInfo.value ? 'Afficher les informations administratives' : 'Masquer les informations administratives']
      ])
    ]),
    _createVNode(_unref(HeightTransition), null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h1", _hoisted_13, _toDisplayString(_ctx.$t('stay.RSS')), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createTextVNode(_toDisplayString(_ctx.stay.rssId || '-') + " ", 1),
                    (hasMaintenancePermission.value && _ctx.stay.rssId)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "btn btn-link",
                          name: "maintenance-stay-history-detail",
                          to: { path: `/maintenance/stay-history/${_ctx.stay.rssId}` }
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" (Historique) ")
                          ])),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.stay.geographicEntity || _ctx.stay.legalEntity)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "col-4" }, " ÉTABLISSEMENT ", -1)),
                      (_ctx.stay.geographicEntity)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.stay.geographicEntity.shortDescription || '-'), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.stay.legalEntity.shortDescription || '-'), 1))
                    ]))
                  : _createCommentVNode("", true),
                (!pseudonymizationEnabled.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "col-4" }, " PATIENT ", -1)),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("span", null, _toDisplayString(fullName.value), 1),
                        (_ctx.stay.patient.ipp)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, " (" + _toDisplayString(_ctx.stay.patient.ipp) + ")", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.stay.raac !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "col-4" }, " RAAC ", -1)),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.stay.raac === 1 ? 'OUI' : 'NON'), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_openBlock(), _createBlock(_resolveDynamicComponent(rumComponent.value), {
                rums: stayRums.value,
                "displayed-rum-id": _ctx.displayedRumId
              }, null, 8, ["rums", "displayed-rum-id"]))
            ])
          ])
        ], 512), [
          [_vShow, !hiddenAdministrativeInfo.value]
        ])
      ]),
      _: 1
    })
  ]))
}
}

})