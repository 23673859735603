import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'PredictionIcon',
  props: {
    size: { default: 18 },
    color: { default: 'currentColor' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    "aria-hidden": "true",
    "data-prefix": "fas",
    "data-icon": "robot",
    class: "svg-inline--fa label-prediction-icon",
    role: "img",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 120 640 512",
    style: _normalizeStyle({ width:`${_ctx.size}px`, height:`${_ctx.size}px`, fill:_ctx.color })
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M0 256v128c0 17.7 14.3 32 32 32h32V224H32c-17.7 0-32 14.3-32 32zM464 96H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H176c-44.2 0-80 35.8-80 80v272c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V176c0-44.2-35.8-80-80-80zM256 416h-64v-32h64v32zm-32-120c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm128 120h-64v-32h64v32zm96 0h-64v-32h64v32zm-32-120c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm192-72h-32v192h32c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32z" }, null, -1)
  ]), 4))
}
}

})